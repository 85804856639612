export const CLICK_SAVE = 'CLICK_SAVE';
export const COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD';
export const DEPLOYING_WEBROOT_ENDPOINT_PROTECTION = 'DEPLOYING_WEBROOT_ENDPOINT_PROTECTION';
export const DOWNLOAD_THE_SOFTWARE = 'DOWNLOAD_THE_SOFTWARE';
export const DOWNLOAD_THE_SOFTWARE_VIA_THE_FOLLOWING_LINK = 'DOWNLOAD_THE_SOFTWARE_VIA_THE_FOLLOWING_LINK';
export const DOWNLOAD_THE_WEBROOT_SECURE_ANYWHERE_ENDPOINT_PROTECTION_SOFTWARE = 'DOWNLOAD_THE_WEBROOT_SECURE_ANYWHERE_ENDPOINT_PROTECTION_SOFTWARE';
export const FOLLOW_THESE_SIMPLE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_SOFTWARE = 'FOLLOW_THESE_SIMPLE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_SOFTWARE';
export const FOLLOW_THESE_SIMPLE_STEPS_TO_INSTALL_THE_MDR_SOFTWARE = 'FOLLOW_THESE_SIMPLE_STEPS_TO_INSTALL_THE_MDR_SOFTWARE';
export const FOLLOW_THESE_SIMPLE_STEPS_TO_INSTALL_THE_WEBROOT_ENDPOINT_PROTECTION_SOFTWARE = 'FOLLOW_THESE_SIMPLE_STEPS_TO_INSTALL_THE_WEBROOT_ENDPOINT_PROTECTION_SOFTWARE';
export const FOR_ADVANCED_DEPLOYMENT_OPTIONS = 'FOR_ADVANCED_DEPLOYMENT_OPTIONS';
export const FROM_THE_ENDPOINT_PROTECTION_TAB_SELECT_THE_POLICY_ASSOCIATED_WITH_DEVICES_THAT_YOU_WANT_TO_INSTALL_THE_EDR_AGENT_ON = 'FROM_THE_ENDPOINT_PROTECTION_TAB_SELECT_THE_POLICY_ASSOCIATED_WITH_DEVICES_THAT_YOU_WANT_TO_INSTALL_THE_EDR_AGENT_ON';
export const INSTALL_THE_ENDPOINT_PROTECTION_AGENT = 'INSTALL_THE_ENDPOINT_PROTECTION_AGENT';
export const INSTALL_THE_PRODUCT_AGENT_EDR = 'INSTALL_THE_PRODUCT_AGENT_EDR';
export const INSTALL_THE_PRODUCT_AGENT_MDR = 'INSTALL_THE_PRODUCT_AGENT_MDR';
export const IN_THE_EDR_MDR_SECTION_TURN_INSTALL_EDR_MDR_AGENT_TO_ON = 'IN_THE_EDR_MDR_SECTION_TURN_INSTALL_EDR_MDR_AGENT_TO_ON';
export const IN_THE_NAVIGATION_PANE_GO_TO_MANAGE_POLICIES = 'IN_THE_NAVIGATION_PANE_GO_TO_MANAGE_POLICIES';
export const MAC_DMG = 'MAC_DMG';
export const OPEN_EMAIL_APPLICATION = 'OPEN_EMAIL_APPLICATION';
export const OPTION_1 = 'OPTION_1';
export const OPTION_2 = 'OPTION_2';
export const PLEASE_FOLLOW_THESE_SIMPLE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_AGENT = 'PLEASE_FOLLOW_THESE_SIMPLE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_AGENT';
export const PLEASE_FOLLOW_THESE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_AGENT = 'PLEASE_FOLLOW_THESE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_AGENT';
export const PLEASE_FOLLOW_THESE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_AND_PRODUCT_AGENT_EDR = 'PLEASE_FOLLOW_THESE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_AND_PRODUCT_AGENT_EDR';
export const PLEASE_FOLLOW_THESE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_AND_PRODUCT_AGENT_MDR = 'PLEASE_FOLLOW_THESE_STEPS_TO_INSTALL_THE_ENDPOINT_PROTECTION_AND_PRODUCT_AGENT_MDR';
export const PLEASE_FOLLOW_THESE_STEPS_TO_INSTALL_THE_MDR_AGENT = 'PLEASE_FOLLOW_THESE_STEPS_TO_INSTALL_THE_MDR_AGENT';
export const PLEASE_NOTE_THAT_THE_WINDOWS_FILE_AUTOMATICALLY_APPLIES_YOUR_KEYCODE = 'PLEASE_NOTE_THAT_THE_WINDOWS_FILE_AUTOMATICALLY_APPLIES_YOUR_KEYCODE';
export const RUN_THE_DOWNLOAD_FILE_ON_YOUR_DEVICE = 'RUN_THE_DOWNLOAD_FILE_ON_YOUR_DEVICE';
export const RUN_THE_FILE = 'RUN_THE_FILE';
export const THE_DEVICE_NEEDS_TO_HAVE_AN_ENDPOINT_PROTECTION_POLICY_ASSIGNED_WITH_THE_INSTALL_EDR_MDR_SETTING_ENABLED = 'THE_DEVICE_NEEDS_TO_HAVE_AN_ENDPOINT_PROTECTION_POLICY_ASSIGNED_WITH_THE_INSTALL_EDR_MDR_SETTING_ENABLED';
export const TO_DEPLOY_THE_PRODUCT_AGENT_EDR = 'TO_DEPLOY_THE_PRODUCT_AGENT_EDR';
export const TO_DEPLOY_THE_PRODUCT_AGENT_MDR = 'TO_DEPLOY_THE_PRODUCT_AGENT_MDR';
export const TO_ENABLE_THE_INSTALL_EDR_MDR_SETTING = 'TO_ENABLE_THE_INSTALL_EDR_MDR_SETTING';
export const WHEN_PROMPTED_CLICK_SAVE = 'WHEN_PROMPTED_CLICK_SAVE';
export const WINDOWS_EXE = 'WINDOWS_EXE';
export const WINDOWS_MSI = 'WINDOWS_MSI';
export const YOU_CAN_SEND_THE_FOLLOWING_INSTRUCTIONS_TO_ANY_ENDPOINT_PROTECTION_USER = 'YOU_CAN_SEND_THE_FOLLOWING_INSTRUCTIONS_TO_ANY_ENDPOINT_PROTECTION_USER';
export const YOU_CAN_SEND_THE_FOLLOWING_INSTRUCTIONS_TO_ANY_ENDPOINT_PROTECTION_USER_SO_THAT = 'YOU_CAN_SEND_THE_FOLLOWING_INSTRUCTIONS_TO_ANY_ENDPOINT_PROTECTION_USER_SO_THAT';
export const YOU_CAN_SEND_THE_FOLLOWING_INSTRUCTIONS_TO_ANY_MDR_USER = 'YOU_CAN_SEND_THE_FOLLOWING_INSTRUCTIONS_TO_ANY_MDR_USER';
export const ATTENTIONANDEXPIRED = 'attentionandexpired';
export const ATTENTIONANDEXPIRING = 'attentionandexpiring';
export const BUTTON_RESETACCOUNT = 'button_resetaccount';
export const BUY_NOW = 'buy_now';
export const DNS_CONFIGURE_BUTTON = 'dns_configure_button';
export const DNS_TRIAL_CONFIRMATION_MESSAGE = 'dns_trial_confirmation_message';
export const DNS_TRIAL_CONFIRMED_MESSAGE = 'dns_trial_confirmed_message';
export const ENDPOINTINFO_TITLE = 'endpointinfo_title';
export const FOCUS_BETA_JOIN_BACKBUTTON_DESC = 'focus_beta_join_backButton_desc';
export const FOCUS_BETA_JOIN_BACKBUTTON_TITLE = 'focus_beta_join_backButton_title';
export const FOCUS_BETA_JOIN_FINISHED_DESC = 'focus_beta_join_finished_desc';
export const FOCUS_BETA_JOIN_FINISHED_TITLE = 'focus_beta_join_finished_title';
export const FOCUS_BETA_JOIN_INTRO_DESC = 'focus_beta_join_intro_desc';
export const FOCUS_BETA_JOIN_INTRO_DESC2 = 'focus_beta_join_intro_desc2';
export const FOCUS_BETA_JOIN_INTRO_TITLE = 'focus_beta_join_intro_title';
export const FOCUS_BETA_JOIN_MANAGEBUTTON_DESC = 'focus_beta_join_manageButton_desc';
export const FOCUS_BETA_JOIN_MANAGEBUTTON_TITLE = 'focus_beta_join_manageButton_title';
export const FOCUS_BETA_JOIN_OTHERSETTINGS_DESC = 'focus_beta_join_otherSettings_desc';
export const FOCUS_BETA_JOIN_OTHERSETTINGS_TITLE = 'focus_beta_join_otherSettings_title';
export const FOCUS_BETA_JOIN_SUMMARYACTIONS_DESC = 'focus_beta_join_summaryActions_desc';
export const FOCUS_BETA_JOIN_SUMMARYACTIONS_TITLE = 'focus_beta_join_summaryActions_title';
export const FOCUS_BETA_JOIN_SUMMARYADMINS_DESC = 'focus_beta_join_summaryAdmins_desc';
export const FOCUS_BETA_JOIN_SUMMARYADMINS_TITLE = 'focus_beta_join_summaryAdmins_title';
export const FOCUS_BETA_JOIN_SUMMARYOVERVIEW_DESC = 'focus_beta_join_summaryOverview_desc';
export const FOCUS_BETA_JOIN_SUMMARYOVERVIEW_TITLE = 'focus_beta_join_summaryOverview_title';
export const FOCUS_BETA_JOIN_SUMMARYPAGE_DESC = 'focus_beta_join_summaryPage_desc';
export const FOCUS_BETA_JOIN_SUMMARYPAGE_TITLE = 'focus_beta_join_summaryPage_title';
export const FOCUS_BETA_JOIN_TEMPSITE_DESC = 'focus_beta_join_tempSite_desc';
export const FOCUS_BETA_JOIN_TEMPSITE_TITLE = 'focus_beta_join_tempSite_title';
export const FOCUS_END = 'focus_end';
export const FOCUS_ERROR = 'focus_error';
export const IDP_SECONDFACTORREMINDER_SKIPFORNOWTEXT = 'idp_SecondFactorReminder_SkipForNowText';
export const IDP_SUPPORTEDBROWSERLINKTEXT = 'idp_SupportedBrowserLinkText';
export const IDP_UNSUPPORTEDBROWSER = 'idp_UnsupportedBrowser';
export const IDP_HTMLPART = 'idp_htmlpart';
export const IDP_TEXTPART = 'idp_textpart';
export const INTERACTIVE_DEMOS_TITLE = 'interactive_demos_title';
export const MAR_FILTER_TITLE = 'mar_filter_title';
export const PSMESSAGE10 = 'psMessage10';
export const PSMESSAGE100 = 'psMessage100';
export const PSMESSAGE101 = 'psMessage101';
export const PSMESSAGE102 = 'psMessage102';
export const PSMESSAGE103 = 'psMessage103';
export const PSMESSAGE104 = 'psMessage104';
export const PSMESSAGE105 = 'psMessage105';
export const PSMESSAGE106 = 'psMessage106';
export const PSMESSAGE107 = 'psMessage107';
export const PSMESSAGE108 = 'psMessage108';
export const PSMESSAGE109 = 'psMessage109';
export const PSMESSAGE11 = 'psMessage11';
export const PSMESSAGE110 = 'psMessage110';
export const PSMESSAGE111 = 'psMessage111';
export const PSMESSAGE112 = 'psMessage112';
export const PSMESSAGE113 = 'psMessage113';
export const PSMESSAGE114 = 'psMessage114';
export const PSMESSAGE115 = 'psMessage115';
export const PSMESSAGE116 = 'psMessage116';
export const PSMESSAGE117 = 'psMessage117';
export const PSMESSAGE118 = 'psMessage118';
export const PSMESSAGE119 = 'psMessage119';
export const PSMESSAGE12 = 'psMessage12';
export const PSMESSAGE120 = 'psMessage120';
export const PSMESSAGE121 = 'psMessage121';
export const PSMESSAGE122 = 'psMessage122';
export const PSMESSAGE123 = 'psMessage123';
export const PSMESSAGE13 = 'psMessage13';
export const PSMESSAGE14 = 'psMessage14';
export const PSMESSAGE15 = 'psMessage15';
export const PSMESSAGE16 = 'psMessage16';
export const PSMESSAGE17 = 'psMessage17';
export const PSMESSAGE18 = 'psMessage18';
export const PSMESSAGE19 = 'psMessage19';
export const PSMESSAGE2 = 'psMessage2';
export const PSMESSAGE20 = 'psMessage20';
export const PSMESSAGE21 = 'psMessage21';
export const PSMESSAGE22 = 'psMessage22';
export const PSMESSAGE23 = 'psMessage23';
export const PSMESSAGE24 = 'psMessage24';
export const PSMESSAGE25 = 'psMessage25';
export const PSMESSAGE26 = 'psMessage26';
export const PSMESSAGE27 = 'psMessage27';
export const PSMESSAGE28 = 'psMessage28';
export const PSMESSAGE29 = 'psMessage29';
export const PSMESSAGE3 = 'psMessage3';
export const PSMESSAGE30 = 'psMessage30';
export const PSMESSAGE31 = 'psMessage31';
export const PSMESSAGE32 = 'psMessage32';
export const PSMESSAGE33 = 'psMessage33';
export const PSMESSAGE34 = 'psMessage34';
export const PSMESSAGE35 = 'psMessage35';
export const PSMESSAGE36 = 'psMessage36';
export const PSMESSAGE37 = 'psMessage37';
export const PSMESSAGE38 = 'psMessage38';
export const PSMESSAGE39 = 'psMessage39';
export const PSMESSAGE4 = 'psMessage4';
export const PSMESSAGE40 = 'psMessage40';
export const PSMESSAGE41 = 'psMessage41';
export const PSMESSAGE42 = 'psMessage42';
export const PSMESSAGE43 = 'psMessage43';
export const PSMESSAGE44 = 'psMessage44';
export const PSMESSAGE45 = 'psMessage45';
export const PSMESSAGE46 = 'psMessage46';
export const PSMESSAGE47 = 'psMessage47';
export const PSMESSAGE48 = 'psMessage48';
export const PSMESSAGE49 = 'psMessage49';
export const PSMESSAGE5 = 'psMessage5';
export const PSMESSAGE50 = 'psMessage50';
export const PSMESSAGE51 = 'psMessage51';
export const PSMESSAGE52 = 'psMessage52';
export const PSMESSAGE53 = 'psMessage53';
export const PSMESSAGE54 = 'psMessage54';
export const PSMESSAGE55 = 'psMessage55';
export const PSMESSAGE56 = 'psMessage56';
export const PSMESSAGE57 = 'psMessage57';
export const PSMESSAGE58 = 'psMessage58';
export const PSMESSAGE59 = 'psMessage59';
export const PSMESSAGE6 = 'psMessage6';
export const PSMESSAGE60 = 'psMessage60';
export const PSMESSAGE61 = 'psMessage61';
export const PSMESSAGE62 = 'psMessage62';
export const PSMESSAGE63 = 'psMessage63';
export const PSMESSAGE64 = 'psMessage64';
export const PSMESSAGE65 = 'psMessage65';
export const PSMESSAGE66 = 'psMessage66';
export const PSMESSAGE67 = 'psMessage67';
export const PSMESSAGE68 = 'psMessage68';
export const PSMESSAGE69 = 'psMessage69';
export const PSMESSAGE7 = 'psMessage7';
export const PSMESSAGE70 = 'psMessage70';
export const PSMESSAGE71 = 'psMessage71';
export const PSMESSAGE72 = 'psMessage72';
export const PSMESSAGE73 = 'psMessage73';
export const PSMESSAGE74 = 'psMessage74';
export const PSMESSAGE75 = 'psMessage75';
export const PSMESSAGE76 = 'psMessage76';
export const PSMESSAGE77 = 'psMessage77';
export const PSMESSAGE78 = 'psMessage78';
export const PSMESSAGE79 = 'psMessage79';
export const PSMESSAGE8 = 'psMessage8';
export const PSMESSAGE80 = 'psMessage80';
export const PSMESSAGE81 = 'psMessage81';
export const PSMESSAGE82 = 'psMessage82';
export const PSMESSAGE83 = 'psMessage83';
export const PSMESSAGE84 = 'psMessage84';
export const PSMESSAGE85 = 'psMessage85';
export const PSMESSAGE86 = 'psMessage86';
export const PSMESSAGE87 = 'psMessage87';
export const PSMESSAGE88 = 'psMessage88';
export const PSMESSAGE89 = 'psMessage89';
export const PSMESSAGE9 = 'psMessage9';
export const PSMESSAGE90 = 'psMessage90';
export const PSMESSAGE91 = 'psMessage91';
export const PSMESSAGE92 = 'psMessage92';
export const PSMESSAGE93 = 'psMessage93';
export const PSMESSAGE94 = 'psMessage94';
export const PSMESSAGE95 = 'psMessage95';
export const PSMESSAGE96 = 'psMessage96';
export const PSMESSAGE97 = 'psMessage97';
export const PSMESSAGE98 = 'psMessage98';
export const PSMESSAGE99 = 'psMessage99';
export const SAT_CONFIGURE_BUTTON = 'sat_configure_button';
export const SAT_TRIAL_CONFIRMATION_MESSAGE = 'sat_trial_confirmation_message';
export const SAT_TRIAL_CONFIRMED_MESSAGE = 'sat_trial_confirmed_message';
export const SAT_TRIAL_HEADER = 'sat_trial_header';
export const SELECTANADMIN = 'selectanadmin';
export const SITEADMINSDESC = 'siteadminsdesc';
export const TEXT_100_MAXIMUMCHARACTERSALLOWED = 'text_100_maximumcharactersallowed';
export const TEXT_101TO250 = 'text_101to250';
export const TEXT_12HOURS = 'text_12hours';
export const TEXT_12MONTHS = 'text_12months';
export const TEXT_14DAYS = 'text_14days';
export const TEXT_15MINS = 'text_15mins';
export const TEXT_1HOUR = 'text_1hour';
export const TEXT_1MONTH = 'text_1month';
export const TEXT_251TO500 = 'text_251to500';
export const TEXT_255_MAXIMUMCHARACTERSALLOWED = 'text_255_maximumcharactersallowed';
export const TEXT_2DAYS = 'text_2days';
export const TEXT_2HOURS = 'text_2hours';
export const TEXT_2MONTHS = 'text_2months';
export const TEXT_30DAYS = 'text_30days';
export const TEXT_30MINS = 'text_30mins';
export const TEXT_3DAYS = 'text_3days';
export const TEXT_3HOURS = 'text_3hours';
export const TEXT_3MONTHS = 'text_3months';
export const TEXT_4HOURS = 'text_4hours';
export const TEXT_500_MAXIMUMCHARACTERSALLOWED = 'text_500_maximumcharactersallowed';
export const TEXT_50TO100 = 'text_50to100';
export const TEXT_60DAYS = 'text_60days';
export const TEXT_60DAYTRIAL = 'text_60daytrial';
export const TEXT_6HOURS = 'text_6hours';
export const TEXT_6MONTHS = 'text_6months';
export const TEXT_7DAYS = 'text_7days';
export const TEXT_90DAYS = 'text_90days';
export const TEXT_DEACTIVATEDSITES = 'text_DeactivatedSites';
export const TEXT_DEVICESALLOWED = 'text_DevicesAllowed';
export const TEXT_EXPIREDSITES = 'text_ExpiredSites';
export const TEXT_EXPIRINGSITES = 'text_ExpiringSites';
export const TEXT_NUMBEROFSITES = 'text_NumberOfSites';
export const TEXT_REQUIRINGATTENTION = 'text_RequiringAttention';
export const TEXT_SUSPENDEDSITES = 'text_SuspendedSites';
export const TEXT_TRIALSITES = 'text_TrialSites';
export const TEXT_WITHACTIVETHREATS = 'text_WithActiveThreats';
export const TEXT_ACCEPTING_AGREEMENT = 'text_accepting_agreement';
export const TEXT_ACCESSCONTROL = 'text_accesscontrol';
export const TEXT_ACCOUNT = 'text_account';
export const TEXT_ACCOUNTDETAILS = 'text_accountdetails';
export const TEXT_ACCOUNTID = 'text_accountid';
export const TEXT_ACCOUNTINFO = 'text_accountinfo';
export const TEXT_ACCOUNTTYPE = 'text_accounttype';
export const TEXT_ACTION = 'text_action';
export const TEXT_ACTIONS = 'text_actions';
export const TEXT_ACTIONTAKEN = 'text_actiontaken';
export const TEXT_ACTIVE = 'text_active';
export const TEXT_ACTIVE_AGENT = 'text_active_agent';
export const TEXT_ACTIVE_IP = 'text_active_ip';
export const TEXT_ACTIVEDEVICES = 'text_activedevices';
export const TEXT_ACTIVEDIRECTORY = 'text_activedirectory';
export const TEXT_ACTIVETRIALDEVICES = 'text_activetrialdevices';
export const TEXT_AD_WORKGROUP = 'text_ad_workgroup';
export const TEXT_ADD = 'text_add';
export const TEXT_ADD_FILE_OVERRIDE = 'text_add_file_override';
export const TEXT_ADDADMIN = 'text_addadmin';
export const TEXT_ADDCHART = 'text_addchart';
export const TEXT_ADDCHARTERROR_CHARTNAME = 'text_addcharterror_chartname';
export const TEXT_ADDCHARTERROR_CHARTTYPE = 'text_addcharterror_charttype';
export const TEXT_ADDCHARTERROR_DATAFIELD = 'text_addcharterror_datafield';
export const TEXT_ADDED = 'text_added';
export const TEXT_ADDITIONALEMAILS = 'text_additionalemails';
export const TEXT_ADDITIONALFILTERING = 'text_additionalfiltering';
export const TEXT_ADDNEWIPROW = 'text_addnewiprow';
export const TEXT_ADDPAGE = 'text_addpage';
export const TEXT_ADDROW = 'text_addrow';
export const TEXT_ADDSITE = 'text_addsite';
export const TEXT_ADDTAG = 'text_addtag';
export const TEXT_ADMIN_GUIDES = 'text_admin_guides';
export const TEXT_ADMINALREADYEXISTS = 'text_adminalreadyexists';
export const TEXT_ADMINS = 'text_admins';
export const TEXT_ADVANCED_SETTINGS = 'text_advanced_settings';
export const TEXT_ADVANCED_SETTINGS_CONSOLE_TYPE_PARAGRAPH = 'text_advanced_settings_console_type_paragraph';
export const TEXT_ADVANCED_SETTINGS_CONSOLE_TYPE_PARAGRAPH_FOUR = 'text_advanced_settings_console_type_paragraph_four';
export const TEXT_ADVANCED_SETTINGS_CONSOLE_TYPE_PARAGRAPH_ONE = 'text_advanced_settings_console_type_paragraph_one';
export const TEXT_ADVANCED_SETTINGS_CONSOLE_TYPE_PARAGRAPH_THREE = 'text_advanced_settings_console_type_paragraph_three';
export const TEXT_ADVANCED_SETTINGS_CONSOLE_TYPE_PARAGRAPH_TWO = 'text_advanced_settings_console_type_paragraph_two';
export const TEXT_ADVANCED_SETTINGS_CONVERT_CONSOLE_BUTTON = 'text_advanced_settings_convert_console_button';
export const TEXT_ADVANCED_SETTINGS_CONVERT_CONSOLE_TITLE = 'text_advanced_settings_convert_console_title';
export const TEXT_AGENT_MANUAL = 'text_agent_manual';
export const TEXT_AGENT_MANUAL_TITLE = 'text_agent_manual_title';
export const TEXT_AGENT_SILENT = 'text_agent_silent';
export const TEXT_AGENT_SILENT_TITLE = 'text_agent_silent_title';
export const TEXT_AGENT_TITLE = 'text_agent_title';
export const TEXT_AGENTBYPASSLIST = 'text_agentbypasslist';
export const TEXT_AGENTCOMMANDS = 'text_agentcommands';
export const TEXT_AGENTCOMMANDS_NO_DEVICES_SELECTED = 'text_agentcommands_no_devices_selected';
export const TEXT_AGENTVERSION = 'text_agentversion';
export const TEXT_AGENTVERSIONSPREAD = 'text_agentversionspread';
export const TEXT_AGREE = 'text_agree';
export const TEXT_AGREEANDREGISTER = 'text_agreeandregister';
export const TEXT_AGREEMENTDISCLAIMER = 'text_agreementdisclaimer';
export const TEXT_AGREEMENTTERMSDESCRIPTION = 'text_agreementtermsdescription';
export const TEXT_ALCLEANED = 'text_alcleaned';
export const TEXT_ALERTACTIVE = 'text_alertactive';
export const TEXT_ALERTDETAILS = 'text_alertdetails';
export const TEXT_ALERTGROUPDETAILS = 'text_alertgroupdetails';
export const TEXT_ALERTLIST = 'text_alertlist';
export const TEXT_ALERTNAMEALREADYEXISTS = 'text_alertnamealreadyexists';
export const TEXT_ALERTPREVIEW = 'text_alertpreview';
export const TEXT_ALERTRECIPIENTS = 'text_alertrecipients';
export const TEXT_ALERTS = 'text_alerts';
export const TEXT_ALERTS_HOWTOUSEDATAINPUTS = 'text_alerts_howtousedatainputs';
export const TEXT_ALERTS_SCOPE_TOOLTIP = 'text_alerts_scope_tooltip';
export const TEXT_ALERTS_SITE_TOOLTIP = 'text_alerts_site_tooltip';
export const TEXT_ALERTSTATUS = 'text_alertstatus';
export const TEXT_ALERTSUSPENDED = 'text_alertsuspended';
export const TEXT_ALERTTEMP1_1 = 'text_alerttemp1_1';
export const TEXT_ALERTTEMP2_1 = 'text_alerttemp2_1';
export const TEXT_ALERTTEMP3 = 'text_alerttemp3';
export const TEXT_ALERTTEMP4 = 'text_alerttemp4';
export const TEXT_ALERTTEMP5_1 = 'text_alerttemp5_1';
export const TEXT_ALERTTEMP5_2 = 'text_alerttemp5_2';
export const TEXT_ALERTTYPE = 'text_alerttype';
export const TEXT_ALINFECTED = 'text_alinfected';
export const TEXT_ALINSTALLS = 'text_alinstalls';
export const TEXT_ALINSTANT = 'text_alinstant';
export const TEXT_ALL = 'text_all';
export const TEXT_ALL_ENDPOINTS_ARE_ACTIVE = 'text_all_endpoints_are_active';
export const TEXT_ALL_POLICIES = 'text_all_policies';
export const TEXT_ALL_THREATS_SEEN = 'text_all_threats_seen';
export const TEXT_ALL_THREATS_SEEN_ON_THIS_DEVICE = 'text_all_threats_seen_on_this_device';
export const TEXT_ALL_UNDETERMINED_SOFTWARE_SEEN = 'text_all_undetermined_software_seen';
export const TEXT_ALL_UNDETERMINED_SOFTWARE_SEEN_ON_THIS_DEVICE = 'text_all_undetermined_software_seen_on_this_device';
export const TEXT_ALLCHARTTYPESALREADYEXIST = 'text_allcharttypesalreadyexist';
export const TEXT_ALLOW = 'text_allow';
export const TEXT_ALLOW_BLOCK = 'text_allow_block';
export const TEXT_ALLOW_BLOCK_TOOLTIP = 'text_allow_block_tooltip';
export const TEXT_ALLOWED = 'text_allowed';
export const TEXT_ALLOWLIST = 'text_allowlist';
export const TEXT_ALLOWONLY = 'text_allowonly';
export const TEXT_ALLOWURL = 'text_allowurl';
export const TEXT_ALLPAGES = 'text_allpages';
export const TEXT_ALLSELECTED = 'text_allselected';
export const TEXT_ALLSITES = 'text_allsites';
export const TEXT_ALLSITESDEACTIVATED = 'text_allsitesdeactivated';
export const TEXT_ALLSTATUSES = 'text_allstatuses';
export const TEXT_AMERICAS = 'text_americas';
export const TEXT_ANNUALLY = 'text_annually';
export const TEXT_APIONLYADMINS = 'text_apionlyadmins';
export const TEXT_APIONLYUSER = 'text_apionlyuser';
export const TEXT_APPLEMAC = 'text_applemac';
export const TEXT_APPLY = 'text_apply';
export const TEXT_APPLYCHANGESINGLEORALLPAGES = 'text_applychangesingleorallpages';
export const TEXT_APRIL = 'text_april';
export const TEXT_AREA = 'text_area';
export const TEXT_AREASPLINE = 'text_areaspline';
export const TEXT_AREYOUSUREDELETEPOLICY = 'text_areyousuredeletepolicy';
export const TEXT_ASIAPAC = 'text_asiapac';
export const TEXT_ASSIGNREPLACEMENTDISTLIST = 'text_assignreplacementdistlist';
export const TEXT_ASSIGNREPLACEMENTPOLICY = 'text_assignreplacementpolicy';
export const TEXT_ASSOCIATE_POLICY_TOOLTIP = 'text_associate_policy_tooltip';
export const TEXT_ASSOCIATE_WITH_POLICY = 'text_associate_with_policy';
export const TEXT_ASSOCIATED_POLICY = 'text_associated_policy';
export const TEXT_ATLEASTONELANG = 'text_atleastonelang';
export const TEXT_ATLEASTONESITE = 'text_atleastonesite';
export const TEXT_ATLEASTONETEMPLATE = 'text_atleastonetemplate';
export const TEXT_ATTENTION = 'text_attention';
export const TEXT_ATTENTIONREQUIRED = 'text_attentionrequired';
export const TEXT_ATTENTIONREQUIREDSTATUS = 'text_attentionrequiredstatus';
export const TEXT_ATTENTIONSUSPENDED = 'text_attentionsuspended';
export const TEXT_AUDIT_LOG = 'text_audit_log';
export const TEXT_AUGUST = 'text_august';
export const TEXT_AUTHANY = 'text_authany';
export const TEXT_AUTHBASIC = 'text_authbasic';
export const TEXT_AUTHDIGEST = 'text_authdigest';
export const TEXT_AUTHNEGOTIATION = 'text_authnegotiation';
export const TEXT_AUTHNTLM = 'text_authntlm';
export const TEXT_AUTO = 'text_auto';
export const TEXT_AVAILABLE_DOWNLOADS = 'text_available_downloads';
export const TEXT_AWAITINGCONFIRMATION = 'text_awaitingconfirmation';
export const TEXT_BACK = 'text_back';
export const TEXT_BACK_TO_GUIDED_SETUP = 'text_back_to_guided_setup';
export const TEXT_BACK_TO_SITES = 'text_back_to_sites';
export const TEXT_BACKTODEVICELIST = 'text_backtodevicelist';
export const TEXT_BAD = 'text_bad';
export const TEXT_BAR = 'text_bar';
export const TEXT_BASICCONFIG = 'text_basicconfig';
export const TEXT_BASICSETTINGS = 'text_basicsettings';
export const TEXT_BAURL = 'text_baurl';
export const TEXT_BEGIN_GUIDED_SETUP = 'text_begin_guided_setup';
export const TEXT_BEHAVIORSHIELD = 'text_behaviorshield';
export const TEXT_BENEFITS = 'text_benefits';
export const TEXT_BETAMODAL = 'text_betaModal';
export const TEXT_BETAMODAL2 = 'text_betaModal2';
export const TEXT_BETAMODALOUT = 'text_betaModalOut';
export const TEXT_BILLINGCYCLE = 'text_billingcycle';
export const TEXT_BILLINGDATE = 'text_billingdate';
export const TEXT_BLACKLIST = 'text_blacklist';
export const TEXT_BLACKLISTTHISFILE = 'text_blacklistthisfile';
export const TEXT_BLOCK = 'text_block';
export const TEXT_BLOCK_AND_ALLOW = 'text_block_and_allow';
export const TEXT_BLOCK_SLASH_ALLOW = 'text_block_slash_allow';
export const TEXT_BLOCKALLOWBLOCKED = 'text_blockallowblocked';
export const TEXT_BLOCKALLOWDNSONLY = 'text_blockallowdnsonly';
export const TEXT_BLOCKALLOWLIST = 'text_blockallowlist';
export const TEXT_BLOCKALLOWSETTINGS = 'text_blockallowsettings';
export const TEXT_BLOCKANDALLOW = 'text_blockandallow';
export const TEXT_BLOCKED = 'text_blocked';
export const TEXT_BLOCKEDBYBLOCKLIST = 'text_blockedbyblocklist';
export const TEXT_BLOCKEDBYCATEGORY = 'text_blockedbycategory';
export const TEXT_BLOCKEDBYREPUTATION = 'text_blockedbyreputation';
export const TEXT_BLOCKEDCOUNT = 'text_blockedcount';
export const TEXT_BLOCKEDURLS = 'text_blockedurls';
export const TEXT_BLOCKONLY = 'text_blockonly';
export const TEXT_BLOCKREASON = 'text_blockreason';
export const TEXT_BOTNETCOMMANDCONTROL = 'text_botnetcommandcontrol';
export const TEXT_BUILDING_OWN_INTEGRATION_OR_AUTOMATION_SYSTEM = 'text_building_own_integration_or_automation_system';
export const TEXT_BUSINESS = 'text_business';
export const TEXT_BUSINESSCONSUMERCONSOLES = 'text_businessconsumerconsoles';
export const TEXT_BUSINESSSERVICES = 'text_businessservices';
export const TEXT_BUTSITEDEAC = 'text_butsitedeac';
export const TEXT_BUTSITESDEAC = 'text_butsitesdeac';
export const TEXT_BUTTONCANCEL = 'text_buttoncancel';
export const TEXT_BUTTONCREATECHART = 'text_buttoncreatechart';
export const TEXT_BUTTONOK = 'text_buttonok';
export const TEXT_BUTTONSAVE = 'text_buttonsave';
export const TEXT_BUTTONSAVECHART = 'text_buttonsavechart';
export const TEXT_CANCEL = 'text_cancel';
export const TEXT_CANNOTCONTAINFOLLOWINGCHARACTERS = 'text_cannotcontainfollowingcharacters';
export const TEXT_CANNOTDELETEDESCRIPTION = 'text_cannotdeletedescription';
export const TEXT_CANNOTDELETEREPORT = 'text_cannotdeletereport';
export const TEXT_CANNOTDELETETEMPLATE = 'text_cannotdeletetemplate';
export const TEXT_CATEGORY = 'text_category';
export const TEXT_CATEGORYRESTRICTED = 'text_categoryrestricted';
export const TEXT_CDDVD = 'text_cddvd';
export const TEXT_CHANGE = 'text_change';
export const TEXT_CHANGECONSOLE = 'text_changeconsole';
export const TEXT_CHANGESNOTSAVED = 'text_changesnotsaved';
export const TEXT_CHARACTERS = 'text_characters';
export const TEXT_CHARACTERSREQUIRED = 'text_charactersrequired';
export const TEXT_CHARTAREANOTBIGENOUGH = 'text_chartareanotbigenough';
export const TEXT_CHARTDATAABOVE0 = 'text_chartdataabove0';
export const TEXT_CHARTDATAFIELD = 'text_chartdatafield';
export const TEXT_CHARTEDITINFO = 'text_charteditinfo';
export const TEXT_CHARTNAME = 'text_chartname';
export const TEXT_CHARTTYPE = 'text_charttype';
export const TEXT_CHECKINGKEYCODE = 'text_checkingKeycode';
export const TEXT_CID90_0 = 'text_cid90_0';
export const TEXT_CID90_1 = 'text_cid90_1';
export const TEXT_CID90_2 = 'text_cid90_2';
export const TEXT_CID90_3 = 'text_cid90_3';
export const TEXT_CLEAN = 'text_clean';
export const TEXT_CLEANUP = 'text_cleanup';
export const TEXT_CLEAR = 'text_clear';
export const TEXT_CLEARALL = 'text_clearall';
export const TEXT_CLICKFORHELPINFO = 'text_clickforhelpinfo';
export const TEXT_CLIENTCREDS = 'text_clientcreds';
export const TEXT_CLOSE = 'text_close';
export const TEXT_CLOUD_DETERMINATION = 'text_cloud_determination';
export const TEXT_CLOUD_DETERMINATION_TOOLTIP = 'text_cloud_determination_tooltip';
export const TEXT_CLOUDDETERMINATION = 'text_clouddetermination';
export const TEXT_COLUMN = 'text_column';
export const TEXT_COMINGSOON = 'text_comingsoon';
export const TEXT_COMMAND_ALLGOOD = 'text_command_allgood';
export const TEXT_COMMAND_ALLGOOD2 = 'text_command_allgood2';
export const TEXT_COMMAND_ALLOWAPP = 'text_command_allowapp';
export const TEXT_COMMAND_CHANGEKEYCODE = 'text_command_changekeycode';
export const TEXT_COMMAND_CHANGEKEYCODE_DESC = 'text_command_changekeycode_desc';
export const TEXT_COMMAND_CLEANUP = 'text_command_cleanup';
export const TEXT_COMMAND_CLEANUP_DESC = 'text_command_cleanup_desc';
export const TEXT_COMMAND_CLEARSYSPROXY = 'text_command_clearsysproxy';
export const TEXT_COMMAND_DEACTIVATE = 'text_command_deactivate';
export const TEXT_COMMAND_DEACTIVATE_DESC = 'text_command_deactivate_desc';
export const TEXT_COMMAND_DENYAPP = 'text_command_denyapp';
export const TEXT_COMMAND_DLEXEC = 'text_command_dlexec';
export const TEXT_COMMAND_DLEXEC_DESC = 'text_command_dlexec_desc';
export const TEXT_COMMAND_DROPPWD = 'text_command_droppwd';
export const TEXT_COMMAND_EMPTYLOGS = 'text_command_emptylogs';
export const TEXT_COMMAND_FWALLOWALL = 'text_command_fwallowall';
export const TEXT_COMMAND_KILLUNTRUSTED = 'text_command_killuntrusted';
export const TEXT_COMMAND_LINE_OPTIONS_OPTIONAL = 'text_command_line_options_optional';
export const TEXT_COMMAND_LOCKPC = 'text_command_lockpc';
export const TEXT_COMMAND_LOGOFF = 'text_command_logoff';
export const TEXT_COMMAND_PROTECTAPP = 'text_command_protectapp';
export const TEXT_COMMAND_PROXY = 'text_command_proxy';
export const TEXT_COMMAND_PROXY_DESC = 'text_command_proxy_desc';
export const TEXT_COMMAND_REBOOT = 'text_command_reboot';
export const TEXT_COMMAND_REBOOT_DESC = 'text_command_reboot_desc';
export const TEXT_COMMAND_REBOOTSAFEMODE = 'text_command_rebootsafemode';
export const TEXT_COMMAND_REINSTALL = 'text_command_reinstall';
export const TEXT_COMMAND_REINSTALL_DESC = 'text_command_reinstall_desc';
export const TEXT_COMMAND_RESETDESKTOP = 'text_command_resetdesktop';
export const TEXT_COMMAND_RESETSCREENSAVER = 'text_command_resetscreensaver';
export const TEXT_COMMAND_RESETSYSPOLICIES = 'text_command_resetsyspolicies';
export const TEXT_COMMAND_RESTOREFILE = 'text_command_restorefile';
export const TEXT_COMMAND_RESTOREFILE_DESC = 'text_command_restorefile_desc';
export const TEXT_COMMAND_REVERIFY = 'text_command_reverify';
export const TEXT_COMMAND_REVERIFY_DESC = 'text_command_reverify_desc';
export const TEXT_COMMAND_RUNCLEANUPSCRIPT = 'text_command_runcleanupscript';
export const TEXT_COMMAND_RUNDOS = 'text_command_rundos';
export const TEXT_COMMAND_RUNDOS_DESC = 'text_command_rundos_desc';
export const TEXT_COMMAND_RUNREG = 'text_command_runreg';
export const TEXT_COMMAND_SCAN = 'text_command_scan';
export const TEXT_COMMAND_SCAN_DESC = 'text_command_scan_desc';
export const TEXT_COMMAND_SCANFOLDER = 'text_command_scanfolder';
export const TEXT_COMMAND_SCANTIME = 'text_command_scantime';
export const TEXT_COMMAND_SENT = 'text_command_sent';
export const TEXT_COMMAND_SHUTDOWN = 'text_command_shutdown';
export const TEXT_COMMAND_SYSCLEAN = 'text_command_sysclean';
export const TEXT_COMMAND_SYSCLEAN_DESC = 'text_command_sysclean_desc';
export const TEXT_COMMAND_TEMPKEYCODE = 'text_command_tempkeycode';
export const TEXT_COMMAND_UNINSTALL = 'text_command_uninstall';
export const TEXT_COMMAND_UNINSTALL_DESC = 'text_command_uninstall_desc';
export const TEXT_COMMAND_UNPROTECTAPP = 'text_command_unprotectapp';
export const TEXT_COMMAND_WSABLOGS = 'text_command_wsablogs';
export const TEXT_COMMANDS_DISCLAIMER = 'text_commands_disclaimer';
export const TEXT_COMMASEPARATED = 'text_commaseparated';
export const TEXT_COMMENTMATCH = 'text_commentmatch';
export const TEXT_COMMENTS = 'text_comments';
export const TEXT_COMPANY = 'text_company';
export const TEXT_COMPANYADDRESS = 'text_companyaddress';
export const TEXT_COMPANYNAME = 'text_companyname';
export const TEXT_COMPLETE = 'text_complete';
export const TEXT_CONFIGURE_DNS_SETTINGS = 'text_configure_dns_settings';
export const TEXT_CONFIGURE_SAT_SETTINGS = 'text_configure_sat_settings';
export const TEXT_CONFIRM = 'text_confirm';
export const TEXT_CONFIRMCHANGES = 'text_confirmchanges';
export const TEXT_CONFIRMDELETE = 'text_confirmdelete';
export const TEXT_CONSOLE_TYPE = 'text_console_type';
export const TEXT_CONSOLENAME = 'text_consolename';
export const TEXT_CONSOLENAMEALREADYINUSE = 'text_consolenamealreadyinuse';
export const TEXT_CONSOLENAMEGENERICERROR = 'text_consolenamegenericerror';
export const TEXT_CONSOLEREFRESH = 'text_consolerefresh';
export const TEXT_CONSOLES = 'text_consoles';
export const TEXT_CONTACTEMAIL = 'text_contactemail';
export const TEXT_CONTACTPHONE = 'text_contactphone';
export const TEXT_CONVERT = 'text_convert';
export const TEXT_CONVERT_CONSOLE = 'text_convert_console';
export const TEXT_CONVERT_CONSOLE_PARAGRAPH_FIVE = 'text_convert_console_paragraph_five';
export const TEXT_CONVERT_CONSOLE_PARAGRAPH_FOUR = 'text_convert_console_paragraph_four';
export const TEXT_CONVERT_CONSOLE_PARAGRAPH_ONE = 'text_convert_console_paragraph_one';
export const TEXT_CONVERT_CONSOLE_PARAGRAPH_SIX = 'text_convert_console_paragraph_six';
export const TEXT_CONVERT_CONSOLE_PARAGRAPH_THREE = 'text_convert_console_paragraph_three';
export const TEXT_CONVERT_CONSOLE_PARAGRAPH_TWO = 'text_convert_console_paragraph_two';
export const TEXT_CONVERTING = 'text_converting';
export const TEXT_CONVERTTRIALTOFULL = 'text_converttrialtofull';
export const TEXT_COPY = 'text_copy';
export const TEXT_COPYPOLICY = 'text_copypolicy';
export const TEXT_COPYREPORT = 'text_copyreport';
export const TEXT_COPYTEMPLATE = 'text_copytemplate';
export const TEXT_CORESHIELD = 'text_coreshield';
export const TEXT_COUNT = 'text_count';
export const TEXT_COUNTRYCODE = 'text_countrycode';
export const TEXT_CREATE = 'text_create';
export const TEXT_CREATE_ALLOW_OVERRIDE = 'text_create_allow_override';
export const TEXT_CREATE_BLOCK_OVERRIDE = 'text_create_block_override';
export const TEXT_CREATEADMIN = 'text_createadmin';
export const TEXT_CREATEALERT = 'text_createalert';
export const TEXT_CREATEDBY = 'text_createdby';
export const TEXT_CREATEDISTLIST = 'text_createdistlist';
export const TEXT_CREATEDNSPOLICY = 'text_creatednspolicy';
export const TEXT_CREATEGROUP = 'text_creategroup';
export const TEXT_CREATENEWENTRY = 'text_createnewentry';
export const TEXT_CREATENEWLIST = 'text_createnewlist';
export const TEXT_CREATEOVERRIDE = 'text_createoverride';
export const TEXT_CREATEPOLICY = 'text_createpolicy';
export const TEXT_CREATEREPORT = 'text_createreport';
export const TEXT_CREATERMM = 'text_creatermm';
export const TEXT_CREATETEMPLATE = 'text_createtemplate';
export const TEXT_CREATIONMETHOD = 'text_creationmethod';
export const TEXT_CREATIONTYPE = 'text_creationtype';
export const TEXT_CREATIONTYPE1 = 'text_creationtype1';
export const TEXT_CREATIONTYPE2 = 'text_creationtype2';
export const TEXT_CREDCLIENTID = 'text_credClientId';
export const TEXT_CREDDESC = 'text_creddesc';
export const TEXT_CREDNAME = 'text_credname';
export const TEXT_CREDSECRET = 'text_credsecret';
export const TEXT_CS_ELAPSED = 'text_cs_elapsed';
export const TEXT_CS_EXECUTED = 'text_cs_executed';
export const TEXT_CS_NOTRECIEVED = 'text_cs_notrecieved';
export const TEXT_CSS_ACCESSTHISCONSOLE = 'text_css_accessthisconsole';
export const TEXT_CSS_CONSOLENAME = 'text_css_consolename';
export const TEXT_CSS_DATECREATED = 'text_css_datecreated';
export const TEXT_CSS_DEVICESALLOWED = 'text_css_devicesallowed';
export const TEXT_CSS_EXPIREDKEYCODES = 'text_css_expiredkeycodes';
export const TEXT_CSS_GSMCONSOLENAME = 'text_css_gsmconsolename';
export const TEXT_CSS_KEYCODES = 'text_css_keycodes';
export const TEXT_CSS_LOADINGCONSOLES = 'text_css_loadingconsoles';
export const TEXT_CSS_LOADINGERROR = 'text_css_loadingerror';
export const TEXT_CSS_TOTALSITES = 'text_css_totalsites';
export const TEXT_CSVFILEREQUESTED = 'text_csvfilerequested';
export const TEXT_CSVFILEREQUESTEDDESC = 'text_csvfilerequesteddesc';
export const TEXT_CSVREQUESTSUCCESS = 'text_csvrequestsuccess';
export const TEXT_CT_ALLOWAPP = 'text_ct_allowapp';
export const TEXT_CT_CHANGEKEYCODE = 'text_ct_changekeycode';
export const TEXT_CT_DENYAPP = 'text_ct_denyapp';
export const TEXT_CT_DLEXEC = 'text_ct_dlexec';
export const TEXT_CT_EMPTYLOGS = 'text_ct_emptylogs';
export const TEXT_CT_LOCKPC = 'text_ct_lockpc';
export const TEXT_CT_LOGOFF = 'text_ct_logoff';
export const TEXT_CT_PROTECTAPP = 'text_ct_protectapp';
export const TEXT_CT_PROXY = 'text_ct_proxy';
export const TEXT_CT_RESTOREFILE = 'text_ct_restorefile';
export const TEXT_CT_RUNBAT = 'text_ct_runbat';
export const TEXT_CT_RUNBATONBOOT = 'text_ct_runbatonboot';
export const TEXT_CT_RUNCLEANUPSCRIPT = 'text_ct_runcleanupscript';
export const TEXT_CT_RUNREG = 'text_ct_runreg';
export const TEXT_CT_SCAN = 'text_ct_scan';
export const TEXT_CT_SCANFOLDER = 'text_ct_scanfolder';
export const TEXT_CT_SCANTIME = 'text_ct_scantime';
export const TEXT_CT_TEMPKEYCODE = 'text_ct_tempkeycode';
export const TEXT_CT_UNINSTALL = 'text_ct_uninstall';
export const TEXT_CT_UNPROTECTAPP = 'text_ct_unprotectapp';
export const TEXT_CT_WSABLOGS = 'text_ct_wsablogs';
export const TEXT_CURRENTLY = 'text_currently';
export const TEXT_CURRENTUSER = 'text_currentuser';
export const TEXT_CUSTOM_SCAN = 'text_custom_scan';
export const TEXT_CUSTOMDATERANGE = 'text_customdaterange';
export const TEXT_CUSTOMLOGONOTSUPPORTED = 'text_customlogonotsupported';
export const TEXT_CUSTOMRANGE = 'text_customrange';
export const TEXT_DAILY = 'text_daily';
export const TEXT_DASHBOARD = 'text_dashboard';
export const TEXT_DASHBOARD_WELCOME_PARAGRAPH_ONE = 'text_dashboard_welcome_paragraph_one';
export const TEXT_DASHBOARD_WELCOME_PARAGRAPH_THREE = 'text_dashboard_welcome_paragraph_three';
export const TEXT_DASHBOARD_WELCOME_PARAGRAPH_TWO = 'text_dashboard_welcome_paragraph_two';
export const TEXT_DASHBOARD_WELCOME_SUBHEADING = 'text_dashboard_welcome_subheading';
export const TEXT_DASHBOARD_WELCOME_TITLE = 'text_dashboard_welcome_title';
export const TEXT_DASHBOARDSUMMARY = 'text_dashboardsummary';
export const TEXT_DATAFIELD = 'text_datafield';
export const TEXT_DATAFILTERLOG = 'text_datafilterlog';
export const TEXT_DATE = 'text_date';
export const TEXT_DATE10TH = 'text_date10th';
export const TEXT_DATE11TH = 'text_date11th';
export const TEXT_DATE12TH = 'text_date12th';
export const TEXT_DATE13TH = 'text_date13th';
export const TEXT_DATE14TH = 'text_date14th';
export const TEXT_DATE15TH = 'text_date15th';
export const TEXT_DATE16TH = 'text_date16th';
export const TEXT_DATE17TH = 'text_date17th';
export const TEXT_DATE18TH = 'text_date18th';
export const TEXT_DATE19TH = 'text_date19th';
export const TEXT_DATE1ST = 'text_date1st';
export const TEXT_DATE20TH = 'text_date20th';
export const TEXT_DATE21ST = 'text_date21st';
export const TEXT_DATE22ND = 'text_date22nd';
export const TEXT_DATE23RD = 'text_date23rd';
export const TEXT_DATE24TH = 'text_date24th';
export const TEXT_DATE25TH = 'text_date25th';
export const TEXT_DATE26TH = 'text_date26th';
export const TEXT_DATE27TH = 'text_date27th';
export const TEXT_DATE28TH = 'text_date28th';
export const TEXT_DATE29TH = 'text_date29th';
export const TEXT_DATE2ND = 'text_date2nd';
export const TEXT_DATE30TH = 'text_date30th';
export const TEXT_DATE31ST = 'text_date31st';
export const TEXT_DATE3RD = 'text_date3rd';
export const TEXT_DATE4TH = 'text_date4th';
export const TEXT_DATE5TH = 'text_date5th';
export const TEXT_DATE6TH = 'text_date6th';
export const TEXT_DATE7TH = 'text_date7th';
export const TEXT_DATE8TH = 'text_date8th';
export const TEXT_DATE9TH = 'text_date9th';
export const TEXT_DATECREATED = 'text_datecreated';
export const TEXT_DATEFROM = 'text_datefrom';
export const TEXT_DATELASTMODIFIED = 'text_datelastmodified';
export const TEXT_DATEREQUESTED = 'text_daterequested';
export const TEXT_DATETO = 'text_dateto';
export const TEXT_DAY = 'text_day';
export const TEXT_DAYS = 'text_days';
export const TEXT_DAYSAGO = 'text_daysago';
export const TEXT_DAYSREMAINING = 'text_daysremaining';
export const TEXT_DD_EI_ACTIVESCANS = 'text_dd_ei_ActiveScans';
export const TEXT_DD_EI_ACTIVETHREATS = 'text_dd_ei_ActiveThreats';
export const TEXT_DD_EI_ATTENTIONREQUIRED = 'text_dd_ei_AttentionRequired';
export const TEXT_DD_EI_CURRENTUSER = 'text_dd_ei_CurrentUser';
export const TEXT_DD_EI_DAYSREMAINING = 'text_dd_ei_DaysRemaining';
export const TEXT_DD_EI_DEVICETYPE = 'text_dd_ei_DeviceType';
export const TEXT_DD_EI_ENDPOINT = 'text_dd_ei_Endpoint';
export const TEXT_DD_EI_EXPIRATIONDATE = 'text_dd_ei_ExpirationDate';
export const TEXT_DD_EI_FIREWALLENABLED = 'text_dd_ei_FirewallEnabled';
export const TEXT_DD_EI_HOSTNAME = 'text_dd_ei_HostName';
export const TEXT_DD_EI_IDSHIELDENABLED = 'text_dd_ei_IDShieldEnabled';
export const TEXT_DD_EI_INFECTED = 'text_dd_ei_Infected';
export const TEXT_DD_EI_INFRAREDENABLED = 'text_dd_ei_InfraredEnabled';
export const TEXT_DD_EI_INTERNALIP = 'text_dd_ei_InternalIP';
export const TEXT_DD_EI_ISEXPIRED = 'text_dd_ei_IsExpired';
export const TEXT_DD_EI_KEYCODE = 'text_dd_ei_KeyCode';
export const TEXT_DD_EI_LASTSCAN = 'text_dd_ei_LastScan';
export const TEXT_DD_EI_LASTSCANCOUNT = 'text_dd_ei_LastScanCount';
export const TEXT_DD_EI_LASTSCANDURATION = 'text_dd_ei_LastScanDuration';
export const TEXT_DD_EI_MACADDRESS = 'text_dd_ei_MacAddress';
export const TEXT_DD_EI_MANAGED = 'text_dd_ei_Managed';
export const TEXT_DD_EI_OSFIREWALLENABLED = 'text_dd_ei_OSFirewallEnabled';
export const TEXT_DD_EI_OFFLINESHIELDENABLED = 'text_dd_ei_OfflineShieldEnabled';
export const TEXT_DD_EI_PHISHINGSHIELDENABLED = 'text_dd_ei_PhishingShieldEnabled';
export const TEXT_DD_EI_PRIMARYBROWSER = 'text_dd_ei_PrimaryBrowser';
export const TEXT_DD_EI_PRIMARYBROWSERVERSION = 'text_dd_ei_PrimaryBrowserVersion';
export const TEXT_DD_EI_PROTECTIONENABLED = 'text_dd_ei_ProtectionEnabled';
export const TEXT_DD_EI_REMEDIATIONENABLED = 'text_dd_ei_RemediationEnabled';
export const TEXT_DD_EI_ROOTKITSHIELDENABLED = 'text_dd_ei_RootkitShieldEnabled';
export const TEXT_DD_EI_SCANCOUNT = 'text_dd_ei_ScanCount';
export const TEXT_DD_EI_SCANONBOOTUP = 'text_dd_ei_ScanOnBootup';
export const TEXT_DD_EI_SCANWHENIDLE = 'text_dd_ei_ScanWhenIdle';
export const TEXT_DD_EI_SCHEDULESCANSENABLED = 'text_dd_ei_ScheduleScansEnabled';
export const TEXT_DD_EI_SCHEDULEDSCANFREQUENCY = 'text_dd_ei_ScheduledScanFrequency';
export const TEXT_DD_EI_SCHEDULEDSCANTIME = 'text_dd_ei_ScheduledScanTime';
export const TEXT_DD_EI_THREATSREMOVED = 'text_dd_ei_ThreatsRemoved';
export const TEXT_DD_EI_USBSHIELDENABLED = 'text_dd_ei_USBShieldEnabled';
export const TEXT_DD_EI_UNINSTALLED = 'text_dd_ei_Uninstalled';
export const TEXT_DD_EI_UPDATETIME = 'text_dd_ei_UpdateTime';
export const TEXT_DD_EI_VM = 'text_dd_ei_VM';
export const TEXT_DD_EI_VERSION = 'text_dd_ei_Version';
export const TEXT_DD_EI_WSA = 'text_dd_ei_WSA';
export const TEXT_DD_EI_WSASCANINFORMATION = 'text_dd_ei_WSAScanInformation';
export const TEXT_DD_EI_WSASHIELDS = 'text_dd_ei_WSAShields';
export const TEXT_DD_EI_WEBTHREATSHIELDENABLED = 'text_dd_ei_WebThreatShieldEnabled';
export const TEXT_DD_HOSTNAME = 'text_dd_hostname';
export const TEXT_DD_KEYCODE = 'text_dd_keycode';
export const TEXT_DD_SITE = 'text_dd_site';
export const TEXT_DE = 'text_de';
export const TEXT_DEACTIVATED = 'text_deactivated';
export const TEXT_DEACTIVATEDBY = 'text_deactivatedby';
export const TEXT_DEACTIVATING = 'text_deactivating';
export const TEXT_DEADSITES = 'text_deadsites';
export const TEXT_DECEMBER = 'text_december';
export const TEXT_DEEP_SCAN = 'text_deep_scan';
export const TEXT_DEFAULTDNSPOLICY = 'text_defaultdnspolicy';
export const TEXT_DEFAULTDNSPOLICY_DESC = 'text_defaultdnspolicy_desc';
export const TEXT_DEFAULTENDPOINTPOLICY = 'text_defaultendpointpolicy';
export const TEXT_DEFAULTGROUP = 'text_defaultgroup';
export const TEXT_DEFAULTPOLICY = 'text_defaultpolicy';
export const TEXT_DELETE = 'text_delete';
export const TEXT_DELETE_MULTIPLE_BLACKLIST = 'text_delete_multiple_blacklist';
export const TEXT_DELETE_MULTIPLE_WHITELIST = 'text_delete_multiple_whitelist';
export const TEXT_DELETE_OVERRIDE = 'text_delete_override';
export const TEXT_DELETE_THE_FOLLOWING_ENTRY = 'text_delete_the_following_entry';
export const TEXT_DELETEADMIN = 'text_deleteadmin';
export const TEXT_DELETEADMINDESC1 = 'text_deleteadmindesc1';
export const TEXT_DELETEADMINDESC2 = 'text_deleteadmindesc2';
export const TEXT_DELETEALERT = 'text_deletealert';
export const TEXT_DELETEBLACKLISTENTRY = 'text_deleteblacklistentry';
export const TEXT_DELETECHARTTEXT = 'text_deletecharttext';
export const TEXT_DELETECHARTTITLE = 'text_deletecharttitle';
export const TEXT_DELETECURRENTIMAGE = 'text_deletecurrentimage';
export const TEXT_DELETED = 'text_deleted';
export const TEXT_DELETEDISTLIST = 'text_deletedistlist';
export const TEXT_DELETEDOMAINROW = 'text_deletedomainrow';
export const TEXT_DELETEDUSERDESC1 = 'text_deleteduserdesc1';
export const TEXT_DELETEGROUP = 'text_deletegroup';
export const TEXT_DELETEIPROW = 'text_deleteiprow';
export const TEXT_DELETEOVERRIDE = 'text_deleteoverride';
export const TEXT_DELETEPAGE = 'text_deletepage';
export const TEXT_DELETEPOLICY = 'text_deletepolicy';
export const TEXT_DELETEREPORT = 'text_deletereport';
export const TEXT_DELETERMM = 'text_deletermm';
export const TEXT_DELETERMMDESC = 'text_deletermmdesc';
export const TEXT_DELETETEMPLATE = 'text_deletetemplate';
export const TEXT_DELETEWHITELISTENTRY = 'text_deletewhitelistentry';
export const TEXT_DELETING = 'text_deleting';
export const TEXT_DELIVERYHISTORY0 = 'text_deliveryhistory0';
export const TEXT_DELIVERYHISTORY1 = 'text_deliveryhistory1';
export const TEXT_DELIVERYHISTORY2 = 'text_deliveryhistory2';
export const TEXT_DELIVERYMETHOD1 = 'text_deliverymethod1';
export const TEXT_DELIVERYMETHOD2 = 'text_deliverymethod2';
export const TEXT_DELIVERYMETHOD3 = 'text_deliverymethod3';
export const TEXT_DELIVERYSCHEDULE = 'text_deliveryschedule';
export const TEXT_DEMO_EXPORTEXPLANATION = 'text_demo_exportexplanation';
export const TEXT_DEMO_IMPORTEXPLANATION = 'text_demo_importexplanation';
export const TEXT_DEMO_MANUAL = 'text_demo_manual';
export const TEXT_DEMO_MANUAL_DESC = 'text_demo_manual_desc';
export const TEXT_DEMO_OLDDATA_CANCEL = 'text_demo_olddata_cancel';
export const TEXT_DEMO_OLDDATA_CONFIRM = 'text_demo_olddata_confirm';
export const TEXT_DEMO_OLDDATA_DESCRIPTION = 'text_demo_olddata_description';
export const TEXT_DEMO_OLDDATA_TITLE = 'text_demo_olddata_title';
export const TEXT_DEMO_OLDDATA_WARNING = 'text_demo_olddata_warning';
export const TEXT_DEMO_OPTIONTITLE = 'text_demo_optiontitle';
export const TEXT_DEMO_UNATTENDED = 'text_demo_unattended';
export const TEXT_DEMO_UNATTENDED_DESC = 'text_demo_unattended_desc';
export const TEXT_DEMO_UNIT_HOSTNAME = 'text_demo_unit_hostname';
export const TEXT_DEPLOYING_WEBROOT_SECUREANYWHERE = 'text_deploying_webroot_secureanywhere';
export const TEXT_DEPLOYMENTEXTERNAL = 'text_deploymentexternal';
export const TEXT_DEPLOYMENTINTERNAL = 'text_deploymentinternal';
export const TEXT_DEPLOYMENTTYPE = 'text_deploymenttype';
export const TEXT_DESCRIPTION = 'text_description';
export const TEXT_DETAILS = 'text_details';
export const TEXT_DETECT_IF_MALICIOUS = 'text_detect_if_malicious';
export const TEXT_DETECT_MALICIOUS_TOOLTIP = 'text_detect_malicious_tooltip';
export const TEXT_DETECTANDREMEDIATE = 'text_detectandremediate';
export const TEXT_DETECTANDREPORT = 'text_detectandreport';
export const TEXT_DETECTED = 'text_detected';
export const TEXT_DETERMINATION = 'text_determination';
export const TEXT_DEVICE = 'text_device';
export const TEXT_DEVICEACTIVATIONS = 'text_deviceactivations';
export const TEXT_DEVICEINFO = 'text_deviceinfo';
export const TEXT_DEVICENAME = 'text_devicename';
export const TEXT_DEVICEREPORTS = 'text_devicereports';
export const TEXT_DEVICES = 'text_devices';
export const TEXT_DEVICES_ENCOUNTERING_THREATS = 'text_devices_encountering_threats';
export const TEXT_DEVICES_WITH_THREATS_SEEN_ON_LAST_SCAN = 'text_devices_with_threats_seen_on_last_scan';
export const TEXT_DEVICES_WITH_UNDETERMINED_SOFTWARE_ON_LAST_SCAN = 'text_devices_with_undetermined_software_on_last_scan';
export const TEXT_DEVICESACTIVE = 'text_devicesactive';
export const TEXT_DEVICESENCOUNTERING24HRS = 'text_devicesencountering24hrs';
export const TEXT_DEVICESINSTALLED7DAYS = 'text_devicesinstalled7days';
export const TEXT_DEVICESLASTTWENTYFOUR = 'text_deviceslasttwentyfour';
export const TEXT_DEVICESNEEDINGATTENTION = 'text_devicesneedingattention';
export const TEXT_DEVICESPURCHASED = 'text_devicespurchased';
export const TEXT_DEVICESREQUIRINGATTENTION = 'text_devicesrequiringattention';
export const TEXT_DEVICESREQUIRINGATTENTION7DAYS = 'text_devicesrequiringattention7days';
export const TEXT_DEVICESREQUIRINGATTENTIONNOW = 'text_devicesrequiringattentionnow';
export const TEXT_DEVICESSEEN = 'text_devicesseen';
export const TEXT_DEVICETYPESTATUS = 'text_devicetypestatus';
export const TEXT_DISABLE = 'text_disable';
export const TEXT_DISABLE2FADESC = 'text_disable2fadesc';
export const TEXT_DISABLED = 'text_disabled';
export const TEXT_DISCLAIMER = 'text_disclaimer';
export const TEXT_DISLISTNAMEALREADYEXISTS = 'text_dislistnamealreadyexists';
export const TEXT_DISTLISTEMAILS = 'text_distlistemails';
export const TEXT_DISTRIBUTIONLIST = 'text_distributionlist';
export const TEXT_DISTRIBUTIONLISTNAME = 'text_distributionlistname';
export const TEXT_DISTRIBUTIONLISTS = 'text_distributionlists';
export const TEXT_DISTRIBUTIONLISTS_SCOPE_TOOLTIP = 'text_distributionlists_scope_tooltip';
export const TEXT_DNS = 'text_dns';
export const TEXT_DNS_ACTIVE_DEVICE_COUNT = 'text_dns_active_device_count';
export const TEXT_DNS_AGENT_VERSION_SPREAD = 'text_dns_agent_version_spread';
export const TEXT_DNS_COUNT_CARD_DISABLED = 'text_dns_count_card_disabled';
export const TEXT_DNS_COUNT_CARD_EXPIRED = 'text_dns_count_card_expired';
export const TEXT_DNS_COUNT_CARD_EXPIRED_SITE_ONLY_CONSOLE = 'text_dns_count_card_expired_site_only_console';
export const TEXT_DNS_COUNT_CARD_NOT_TRIALED = 'text_dns_count_card_not_trialed';
export const TEXT_DNS_DEFAULT_AGENT_HIGH_SECURITY_POLICY = 'text_dns_default_agent_high_security_policy';
export const TEXT_DNS_DEFAULT_AGENT_POLICY_LABEL = 'text_dns_default_agent_policy_label';
export const TEXT_DNS_ECHOLOCALRESOLVERS = 'text_dns_echolocalresolvers';
export const TEXT_DNS_ECHOLOCALRESOLVERS_TOOLTIP = 'text_dns_echolocalresolvers_tooltip';
export const TEXT_DNS_ENABLED = 'text_dns_enabled';
export const TEXT_DNS_ENABLESERVER = 'text_dns_enableserver';
export const TEXT_DNS_ENABLESERVER_TOOLTIP = 'text_dns_enableserver_tooltip';
export const TEXT_DNS_FAILOPEN = 'text_dns_failopen';
export const TEXT_DNS_FAILOPEN_TOOLTIP = 'text_dns_failopen_tooltip';
export const TEXT_DNS_GOOGLESAFESEARCH = 'text_dns_googlesafesearch';
export const TEXT_DNS_GOOGLESAFESEARCH_TOOLTIP = 'text_dns_googlesafesearch_tooltip';
export const TEXT_DNS_LOGDNSTRAFFIC = 'text_dns_logdnstraffic';
export const TEXT_DNS_LOGDNSTRAFFIC_TOOLTIP = 'text_dns_logdnstraffic_tooltip';
export const TEXT_DNS_MORE_INFO_LINK_ONE = 'text_dns_more_info_link_one';
export const TEXT_DNS_PRIVACYDESCRIPTION_000 = 'text_dns_privacydescription_000';
export const TEXT_DNS_PRIVACYDESCRIPTION_001 = 'text_dns_privacydescription_001';
export const TEXT_DNS_PRIVACYDESCRIPTION_010 = 'text_dns_privacydescription_010';
export const TEXT_DNS_PRIVACYDESCRIPTION_011 = 'text_dns_privacydescription_011';
export const TEXT_DNS_PRIVACYDESCRIPTION_100 = 'text_dns_privacydescription_100';
export const TEXT_DNS_PRIVACYDESCRIPTION_101 = 'text_dns_privacydescription_101';
export const TEXT_DNS_PRIVACYDESCRIPTION_110 = 'text_dns_privacydescription_110';
export const TEXT_DNS_PRIVACYDESCRIPTION_111 = 'text_dns_privacydescription_111';
export const TEXT_DNS_PRIVACYSETTINGS = 'text_dns_privacysettings';
export const TEXT_DNS_PRIVACYSETTINGS_DESC = 'text_dns_privacysettings_desc';
export const TEXT_DNS_PRIVACYTITLE_000 = 'text_dns_privacytitle_000';
export const TEXT_DNS_PRIVACYTITLE_001 = 'text_dns_privacytitle_001';
export const TEXT_DNS_PRIVACYTITLE_010 = 'text_dns_privacytitle_010';
export const TEXT_DNS_PRIVACYTITLE_011 = 'text_dns_privacytitle_011';
export const TEXT_DNS_PRIVACYTITLE_100 = 'text_dns_privacytitle_100';
export const TEXT_DNS_PRIVACYTITLE_101 = 'text_dns_privacytitle_101';
export const TEXT_DNS_PRIVACYTITLE_110 = 'text_dns_privacytitle_110';
export const TEXT_DNS_PRIVACYTITLE_111 = 'text_dns_privacytitle_111';
export const TEXT_DNS_QUICK_SETUP_FOOTER = 'text_dns_quick_setup_footer';
export const TEXT_DNS_QUICK_SETUP_LINK1 = 'text_dns_quick_setup_link1';
export const TEXT_DNS_QUICK_SETUP_LINK2 = 'text_dns_quick_setup_link2';
export const TEXT_DNS_QUICK_SETUP_PARAGRAPH_ONE = 'text_dns_quick_setup_paragraph_one';
export const TEXT_DNS_QUICK_SETUP_STEP_ONE_BULLET1 = 'text_dns_quick_setup_step_one_bullet1';
export const TEXT_DNS_QUICK_SETUP_STEP_ONE_BULLET2 = 'text_dns_quick_setup_step_one_bullet2';
export const TEXT_DNS_QUICK_SETUP_STEP_ONE_TITLE = 'text_dns_quick_setup_step_one_title';
export const TEXT_DNS_QUICK_SETUP_STEP_THREE_BULLET1 = 'text_dns_quick_setup_step_three_bullet1';
export const TEXT_DNS_QUICK_SETUP_STEP_THREE_TITLE = 'text_dns_quick_setup_step_three_title';
export const TEXT_DNS_QUICK_SETUP_STEP_TWO_BULLET1 = 'text_dns_quick_setup_step_two_bullet1';
export const TEXT_DNS_QUICK_SETUP_STEP_TWO_BULLET2 = 'text_dns_quick_setup_step_two_bullet2';
export const TEXT_DNS_QUICK_SETUP_STEP_TWO_TITLE = 'text_dns_quick_setup_step_two_title';
export const TEXT_DNS_QUICK_SETUP_TITLE = 'text_dns_quick_setup_title';
export const TEXT_DNS_REENABLE_DIALOG_REENABLE_PARAPRAPH = 'text_dns_reenable_dialog_reenable_parapraph';
export const TEXT_DNS_RESTART_GUIDED_SETUP_DEVICES_REQUIRED = 'text_dns_restart_guided_setup_devices_required';
export const TEXT_DNS_RESUME_GUIDED_SETUP_MESSAGE = 'text_dns_resume_guided_setup_message';
export const TEXT_DNS_SECURITY_RISK_REPORT = 'text_dns_security_risk_report';
export const TEXT_DNS_TOOLTIP_AGENT_POLICY = 'text_dns_tooltip_agent_policy';
export const TEXT_DNS_TOOLTIP_DEVICE_COUNT = 'text_dns_tooltip_device_count';
export const TEXT_DNS_TRIAL_HEADER = 'text_dns_trial_header';
export const TEXT_DNSCAT0 = 'text_dnscat0';
export const TEXT_DNSCAT1 = 'text_dnscat1';
export const TEXT_DNSCAT10 = 'text_dnscat10';
export const TEXT_DNSCAT11 = 'text_dnscat11';
export const TEXT_DNSCAT12 = 'text_dnscat12';
export const TEXT_DNSCAT13 = 'text_dnscat13';
export const TEXT_DNSCAT14 = 'text_dnscat14';
export const TEXT_DNSCAT16 = 'text_dnscat16';
export const TEXT_DNSCAT17 = 'text_dnscat17';
export const TEXT_DNSCAT18 = 'text_dnscat18';
export const TEXT_DNSCAT19 = 'text_dnscat19';
export const TEXT_DNSCAT2 = 'text_dnscat2';
export const TEXT_DNSCAT20 = 'text_dnscat20';
export const TEXT_DNSCAT21 = 'text_dnscat21';
export const TEXT_DNSCAT22 = 'text_dnscat22';
export const TEXT_DNSCAT23 = 'text_dnscat23';
export const TEXT_DNSCAT24 = 'text_dnscat24';
export const TEXT_DNSCAT25 = 'text_dnscat25';
export const TEXT_DNSCAT26 = 'text_dnscat26';
export const TEXT_DNSCAT27 = 'text_dnscat27';
export const TEXT_DNSCAT28 = 'text_dnscat28';
export const TEXT_DNSCAT29 = 'text_dnscat29';
export const TEXT_DNSCAT3 = 'text_dnscat3';
export const TEXT_DNSCAT30 = 'text_dnscat30';
export const TEXT_DNSCAT31 = 'text_dnscat31';
export const TEXT_DNSCAT32 = 'text_dnscat32';
export const TEXT_DNSCAT33 = 'text_dnscat33';
export const TEXT_DNSCAT34 = 'text_dnscat34';
export const TEXT_DNSCAT35 = 'text_dnscat35';
export const TEXT_DNSCAT36 = 'text_dnscat36';
export const TEXT_DNSCAT37 = 'text_dnscat37';
export const TEXT_DNSCAT38 = 'text_dnscat38';
export const TEXT_DNSCAT39 = 'text_dnscat39';
export const TEXT_DNSCAT4 = 'text_dnscat4';
export const TEXT_DNSCAT40 = 'text_dnscat40';
export const TEXT_DNSCAT41 = 'text_dnscat41';
export const TEXT_DNSCAT42 = 'text_dnscat42';
export const TEXT_DNSCAT43 = 'text_dnscat43';
export const TEXT_DNSCAT44 = 'text_dnscat44';
export const TEXT_DNSCAT45 = 'text_dnscat45';
export const TEXT_DNSCAT46 = 'text_dnscat46';
export const TEXT_DNSCAT47 = 'text_dnscat47';
export const TEXT_DNSCAT48 = 'text_dnscat48';
export const TEXT_DNSCAT49 = 'text_dnscat49';
export const TEXT_DNSCAT5 = 'text_dnscat5';
export const TEXT_DNSCAT50 = 'text_dnscat50';
export const TEXT_DNSCAT51 = 'text_dnscat51';
export const TEXT_DNSCAT52 = 'text_dnscat52';
export const TEXT_DNSCAT53 = 'text_dnscat53';
export const TEXT_DNSCAT54 = 'text_dnscat54';
export const TEXT_DNSCAT55 = 'text_dnscat55';
export const TEXT_DNSCAT56 = 'text_dnscat56';
export const TEXT_DNSCAT57 = 'text_dnscat57';
export const TEXT_DNSCAT58 = 'text_dnscat58';
export const TEXT_DNSCAT59 = 'text_dnscat59';
export const TEXT_DNSCAT6 = 'text_dnscat6';
export const TEXT_DNSCAT60 = 'text_dnscat60';
export const TEXT_DNSCAT61 = 'text_dnscat61';
export const TEXT_DNSCAT62 = 'text_dnscat62';
export const TEXT_DNSCAT63 = 'text_dnscat63';
export const TEXT_DNSCAT64 = 'text_dnscat64';
export const TEXT_DNSCAT65 = 'text_dnscat65';
export const TEXT_DNSCAT66 = 'text_dnscat66';
export const TEXT_DNSCAT67 = 'text_dnscat67';
export const TEXT_DNSCAT68 = 'text_dnscat68';
export const TEXT_DNSCAT69 = 'text_dnscat69';
export const TEXT_DNSCAT7 = 'text_dnscat7';
export const TEXT_DNSCAT70 = 'text_dnscat70';
export const TEXT_DNSCAT71 = 'text_dnscat71';
export const TEXT_DNSCAT72 = 'text_dnscat72';
export const TEXT_DNSCAT74 = 'text_dnscat74';
export const TEXT_DNSCAT75 = 'text_dnscat75';
export const TEXT_DNSCAT76 = 'text_dnscat76';
export const TEXT_DNSCAT77 = 'text_dnscat77';
export const TEXT_DNSCAT78 = 'text_dnscat78';
export const TEXT_DNSCAT79 = 'text_dnscat79';
export const TEXT_DNSCAT8 = 'text_dnscat8';
export const TEXT_DNSCAT80 = 'text_dnscat80';
export const TEXT_DNSCAT81 = 'text_dnscat81';
export const TEXT_DNSCAT82 = 'text_dnscat82';
export const TEXT_DNSCAT9 = 'text_dnscat9';
export const TEXT_DNSCAT999 = 'text_dnscat999';
export const TEXT_DNSCATGRP1 = 'text_dnscatgrp1';
export const TEXT_DNSCATGRP10 = 'text_dnscatgrp10';
export const TEXT_DNSCATGRP11 = 'text_dnscatgrp11';
export const TEXT_DNSCATGRP2 = 'text_dnscatgrp2';
export const TEXT_DNSCATGRP3 = 'text_dnscatgrp3';
export const TEXT_DNSCATGRP4 = 'text_dnscatgrp4';
export const TEXT_DNSCATGRP5 = 'text_dnscatgrp5';
export const TEXT_DNSCATGRP6 = 'text_dnscatgrp6';
export const TEXT_DNSCATGRP7 = 'text_dnscatgrp7';
export const TEXT_DNSCATGRP8 = 'text_dnscatgrp8';
export const TEXT_DNSCATGRP9 = 'text_dnscatgrp9';
export const TEXT_DNSIPREGIONDESCRIPTION = 'text_dnsipregiondescription';
export const TEXT_DNSPOLICY = 'text_dnspolicy';
export const TEXT_DNSPOLICYSECTIONDESC = 'text_dnspolicysectiondesc';
export const TEXT_DNSPROTECTION = 'text_dnsprotection';
export const TEXT_DNSQS_FOOTER = 'text_dnsqs_footer';
export const TEXT_DNSQS_STEP1 = 'text_dnsqs_step1';
export const TEXT_DNSQS_STEP1_1 = 'text_dnsqs_step1_1';
export const TEXT_DNSQS_STEP1_2 = 'text_dnsqs_step1_2';
export const TEXT_DNSQS_STEP2 = 'text_dnsqs_step2';
export const TEXT_DNSQS_STEP2_1 = 'text_dnsqs_step2_1';
export const TEXT_DNSQS_STEP2_2 = 'text_dnsqs_step2_2';
export const TEXT_DNSQS_STEP2_3 = 'text_dnsqs_step2_3';
export const TEXT_DNSQS_STEP2_4 = 'text_dnsqs_step2_4';
export const TEXT_DNSQS_STEP3 = 'text_dnsqs_step3';
export const TEXT_DNSQS_STEP3_1 = 'text_dnsqs_step3_1';
export const TEXT_DNSQS_STEP3_2 = 'text_dnsqs_step3_2';
export const TEXT_DNSQS_STEP3_3 = 'text_dnsqs_step3_3';
export const TEXT_DNSQS_STEP3_4 = 'text_dnsqs_step3_4';
export const TEXT_DNSQS_STEP4 = 'text_dnsqs_step4';
export const TEXT_DNSQS_TITLE = 'text_dnsqs_title';
export const TEXT_DNSREGION_1 = 'text_dnsregion_1';
export const TEXT_DNSREGION_10 = 'text_dnsregion_10';
export const TEXT_DNSREGION_11 = 'text_dnsregion_11';
export const TEXT_DNSREGION_12 = 'text_dnsregion_12';
export const TEXT_DNSREGION_13 = 'text_dnsregion_13';
export const TEXT_DNSREGION_14 = 'text_dnsregion_14';
export const TEXT_DNSREGION_15 = 'text_dnsregion_15';
export const TEXT_DNSREGION_16 = 'text_dnsregion_16';
export const TEXT_DNSREGION_17 = 'text_dnsregion_17';
export const TEXT_DNSREGION_18 = 'text_dnsregion_18';
export const TEXT_DNSREGION_2 = 'text_dnsregion_2';
export const TEXT_DNSREGION_3 = 'text_dnsregion_3';
export const TEXT_DNSREGION_4 = 'text_dnsregion_4';
export const TEXT_DNSREGION_5 = 'text_dnsregion_5';
export const TEXT_DNSREGION_6 = 'text_dnsregion_6';
export const TEXT_DNSREGION_7 = 'text_dnsregion_7';
export const TEXT_DNSREGION_8 = 'text_dnsregion_8';
export const TEXT_DNSREGION_9 = 'text_dnsregion_9';
export const TEXT_DNSREPORTS = 'text_dnsreports';
export const TEXT_DNSREQUESTORBLOCKCLICK = 'text_dnsrequestorblockclick';
export const TEXT_DNSSERVER = 'text_dnsserver';
export const TEXT_DNSTRIALTITLE = 'text_dnstrialtitle';
export const TEXT_DO_YOU_PLAN_TO_USE_THE_EVENT_NOTIFICATION_API = 'text_do_you_plan_to_use_the_event_notification_api';
export const TEXT_DO_YOU_PLAN_TO_USE_THE_EVENT_NOTIFICATION_API_PARAGRAPH = 'text_do_you_plan_to_use_the_event_notification_api_paragraph';
export const TEXT_DOMAIN = 'text_domain';
export const TEXT_DOMAIN_OR_DOMAINS = 'text_domain_or_domains';
export const TEXT_DOMAIN_TEXTAREA_PLACEHOLDER = 'text_domain_textarea_placeholder';
export const TEXT_DOMAINS_ENTERED = 'text_domains_entered';
export const TEXT_DOMAINS_INVALID_COUNT = 'text_domains_invalid_count';
export const TEXT_DONE = 'text_done';
export const TEXT_DONTSHOWAGAIN = 'text_dontshowagain';
export const TEXT_DOSCOMMAND = 'text_doscommand';
export const TEXT_DOWNLOAD = 'text_download';
export const TEXT_DOWNLOAD_DATASHEET = 'text_download_datasheet';
export const TEXT_DOWNLOAD_SOFTWARE = 'text_download_software';
export const TEXT_DOWNLOADS = 'text_downloads';
export const TEXT_DOWNLOADUSAGEREPORT = 'text_downloadusagereport';
export const TEXT_DRAFT = 'text_draft';
export const TEXT_DRAGROWS = 'text_dragrows';
export const TEXT_DRAGTOUPLOADLOGO = 'text_dragtouploadlogo';
export const TEXT_DRUGSALCOHOL = 'text_drugsalcohol';
export const TEXT_DUPICATEDOMAIN = 'text_dupicatedomain';
export const TEXT_DWELLTIME = 'text_dwelltime';
export const TEXT_DYNAMICIP = 'text_dynamicip';
export const TEXT_DYNAMICIP_DESC_1 = 'text_dynamicip_desc_1';
export const TEXT_DYNAMICIP_DESC_2 = 'text_dynamicip_desc_2';
export const TEXT_DYNAMICIP_DESC_3 = 'text_dynamicip_desc_3';
export const TEXT_DYNAMICIP_DESC_4 = 'text_dynamicip_desc_4';
export const TEXT_E_G_NOTEPAD_EXE = 'text_e_g_notepad_exe';
export const TEXT_EDIT = 'text_edit';
export const TEXT_EDIT_OVERRIDE = 'text_edit_override';
export const TEXT_EDITCHARTERROR_CHARTNAME = 'text_editcharterror_chartname';
export const TEXT_EDITCHARTERROR_CHARTTYPE = 'text_editcharterror_charttype';
export const TEXT_EDITCHARTERROR_DATAFIELD = 'text_editcharterror_datafield';
export const TEXT_EDITCHARTTITLE = 'text_editcharttitle';
export const TEXT_EDITDNSSETTINGS = 'text_editdnssettings';
export const TEXT_EDITED = 'text_edited';
export const TEXT_EDITGROUP = 'text_editgroup';
export const TEXT_EDITPOLICY = 'text_editpolicy';
export const TEXT_EDITRMM = 'text_editrmm';
export const TEXT_EDR_MDR_SERVICE = 'text_edr_mdr_service';
export const TEXT_EDRTRIALTITLE = 'text_edrtrialtitle';
export const TEXT_EDUCATIONVIDEOS = 'text_educationvideos';
export const TEXT_EMAILBODY = 'text_emailbody';
export const TEXT_EMAILCONFIRMSENT = 'text_emailconfirmsent';
export const TEXT_EMAILS = 'text_emails';
export const TEXT_EMAILTEMPLATE = 'text_emailtemplate';
export const TEXT_EMAILTITLE = 'text_emailtitle';
export const TEXT_EN = 'text_en';
export const TEXT_ENABLE = 'text_enable';
export const TEXT_ENABLED = 'text_enabled';
export const TEXT_ENABLESDNS = 'text_enablesdns';
export const TEXT_ENABLESECURECAST = 'text_enablesecurecast';
export const TEXT_ENABLING = 'text_enabling';
export const TEXT_ENABLING_TRIAL = 'text_enabling_trial';
export const TEXT_ENDDATE = 'text_enddate';
export const TEXT_ENDPOINT = 'text_endpoint';
export const TEXT_ENDPOINT_AGENT_VERSION_SPREAD = 'text_endpoint_agent_version_spread';
export const TEXT_ENDPOINT_INFECTIONS_ENCOUNTERED = 'text_endpoint_infections_encountered';
export const TEXT_ENDPOINT_INSTALLATIONS = 'text_endpoint_installations';
export const TEXT_ENDPOINT_PROTECTION = 'text_endpoint_protection';
export const TEXT_ENDPOINT_PROTECTION_CONSOLE = 'text_endpoint_protection_console';
export const TEXT_ENDPOINTDEMO_1 = 'text_endpointdemo_1';
export const TEXT_ENDPOINTDEMO_2 = 'text_endpointdemo_2';
export const TEXT_ENDPOINTDEMO_3 = 'text_endpointdemo_3';
export const TEXT_ENDPOINTDEMO_4 = 'text_endpointdemo_4';
export const TEXT_ENDPOINTDEMO_TITLE = 'text_endpointdemo_title';
export const TEXT_ENDPOINTPOLICY = 'text_endpointpolicy';
export const TEXT_ENDPOINTS = 'text_endpoints';
export const TEXT_ENDPOINTS_CURRENTLY_INFECTED = 'text_endpoints_currently_infected';
export const TEXT_ENDPOINTS_NOT_SEEN_RECENTLY = 'text_endpoints_not_seen_recently';
export const TEXT_ENDPOINTSTATUSSTATUS = 'text_endpointstatusstatus';
export const TEXT_ENFORCES_THE_FOLLOWING_CATEGORIES = 'text_enforces_the_following_categories';
export const TEXT_ENPOINT_NOWORKGROUPS = 'text_enpoint_noworkgroups';
export const TEXT_ENTER_A_32_CHARACTER_MD5 = 'text_enter_a_32_character_MD5';
export const TEXT_ENTER_NAME_IDENTIFY = 'text_enter_name_identify';
export const TEXT_ENTERCURRENTPASSWORD = 'text_entercurrentpassword';
export const TEXT_ENTERTAINMENT = 'text_entertainment';
export const TEXT_ENTERVALIDURL = 'text_entervalidurl';
export const TEXT_EP_DESC = 'text_ep_desc';
export const TEXT_EP_TITLE = 'text_ep_title';
export const TEXT_ERROR = 'text_error';
export const TEXT_ERRORMSPACCT034 = 'text_errorMSPACCT034';
export const TEXT_ERROR_CANNOTUPDATECONSOLENAME = 'text_error_cannotupdateconsolename';
export const TEXT_ERROR_CONSOLEUNIQUENAME = 'text_error_consoleuniquename';
export const TEXT_ERROR_DOMAIN_SUFFIX = 'text_error_domain_suffix';
export const TEXT_ERROR_EMAILS = 'text_error_emails';
export const TEXT_ERROR_EMAILSLIMIT = 'text_error_emailslimit';
export const TEXT_ERROR_FIELD_GREATER_THAN_ZERO = 'text_error_field_greater_than_zero';
export const TEXT_ERROR_MAXIMUM_NUMBER_OF_DOMAIN_OVERRIDES_PER_SITE_EXCEEDED = 'text_error_maximum_number_of_domain_overrides_per_site_exceeded';
export const TEXT_ERROR_MINIMUMONERADIO = 'text_error_minimumoneradio';
export const TEXT_ERROR_NAMEALREADYINUSE = 'text_error_namealreadyinuse';
export const TEXT_ERROR_PLEASERENAMEPOLICY = 'text_error_pleaserenamepolicy';
export const TEXT_ERROR_POLICYNAMEUNIQUE = 'text_error_policynameunique';
export const TEXT_ERROR_RESET = 'text_error_reset';
export const TEXT_ERROR_SITEEXPIREDMORETHAN30DAYS = 'text_error_siteexpiredmorethan30days';
export const TEXT_ERROR_URLS_EXCEEDED = 'text_error_urls_exceeded';
export const TEXT_ERROR_URLSLIMIT = 'text_error_urlslimit';
export const TEXT_ERRORBLOCKALLOWEXISTS = 'text_errorblockallowexists';
export const TEXT_ERRORCREATINGCHART = 'text_errorcreatingchart';
export const TEXT_ES = 'text_es';
export const TEXT_ETCETERA = 'text_etcetera';
export const TEXT_EUROPE = 'text_europe';
export const TEXT_EVASIONDESC = 'text_evasiondesc';
export const TEXT_EVASIONLINK = 'text_evasionlink';
export const TEXT_EVASIONNOTE = 'text_evasionnote';
export const TEXT_EVASIONSHIELD = 'text_evasionshield';
export const TEXT_EVASIONSHIELD_FCS = 'text_evasionshield_fcs';
export const TEXT_EVASIONSHIELD_FCS_STATUS = 'text_evasionshield_fcs_status';
export const TEXT_EVASIONSHIELD_SCRIPT = 'text_evasionshield_script';
export const TEXT_EVASIONSHIELDPOLICYMESSAGE = 'text_evasionshieldpolicymessage';
export const TEXT_EVASIONSHIELDSCRIPTPROTECTIONSTATUS = 'text_evasionshieldscriptprotectionstatus';
export const TEXT_EVASIONSHIELDSTATUS = 'text_evasionshieldstatus';
export const TEXT_EVASIONSHIELDTHREATS = 'text_evasionshieldthreats';
export const TEXT_EVENTTIME = 'text_eventtime';
export const TEXT_EVERY = 'text_every';
export const TEXT_EVERYFRI = 'text_everyfri';
export const TEXT_EVERYMON = 'text_everymon';
export const TEXT_EVERYSAT = 'text_everysat';
export const TEXT_EVERYSUN = 'text_everysun';
export const TEXT_EVERYTHU = 'text_everythu';
export const TEXT_EVERYTUE = 'text_everytue';
export const TEXT_EVERYWED = 'text_everywed';
export const TEXT_EXAMPLENOTEPADEXE = 'text_examplenotepadexe';
export const TEXT_EXCEEDING = 'text_exceeding';
export const TEXT_EXIT = 'text_exit';
export const TEXT_EXPIRED = 'text_expired';
export const TEXT_EXPIREDCONTENT = 'text_expiredcontent';
export const TEXT_EXPIREDSTATUS = 'text_expiredstatus';
export const TEXT_EXPIREDTITLE = 'text_expiredtitle';
export const TEXT_EXPIRES = 'text_expires';
export const TEXT_EXPIRING = 'text_expiring';
export const TEXT_EXPORT = 'text_export';
export const TEXT_EXPORT_CHARTS = 'text_export_charts';
export const TEXT_EXPORT_LAYOUTS = 'text_export_layouts';
export const TEXT_EXPORT_TITLE = 'text_export_title';
export const TEXT_EXPORTDESCRIPTION = 'text_exportdescription';
export const TEXT_EXPORTPOLICY = 'text_exportpolicy';
export const TEXT_EXPORTTOCSV = 'text_exporttocsv';
export const TEXT_FCS_TOOLTIP = 'text_fcs_tooltip';
export const TEXT_FEBRUARY = 'text_february';
export const TEXT_FIELD_REQUIRED_ERROR = 'text_field_required_error';
export const TEXT_FILE = 'text_file';
export const TEXT_FILE_MASK = 'text_file_mask';
export const TEXT_FILE_OVERRIDES = 'text_file_overrides';
export const TEXT_FILE_TOOLTIP = 'text_file_tooltip';
export const TEXT_FILEALLOWLIST = 'text_fileallowlist';
export const TEXT_FILEANDFOLDEROVERRIDES = 'text_fileandfolderoverrides';
export const TEXT_FILEBLACKLIST = 'text_fileblacklist';
export const TEXT_FILEBLOCKLIST = 'text_fileblocklist';
export const TEXT_FILEDESCRIPTION = 'text_filedescription';
export const TEXT_FILEFORMAT = 'text_fileformat';
export const TEXT_FILEHASH = 'text_filehash';
export const TEXT_FILEHASHORPATHFILE = 'text_filehashorpathfile';
export const TEXT_FILEINFOFOR = 'text_fileinfofor';
export const TEXT_FILEINFORMATION = 'text_fileinformation';
export const TEXT_FILELESSSCRIPT = 'text_filelessscript';
export const TEXT_FILEMASK_INFO = 'text_filemask_info';
export const TEXT_FILENAME = 'text_filename';
export const TEXT_FILEPATH = 'text_filepath';
export const TEXT_FILEPATHCOMBINATIONEXISTS = 'text_filepathcombinationexists';
export const TEXT_FILES = 'text_files';
export const TEXT_FILESIZE = 'text_filesize';
export const TEXT_FILESIZETOOLARGE = 'text_filesizetoolarge';
export const TEXT_FILETHREATDETECTIONS = 'text_filethreatdetections';
export const TEXT_FILETHREATREPORTS = 'text_filethreatreports';
export const TEXT_FILETYPEINVALID = 'text_filetypeinvalid';
export const TEXT_FILEVERSION = 'text_fileversion';
export const TEXT_FILEWHITELIST = 'text_filewhitelist';
export const TEXT_FILTER = 'text_filter';
export const TEXT_FILTER_BY_GROUP = 'text_filter_by_group';
export const TEXT_FILTER_SITES = 'text_filter_sites';
export const TEXT_FILTERBYNAME = 'text_filterbyname';
export const TEXT_FILTERBYNAMETAGS = 'text_filterbynametags';
export const TEXT_FILTERS = 'text_filters';
export const TEXT_FINISH = 'text_finish';
export const TEXT_FIREWALL = 'text_firewall';
export const TEXT_FIREWALLSTATUS = 'text_firewallstatus';
export const TEXT_FIRSTPAGE = 'text_firstpage';
export const TEXT_FIRSTSEEN = 'text_firstseen';
export const TEXT_FIX_NOW = 'text_fix_now';
export const TEXT_FOCUS_BETA_HELP_DESC = 'text_focus_beta_help_desc';
export const TEXT_FOCUS_BETA_HELP_TITLE = 'text_focus_beta_help_title';
export const TEXT_FOLDER = 'text_folder';
export const TEXT_FOLDER_FILE = 'text_folder_file';
export const TEXT_FOLDER_TOOLTIP = 'text_folder_tooltip';
export const TEXT_FORMFACTOR = 'text_formfactor';
export const TEXT_FORMTIP_EMAILORPHONE = 'text_formtip_emailorphone';
export const TEXT_FR = 'text_fr';
export const TEXT_FREE_TRIAL = 'text_free_trial';
export const TEXT_FREE_TRIAL_DNS_DESCRIPTION = 'text_free_trial_dns_description';
export const TEXT_FREE_TRIAL_DNS_LIST_ITEM_ONE = 'text_free_trial_dns_list_item_one';
export const TEXT_FREE_TRIAL_DNS_LIST_ITEM_TWO = 'text_free_trial_dns_list_item_two';
export const TEXT_FREQUENCY = 'text_frequency';
export const TEXT_FRI = 'text_fri';
export const TEXT_FROM = 'text_from';
export const TEXT_FULL_SCAN = 'text_full_scan';
export const TEXT_GENERICDNSDOMAINERROR = 'text_genericdnsdomainerror';
export const TEXT_GENERICDNSIPERROR = 'text_genericdnsiperror';
export const TEXT_GENERICERROR = 'text_genericerror';
export const TEXT_GENERICERROR2 = 'text_genericerror2';
export const TEXT_GLOBAL = 'text_global';
export const TEXT_GLOBAL_AND_SITE = 'text_global_and_site';
export const TEXT_GLOBAL_OVERRIDE = 'text_global_override';
export const TEXT_GLOBALALERTS = 'text_globalalerts';
export const TEXT_GLOBALORSITEOVERRIDE = 'text_globalorsiteoverride';
export const TEXT_GLOBALOVERRIDES = 'text_globaloverrides';
export const TEXT_GLOBALPOLICIES = 'text_globalpolicies';
export const TEXT_GLOBALSETTINGS = 'text_globalsettings';
export const TEXT_GLOBALSETTINGSNOTREVERSIBLE = 'text_globalsettingsnotreversible';
export const TEXT_GO_TO_USAGE_PORTAL = 'text_go_to_usage_portal';
export const TEXT_GOOD = 'text_good';
export const TEXT_GOTOADMINS = 'text_gotoadmins';
export const TEXT_GOTOENDPOINTCONSOLE = 'text_gotoendpointconsole';
export const TEXT_GOTOENDPOINTPROTECTION = 'text_gotoendpointprotection';
export const TEXT_GOTOMOBILECONSOLE = 'text_gotomobileconsole';
export const TEXT_GOTOSECURECAST = 'text_gotosecurecast';
export const TEXT_GOTOSITES = 'text_gotosites';
export const TEXT_GROUP = 'text_group';
export const TEXT_GROUP_DESCRIPTION = 'text_group_description';
export const TEXT_GROUP_NAME = 'text_group_name';
export const TEXT_GROUP_NAME_ALREADY_IN_USE = 'text_group_name_already_in_use';
export const TEXT_GROUP_NAME_ALREADY_IN_USE_PLEASE_PROVIDE_ALTERNATIVE = 'text_group_name_already_in_use_please_provide_alternative';
export const TEXT_GS_OVERRIDESDISABLED = 'text_gs_overridesdisabled';
export const TEXT_GS_OVERRIDESENABLED = 'text_gs_overridesenabled';
export const TEXT_GS_POLICIESDISABLED = 'text_gs_policiesdisabled';
export const TEXT_GS_POLICIESENABLED = 'text_gs_policiesenabled';
export const TEXT_GS_SECUREDNSDISABLED = 'text_gs_securednsdisabled';
export const TEXT_GS_SECUREDNSENABLED = 'text_gs_securednsenabled';
export const TEXT_GSM_DESC = 'text_gsm_desc';
export const TEXT_GSM_INTRO_1 = 'text_gsm_intro_1';
export const TEXT_GSM_INTRO_2 = 'text_gsm_intro_2';
export const TEXT_GSM_INTRO_3 = 'text_gsm_intro_3';
export const TEXT_GSM_INTRO_4 = 'text_gsm_intro_4';
export const TEXT_GSM_INTRO_5 = 'text_gsm_intro_5';
export const TEXT_GSM_TITLE = 'text_gsm_title';
export const TEXT_GSMADMINS = 'text_gsmadmins';
export const TEXT_GSMCONSOLE = 'text_gsmconsole';
export const TEXT_GSMCONSOLERELEASE = 'text_gsmconsolerelease';
export const TEXT_GSMCONSOLES = 'text_gsmconsoles';
export const TEXT_GSMGLOBALWEBOVERRIDE = 'text_gsmglobalweboverride';
export const TEXT_GSMGLOBALWEBOVERRIDES = 'text_gsmglobalweboverrides';
export const TEXT_GSMINTRO_01 = 'text_gsmintro_01';
export const TEXT_GSMINTRO_02 = 'text_gsmintro_02';
export const TEXT_GSMINTRO_03 = 'text_gsmintro_03';
export const TEXT_GSMINTRO_04 = 'text_gsmintro_04';
export const TEXT_GSMINTRO_05 = 'text_gsmintro_05';
export const TEXT_HANDLETIMEOUT1 = 'text_handletimeout1';
export const TEXT_HANDLETIMEOUT2 = 'text_handletimeout2';
export const TEXT_HELP = 'text_help';
export const TEXT_HELPDOC_REPORTS = 'text_helpdoc_reports';
export const TEXT_HELPDOCUMENTATION = 'text_helpdocumentation';
export const TEXT_HELPMECHOOSE = 'text_helpmechoose';
export const TEXT_HEURISTICCLASSIFICATION = 'text_heuristicclassification';
export const TEXT_HEURISTICS = 'text_heuristics';
export const TEXT_HEURS_0 = 'text_heurs_0';
export const TEXT_HEURS_1 = 'text_heurs_1';
export const TEXT_HEURS_2 = 'text_heurs_2';
export const TEXT_HEURS_3 = 'text_heurs_3';
export const TEXT_HIDDEN = 'text_hidden';
export const TEXT_HIDE = 'text_hide';
export const TEXT_HIDEDATA_12M = 'text_hidedata_12m';
export const TEXT_HIDEDATA_1M = 'text_hidedata_1m';
export const TEXT_HIDEDATA_2M = 'text_hidedata_2m';
export const TEXT_HIDEDATA_3M = 'text_hidedata_3m';
export const TEXT_HIDEDATA_6M = 'text_hidedata_6m';
export const TEXT_HIDEDATA_ALL = 'text_hidedata_all';
export const TEXT_HIDEDATA_INHERIT = 'text_hidedata_inherit';
export const TEXT_HIDEDATADESC = 'text_hidedatadesc';
export const TEXT_HIDEDATADESC2 = 'text_hidedatadesc2';
export const TEXT_HIDEDATALABEL = 'text_hidedatalabel';
export const TEXT_HIDEDATASAVED = 'text_hidedatasaved';
export const TEXT_HIDEDATASAVEDDESC = 'text_hidedatasaveddesc';
export const TEXT_HIDEDATASETTING = 'text_hidedatasetting';
export const TEXT_HIDEENDPOINTDATALOG = 'text_hideendpointdatalog';
export const TEXT_HIGH = 'text_high';
export const TEXT_HIGHRISK = 'text_highrisk';
export const TEXT_HISTORY = 'text_history';
export const TEXT_HISTORYDISCLAIMER = 'text_historydisclaimer';
export const TEXT_HOSTNAME = 'text_hostname';
export const TEXT_HOUR = 'text_hour';
export const TEXT_HOURS = 'text_hours';
export const TEXT_HOURSAGO = 'text_hoursago';
export const TEXT_HOW_DO_YOU_PLAN_TO_USE_UNITY_API = 'text_how_do_you_plan_to_use_unity_api';
export const TEXT_HOWTOUSEDATAINPUTS = 'text_howtousedatainputs';
export const TEXT_HOWTOUSEDATAINPUTSDESC = 'text_howtousedatainputsdesc';
export const TEXT_HOWTOUSEDYNAMICIP = 'text_howtousedynamicip';
export const TEXT_HTTPPROXY = 'text_httpproxy';
export const TEXT_HYBRID = 'text_hybrid';
export const TEXT_IDENTITYSHIELD = 'text_identityshield';
export const TEXT_IDENTITYSHIELDSTATUS = 'text_identityshieldstatus';
export const TEXT_IMITATEGOODSCAN = 'text_imitategoodscan';
export const TEXT_IMPORT = 'text_import';
export const TEXT_IMPORTANT = 'text_important';
export const TEXT_IMPORTANTSERVERPOLICYUPDATE = 'text_importantserverpolicyupdate';
export const TEXT_IMPORTDESC1 = 'text_importdesc1';
export const TEXT_IMPORTDESC2 = 'text_importdesc2';
export const TEXT_IMPORTDESC3 = 'text_importdesc3';
export const TEXT_IMPORTEXPORTHELP = 'text_importexporthelp';
export const TEXT_IMPORTHELP1 = 'text_importhelp1';
export const TEXT_IMPORTHELP10 = 'text_importhelp10';
export const TEXT_IMPORTHELP11 = 'text_importhelp11';
export const TEXT_IMPORTHELP12 = 'text_importhelp12';
export const TEXT_IMPORTHELP2 = 'text_importhelp2';
export const TEXT_IMPORTHELP3 = 'text_importhelp3';
export const TEXT_IMPORTHELP4 = 'text_importhelp4';
export const TEXT_IMPORTHELP5 = 'text_importhelp5';
export const TEXT_IMPORTHELP6 = 'text_importhelp6';
export const TEXT_IMPORTHELP7 = 'text_importhelp7';
export const TEXT_IMPORTHELP8 = 'text_importhelp8';
export const TEXT_IMPORTHELP9 = 'text_importhelp9';
export const TEXT_IMPORTING = 'text_importing';
export const TEXT_IMPORTOVERRIDES = 'text_importoverrides';
export const TEXT_IMPORTOVERRIDES_1 = 'text_importoverrides_1';
export const TEXT_IMPORTOVERRIDES_2 = 'text_importoverrides_2';
export const TEXT_IMPORTOVERRIDES_3 = 'text_importoverrides_3';
export const TEXT_IMPORTPOLICY = 'text_importpolicy';
export const TEXT_IMPORTTYPE = 'text_importtype';
export const TEXT_INACTIVE = 'text_inactive';
export const TEXT_INACTIVE_AGENT = 'text_inactive_agent';
export const TEXT_INCLUDE_DEACTIVATED_AND_HIDDEN = 'text_include_deactivated_and_hidden';
export const TEXT_INCLUDE_SUB_FOLDERS = 'text_include_sub_folders';
export const TEXT_INCLUDE_SUBFOLDERS = 'text_include_subfolders';
export const TEXT_INCLUDEGLOBALALERTS = 'text_includeglobalalerts';
export const TEXT_INCLUDEGLOBALOVERRIDES = 'text_includeglobaloverrides';
export const TEXT_INCLUDEGLOBALPOLICIES = 'text_includeglobalpolicies';
export const TEXT_INCLUDEPOLICYOVERRIDES = 'text_includepolicyoverrides';
export const TEXT_INCLUDESECUREDNS = 'text_includesecuredns';
export const TEXT_INCORRECTTRANSFERCODE = 'text_incorrecttransfercode';
export const TEXT_INFECTED = 'text_infected';
export const TEXT_INFECTION_LIST = 'text_infection_list';
export const TEXT_INFECTIONALERT = 'text_infectionalert';
export const TEXT_INFECTIONS = 'text_infections';
export const TEXT_INFECTIONSENCOUNTERED = 'text_infectionsencountered';
export const TEXT_INFECTIONSUMMARY = 'text_infectionsummary';
export const TEXT_INFONEWSSEARCH = 'text_infonewssearch';
export const TEXT_INFORMATION = 'text_information';
export const TEXT_INFORMATIONTECHNOLOGY = 'text_informationtechnology';
export const TEXT_INFRARED = 'text_infrared';
export const TEXT_INFRAREDSTATUS = 'text_infraredstatus';
export const TEXT_INHERIT = 'text_inherit';
export const TEXT_INHERITPOLICYSETTING = 'text_inheritpolicysetting';
export const TEXT_INITIATECLEANUPDEVICE = 'text_initiatecleanupdevice';
export const TEXT_INSERT = 'text_insert';
export const TEXT_INSTALL = 'text_install';
export const TEXT_INSTALL_DNS_AGENT = 'text_install_dns_agent';
export const TEXT_INSTALLALERT = 'text_installalert';
export const TEXT_INSTALLATION_DESC = 'text_installation_desc';
export const TEXT_INSTALLATION_ENTERKEYCODE = 'text_installation_enterkeycode';
export const TEXT_INSTALLATION_TITLE = 'text_installation_title';
export const TEXT_INSTALLATIONDEMO = 'text_installationdemo';
export const TEXT_INSTALLATIONS = 'text_installations';
export const TEXT_INSTALLATIONSTATUS = 'text_installationstatus';
export const TEXT_INSTALLED = 'text_installed';
export const TEXT_INSTALLED_AGENT = 'text_installed_agent';
export const TEXT_INSTALLSUMMARY = 'text_installsummary';
export const TEXT_INTEGRATION_WITH_SIEM_PROVIDER = 'text_integration_with_siem_provider';
export const TEXT_INTEGRATION_WITH_SNOWFLAKES = 'text_integration_with_snowflakes';
export const TEXT_INTERACTIVEDEMO = 'text_interactivedemo';
export const TEXT_INTERACTIVEDEMOINTROP1 = 'text_interactivedemointrop1';
export const TEXT_INTERACTIVEDEMOINTROP2 = 'text_interactivedemointrop2';
export const TEXT_INTERACTIVEDEMOINTROP3 = 'text_interactivedemointrop3';
export const TEXT_INTERACTIVEDEMOTITLE = 'text_interactivedemotitle';
export const TEXT_INTERNALEXTERNALDESC = 'text_internalexternaldesc';
export const TEXT_INTERNET = 'text_internet';
export const TEXT_INTERNETEXPLORER = 'text_internetexplorer';
export const TEXT_INTROCONTENTADMIN = 'text_introcontentadmin';
export const TEXT_INTROCONTENTVIEW = 'text_introcontentview';
export const TEXT_INTROGUIDANCE = 'text_introguidance';
export const TEXT_INTROH1 = 'text_introh1';
export const TEXT_INVALID = 'text_invalid';
export const TEXT_INVALID_IPV4_OR_IPV6_FORMAT = 'text_invalid_ipv4_or_ipv6_format';
export const TEXT_INVALIDDOMAIN = 'text_invaliddomain';
export const TEXT_INVALIDEMAIL = 'text_invalidemail';
export const TEXT_INVALIDEMAILS = 'text_invalidemails';
export const TEXT_INVALIDIPADDRESS = 'text_invalidipaddress';
export const TEXT_INVALIDURLS = 'text_invalidurls';
export const TEXT_IPFROM_EXCEEDED_TOTAL_256_PER_SITE = 'text_ipFrom_exceeded_total_256_per_site';
export const TEXT_IPFROM_GREATER_THAN_IPTO = 'text_ipFrom_greater_than_ipTo';
export const TEXT_IP_CONFLICT_WITH_ANOTHERACCOUNT = 'text_ip_conflict_with_anotherAccount';
export const TEXT_IP_CONFLICT_WITH_ANOTHERSITEANOTHERPARTNER = 'text_ip_conflict_with_anotherSiteAnotherPartner';
export const TEXT_IP_CONFLICT_WITH_ANOTHERSITESAMEPARTNER = 'text_ip_conflict_with_anotherSiteSamePartner';
export const TEXT_IP_CONFLICT_WITH_SAMEACCOUNT = 'text_ip_conflict_with_sameAccount';
export const TEXT_IP_CONFLICT_WITH_SAMESITESAMEPARTNER = 'text_ip_conflict_with_sameSiteSamePartner';
export const TEXT_IP_COUNT = 'text_ip_count';
export const TEXT_IPADDRESS = 'text_ipaddress';
export const TEXT_IPREGIONPLACEHOLDER = 'text_ipregionplaceholder';
export const TEXT_IRREVERSIBLE = 'text_irreversible';
export const TEXT_IT = 'text_it';
export const TEXT_JA = 'text_ja';
export const TEXT_JANUARY = 'text_january';
export const TEXT_JULY = 'text_july';
export const TEXT_JUNE = 'text_june';
export const TEXT_JUSTNOW = 'text_justnow';
export const TEXT_KEYCODE = 'text_keycode';
export const TEXT_KEYCODEEXPIRED = 'text_keycodeexpired';
export const TEXT_KEYCODEINVALID = 'text_keycodeinvalid';
export const TEXT_KEYCODENOHYPHENS = 'text_keycodenohyphens';
export const TEXT_KEYCODENOTFORINSTALL = 'text_keycodenotforinstall';
export const TEXT_KEYCODETYPE = 'text_keycodetype';
export const TEXT_KEYCODETYPEFULL = 'text_keycodetypefull';
export const TEXT_KEYCODETYPETRIAL = 'text_keycodetypetrial';
export const TEXT_KO = 'text_ko';
export const TEXT_LANGUAGE = 'text_language';
export const TEXT_LANGUAGES = 'text_languages';
export const TEXT_LAST24HOURS = 'text_last24hours';
export const TEXT_LAST30DAYS = 'text_last30days';
export const TEXT_LAST60DAYS = 'text_last60days';
export const TEXT_LAST90DAYS = 'text_last90days';
export const TEXT_LAST_7_DAYS = 'text_last_7_days';
export const TEXT_LAST_MODIFIED = 'text_last_modified';
export const TEXT_LAST_MONTH = 'text_last_month';
export const TEXT_LAST_UPDATED = 'text_last_updated';
export const TEXT_LASTCHANGE = 'text_lastchange';
export const TEXT_LASTDAYOFMONTH = 'text_lastdayofmonth';
export const TEXT_LASTINFECTED = 'text_lastinfected';
export const TEXT_LASTPAGE = 'text_lastpage';
export const TEXT_LASTSEEN = 'text_lastseen';
export const TEXT_LASTUPDATEDBY = 'text_lastupdatedby';
export const TEXT_LAYOUT = 'text_layout';
export const TEXT_LEARNMORE = 'text_learnmore';
export const TEXT_LEAVEUNCHANGED = 'text_leaveunchanged';
export const TEXT_LEGACY_RECOMMENDED_DEFAULTS = 'text_legacy_recommended_defaults';
export const TEXT_LEGACY_RECOMMENDED_DNS_ENABLED = 'text_legacy_recommended_dns_enabled';
export const TEXT_LEGACY_RECOMMENDED_SERVER_DEFAULTS = 'text_legacy_recommended_server_defaults';
export const TEXT_LESSTHAN50 = 'text_lessthan50';
export const TEXT_LIFESTYLE = 'text_lifestyle';
export const TEXT_LIMITEDADMIN = 'text_limitedadmin';
export const TEXT_LIMITONEMEGABYTE = 'text_limitonemegabyte';
export const TEXT_LIMITREACHED = 'text_limitreached';
export const TEXT_LIMITREACHEDDESC = 'text_limitreacheddesc';
export const TEXT_LINE = 'text_line';
export const TEXT_LISTTOBEDELETED = 'text_listtobedeleted';
export const TEXT_LIVE = 'text_live';
export const TEXT_LOAD_ERROR_TEXT = 'text_load_error_text';
export const TEXT_LOAD_ERROR_TEXT_CHECKSETTINGSORRELOAD = 'text_load_error_text_checksettingsorreload';
export const TEXT_LOAD_ERROR_TITLE = 'text_load_error_title';
export const TEXT_LOADING = 'text_loading';
export const TEXT_LOADINGADMINS = 'text_loadingadmins';
export const TEXT_LOADINGGROUPMANAGEMENT = 'text_loadinggroupmanagement';
export const TEXT_LOADINGREPORTS = 'text_loadingreports';
export const TEXT_LOADINGTEMPLATE = 'text_loadingtemplate';
export const TEXT_LOCAL = 'text_local';
export const TEXT_LOW = 'text_low';
export const TEXT_LOWREPUTATION = 'text_lowreputation';
export const TEXT_MAC14 = 'text_mac14';
export const TEXT_MAC15 = 'text_mac15';
export const TEXT_MACADDRESS = 'text_macaddress';
export const TEXT_MACOS = 'text_macos';
export const TEXT_MALICIOUS_ICON_WARNING = 'text_malicious_icon_warning';
export const TEXT_MALICIOUSDETECTION_DESCRIPTION = 'text_maliciousdetection_description';
export const TEXT_MALWAREGROUP = 'text_malwaregroup';
export const TEXT_MANAGE = 'text_manage';
export const TEXT_MANAGED = 'text_managed';
export const TEXT_MANAGED_SERVICE_PROVIDER = 'text_managed_service_provider';
export const TEXT_MANAGEDBYPOLICY = 'text_managedbypolicy';
export const TEXT_MANAGELEARN = 'text_managelearn';
export const TEXT_MANAGETXT1 = 'text_managetxt1';
export const TEXT_MANAGETXT2 = 'text_managetxt2';
export const TEXT_MANAGETXT3 = 'text_managetxt3';
export const TEXT_MANUAL = 'text_manual';
export const TEXT_MARCH = 'text_march';
export const TEXT_MATCH = 'text_match';
export const TEXT_MATCHES = 'text_matches';
export const TEXT_MATRIX_1 = 'text_matrix_1';
export const TEXT_MATRIX_10 = 'text_matrix_10';
export const TEXT_MATRIX_11 = 'text_matrix_11';
export const TEXT_MATRIX_12 = 'text_matrix_12';
export const TEXT_MATRIX_13 = 'text_matrix_13';
export const TEXT_MATRIX_2 = 'text_matrix_2';
export const TEXT_MATRIX_3 = 'text_matrix_3';
export const TEXT_MATRIX_4 = 'text_matrix_4';
export const TEXT_MATRIX_5 = 'text_matrix_5';
export const TEXT_MATRIX_6 = 'text_matrix_6';
export const TEXT_MATRIX_7 = 'text_matrix_7';
export const TEXT_MATRIX_8 = 'text_matrix_8';
export const TEXT_MATRIX_9 = 'text_matrix_9';
export const TEXT_MATUREGAMBLINGQUESTIONABLE = 'text_maturegamblingquestionable';
export const TEXT_MAXDOMAINROWS = 'text_maxdomainrows';
export const TEXT_MAXIMUM = 'text_maximum';
export const TEXT_MAXIMUMCHARACTERSALLOWED = 'text_maximumcharactersallowed';
export const TEXT_MAY = 'text_may';
export const TEXT_MAYBE_LATER = 'text_maybe_later';
export const TEXT_MD5 = 'text_md5';
export const TEXT_MD5_OR_PATH = 'text_md5_or_path';
export const TEXT_MDRTRIALTITLE = 'text_mdrtrialtitle';
export const TEXT_MEDIUM = 'text_medium';
export const TEXT_MIN = 'text_min';
export const TEXT_MINIMUM = 'text_minimum';
export const TEXT_MINS = 'text_mins';
export const TEXT_MINSAGO = 'text_minsago';
export const TEXT_MIXEDBAG = 'text_mixedbag';
export const TEXT_MOBILE_PROTECTION_CONSOLE = 'text_mobile_protection_console';
export const TEXT_MOBILEENDOFLIFE = 'text_mobileendoflife';
export const TEXT_MOBILEPROTECTION = 'text_mobileprotection';
export const TEXT_MON = 'text_mon';
export const TEXT_MONTHLY = 'text_monthly';
export const TEXT_MONTHS = 'text_months';
export const TEXT_MORE_INFO = 'text_more_info';
export const TEXT_MORETHAN = 'text_morethan';
export const TEXT_MORETHAN500 = 'text_morethan500';
export const TEXT_MORETHANTENEMAILSADDED = 'text_morethantenemailsadded';
export const TEXT_MOVE = 'text_move';
export const TEXT_MOVEGROUP = 'text_movegroup';
export const TEXT_MOVETOTHEFOLLOWINGGROUP = 'text_movetothefollowinggroup';
export const TEXT_MUSTHAVEONECHART = 'text_musthaveonechart';
export const TEXT_MUSTHAVEUNIQUENAME = 'text_musthaveuniquename';
export const TEXT_MUSTLAYOUT = 'text_mustlayout';
export const TEXT_MUSTSELECTCHARTSTOEXPORT = 'text_mustselectchartstoexport';
export const TEXT_MUSTSELECTONESITE = 'text_mustselectonesite';
export const TEXT_MUSTSELECTSITESTOFILTER = 'text_mustselectsitestofilter';
export const TEXT_MYBILLING = 'text_mybilling';
export const TEXT_MYBILLING_DESC = 'text_mybilling_desc';
export const TEXT_MYUSAGE = 'text_myusage';
export const TEXT_MYUSAGE_DESC = 'text_myusage_desc';
export const TEXT_NAME = 'text_name';
export const TEXT_NAME_DESCRIPTION = 'text_name_description';
export const TEXT_NAME_REQUIRE_TO_BE_UNIQUE_PER_SITE = 'text_name_require_to_be_unique_per_site';
export const TEXT_NAMEDESCREGEX = 'text_namedescregex';
export const TEXT_NAMEMATCHES = 'text_namematches';
export const TEXT_NEEDATTENTION = 'text_needattention';
export const TEXT_NEEDEDATTENTION = 'text_neededattention';
export const TEXT_NEEDSATTENTION = 'text_needsattention';
export const TEXT_NETWORK = 'text_network';
export const TEXT_NETWORKLOCATION = 'text_networklocation';
export const TEXT_NETWORKSETTINGS = 'text_networksettings';
export const TEXT_NEW = 'text_new';
export const TEXT_NEW_BLACKLIST_ENTRY = 'text_new_blacklist_entry';
export const TEXT_NEW_POLICY = 'text_new_policy';
export const TEXT_NEW_REPORTS = 'text_new_reports';
export const TEXT_NEW_WHITELIST_ENTRY = 'text_new_whitelist_entry';
export const TEXT_NEWALERT = 'text_newalert';
export const TEXT_NEWER_CHANGES_EXIST_TIME = 'text_newer_changes_exist_time';
export const TEXT_NEWPOLICYDESCRIPTION = 'text_newpolicydescription';
export const TEXT_NEWPOLICYNAME = 'text_newpolicyname';
export const TEXT_NEWREPORTNAME = 'text_newreportname';
export const TEXT_NEWSITESLAYOUT = 'text_newsiteslayout';
export const TEXT_NEWTEMPLATENAME = 'text_newtemplatename';
export const TEXT_NEXT = 'text_next';
export const TEXT_NEXTPAGE = 'text_nextpage';
export const TEXT_NL = 'text_nl';
export const TEXT_NO = 'text_no';
export const TEXT_NOTHANKS = 'text_noThanks';
export const TEXT_NO_ANGLE_BRACKETS = 'text_no_angle_brackets';
export const TEXT_NO_ENDPOINTS_CURRENTLY_INFECTED = 'text_no_endpoints_currently_infected';
export const TEXT_NO_RECENT_DATA = 'text_no_recent_data';
export const TEXT_NOADDITIONALADMINSEXIST = 'text_noadditionaladminsexist';
export const TEXT_NOALERTS = 'text_noalerts';
export const TEXT_NOBLACKLISTENTRIES = 'text_noblacklistentries';
export const TEXT_NOCHANGESALLOWED = 'text_nochangesallowed';
export const TEXT_NOCHARTSDESC = 'text_nochartsdesc';
export const TEXT_NOCHARTSDISPLAYINGDATA = 'text_nochartsdisplayingdata';
export const TEXT_NOCHARTSTITLE = 'text_nochartstitle';
export const TEXT_NODATA = 'text_nodata';
export const TEXT_NODATA2 = 'text_nodata2';
export const TEXT_NODEFAULTPOLICY = 'text_nodefaultpolicy';
export const TEXT_NODEVICEINFO_DESC = 'text_nodeviceinfo_desc';
export const TEXT_NODEVICESELECTED = 'text_nodeviceselected';
export const TEXT_NODISTRIBUTIONLISTS = 'text_nodistributionlists';
export const TEXT_NODNSCHANGESALLOWED = 'text_nodnschangesallowed';
export const TEXT_NODOMAINROWS = 'text_nodomainrows';
export const TEXT_NODYNAMICIPSELECTED = 'text_nodynamicipselected';
export const TEXT_NOGLOBALOVERRIDES = 'text_noglobaloverrides';
export const TEXT_NOINFECTIONSENCOUNTERED = 'text_noinfectionsencountered';
export const TEXT_NOINFORMATIONFOUND = 'text_noinformationfound';
export const TEXT_NOIPROWS = 'text_noiprows';
export const TEXT_NOLANGUAGESSELECTED = 'text_nolanguagesselected';
export const TEXT_NOMATCHES = 'text_nomatches';
export const TEXT_NOMINATED_DEVICE_CONFLICT_WITH_ANOTHERSITE = 'text_nominated_device_conflict_with_anotherSite';
export const TEXT_NOMINATED_DEVICE_CONFLICT_WITH_SAMESITE = 'text_nominated_device_conflict_with_sameSite';
export const TEXT_NONDATAFOUNDFORSELECTEDITEM = 'text_nondatafoundforselecteditem';
export const TEXT_NONESPECIFIED = 'text_nonespecified';
export const TEXT_NOPERMISSIONSSELECTED = 'text_nopermissionsselected';
export const TEXT_NOPOLICIESTOIMPORT = 'text_nopoliciestoimport';
export const TEXT_NOPOLICY_INHERIT = 'text_nopolicy_inherit';
export const TEXT_NOPROXY = 'text_noproxy';
export const TEXT_NOREPORTS = 'text_noreports';
export const TEXT_NORESULTS = 'text_noresults';
export const TEXT_NORMAL = 'text_normal';
export const TEXT_NOSITEADMINS = 'text_nositeadmins';
export const TEXT_NOSITESCREATED1 = 'text_nositescreated1';
export const TEXT_NOSITESCREATED_LA1 = 'text_nositescreated_la1';
export const TEXT_NOSITESCREATED_SA1 = 'text_nositescreated_sa1';
export const TEXT_NOSITESCREATED_SA2 = 'text_nositescreated_sa2';
export const TEXT_NOSITESEXIST = 'text_nositesexist';
export const TEXT_NOSITESONREPORT = 'text_nositesonreport';
export const TEXT_NOSITESSELECTED = 'text_nositesselected';
export const TEXT_NOSITESSELECTEDFORTHISREPORT = 'text_nositesselectedforthisreport';
export const TEXT_NOSITESTOIMPORTFROM = 'text_nositestoimportfrom';
export const TEXT_NOSITESUSINGTHISPOLICY = 'text_nositesusingthispolicy';
export const TEXT_NOTAGS = 'text_notags';
export const TEXT_NOTAGSFORTHISSITE = 'text_notagsforthissite';
export const TEXT_NOTALLCOMPLETE = 'text_notallcomplete';
export const TEXT_NOTALLCOMPLETED = 'text_notallcompleted';
export const TEXT_NOTAPPLICABLE = 'text_notapplicable';
export const TEXT_NOTAVAILABLEYET = 'text_notavailableyet';
export const TEXT_NOTEMPLATES = 'text_notemplates';
export const TEXT_NOTEMPLATESSELECTED = 'text_notemplatesselected';
export const TEXT_NOTIFICATION_API = 'text_notification_api';
export const TEXT_NOTIFICATION_API_TOOLTIP = 'text_notification_api_tooltip';
export const TEXT_NOTIFICATIONS = 'text_notifications';
export const TEXT_NOTSEENRECENTLY = 'text_notseenrecently';
export const TEXT_NOTSET = 'text_notset';
export const TEXT_NOVEMBER = 'text_november';
export const TEXT_NOW = 'text_now';
export const TEXT_NOWHITELISTENTRIES = 'text_nowhitelistentries';
export const TEXT_NUMBER_OF_BLOCKED_URLS = 'text_number_of_blocked_urls';
export const TEXT_NUMBER_OF_DEVICES = 'text_number_of_devices';
export const TEXT_NUMBERENDPOINTSENCOUNTERING = 'text_numberendpointsencountering';
export const TEXT_NUMBERENDPOINTSINSTALLED = 'text_numberendpointsinstalled';
export const TEXT_NUMBERSELECTED = 'text_numberselected';
export const TEXT_OCTOBER = 'text_october';
export const TEXT_OF = 'text_of';
export const TEXT_OFEACHQUARTER = 'text_ofeachquarter';
export const TEXT_OFF = 'text_off';
export const TEXT_OFFLINE = 'text_offline';
export const TEXT_OFFLINESHIELD = 'text_offlineshield';
export const TEXT_OFFLINESHIELDSTATUS = 'text_offlineshieldstatus';
export const TEXT_OK = 'text_ok';
export const TEXT_OKAY = 'text_okay';
export const TEXT_ON = 'text_on';
export const TEXT_ONBOOTUP = 'text_onbootup';
export const TEXT_ONDEMAND = 'text_ondemand';
export const TEXT_ONDEMANDDESCRIPTION = 'text_ondemanddescription';
export const TEXT_ONEDEVICEMUSTBESELECTED = 'text_onedevicemustbeselected';
export const TEXT_ONEREPORTPERSITE = 'text_onereportpersite';
export const TEXT_ONESINGLEREPORT = 'text_onesinglereport';
export const TEXT_OPERATINGSYSFIREWALLSTATUS = 'text_operatingsysfirewallstatus';
export const TEXT_OPERATINGSYSTEM = 'text_operatingsystem';
export const TEXT_OPERATINGSYSTEMLANGUAGE = 'text_operatingsystemlanguage';
export const TEXT_OPERATINGSYSTEMPLATFORM = 'text_operatingsystemplatform';
export const TEXT_OPTINBETA = 'text_optInBeta';
export const TEXT_OPTOUTBETA = 'text_optOutBeta';
export const TEXT_OPTIONAL = 'text_optional';
export const TEXT_OTHER = 'text_other';
export const TEXT_OTHER_USE_OR_ENHANCEMENT_PLEASE_SPECIFY = 'text_other_use_or_enhancement_please_specify';
export const TEXT_OVER = 'text_over';
export const TEXT_OVERRIDE = 'text_override';
export const TEXT_OVERRIDE_DETAILS_AND_SCOPE = 'text_override_details_and_scope';
export const TEXT_OVERRIDE_TYPE = 'text_override_type';
export const TEXT_OVERRIDEDETAILS = 'text_overridedetails';
export const TEXT_OVERRIDEDETERMINATION = 'text_overridedetermination';
export const TEXT_OVERRIDES = 'text_overrides';
export const TEXT_OVERRIDES_SHOWN = 'text_overrides_shown';
export const TEXT_OVERRIDESUPPORTTEXT = 'text_overridesupporttext';
export const TEXT_OVERVIEW = 'text_overview';
export const TEXT_OVERWRITEEXISTINGOVERRIDES = 'text_overwriteexistingoverrides';
export const TEXT_PAGE = 'text_page';
export const TEXT_PAIDSEATS = 'text_paidseats';
export const TEXT_PARAMETERS = 'text_parameters';
export const TEXT_PARENTKEYCODE = 'text_parentkeycode';
export const TEXT_PARENTTRIALINFOCLICKHERE = 'text_parenttrialinfoclickhere';
export const TEXT_PATH_FOLDER_MASK = 'text_path_folder_mask';
export const TEXT_PATHFILE = 'text_pathfile';
export const TEXT_PATHMASK = 'text_pathmask';
export const TEXT_PATHMASK_INFO = 'text_pathmask_info';
export const TEXT_PATHNAME = 'text_pathname';
export const TEXT_PAYINVOICES = 'text_payinvoices';
export const TEXT_PENDING = 'text_pending';
export const TEXT_PENDINGDELETE = 'text_pendingdelete';
export const TEXT_PERFORMING_SETUP = 'text_performing_setup';
export const TEXT_PERIOD = 'text_period';
export const TEXT_PERMISSIONLEVEL = 'text_permissionlevel';
export const TEXT_PERMISSIONS = 'text_permissions';
export const TEXT_PERMISSIONSDESC1 = 'text_permissionsdesc1';
export const TEXT_PERMISSIONSDESC2 = 'text_permissionsdesc2';
export const TEXT_PERMISSIONSMATRIX = 'text_permissionsmatrix';
export const TEXT_PHISHING = 'text_phishing';
export const TEXT_PHISHINGSHIELD = 'text_phishingshield';
export const TEXT_PHISHINGSHIELDSTATUS = 'text_phishingshieldstatus';
export const TEXT_PHONE = 'text_phone';
export const TEXT_PHYSICAL = 'text_physical';
export const TEXT_PIE = 'text_pie';
export const TEXT_PLEASE_PROVIDE_DETAILS = 'text_please_provide_details';
export const TEXT_PLEASE_PROVIDE_SERVICE_PROVIDER_NAME = 'text_please_provide_service_provider_name';
export const TEXT_PLEASE_PROVIDE_THE_NAME_OR_FUNCTION_OF_YOUR_INTEGRATION = 'text_please_provide_the_name_or_function_of_your_integration';
export const TEXT_PLEASE_PROVIDE_THE_PLUGIN_NAME = 'text_please_provide_the_plugin_name';
export const TEXT_PLEASE_PROVIDE_THE_SIEM_PROVIDER_NAME = 'text_please_provide_the_siem_provider_name';
export const TEXT_PLEASEMAKENOTEOFCLIENTSECRET = 'text_pleasemakenoteofclientsecret';
export const TEXT_PLEASENOTE = 'text_pleasenote';
export const TEXT_PLEASESELECT = 'text_pleaseselect';
export const TEXT_PLEASESELECTADATE = 'text_pleaseselectadate';
export const TEXT_PLEASESELECTDEFAULT = 'text_pleaseselectdefault';
export const TEXT_PLEASESELECTPOLICY = 'text_pleaseselectpolicy';
export const TEXT_PLEASESELECTSITE = 'text_pleaseselectsite';
export const TEXT_POLICIES = 'text_policies';
export const TEXT_POLICIES_EXPORTHELP = 'text_policies_exporthelp';
export const TEXT_POLICY = 'text_policy';
export const TEXT_POLICY_CANNOT_DELETE = 'text_policy_cannot_delete';
export const TEXT_POLICY_INHERIT = 'text_policy_inherit';
export const TEXT_POLICY_NAME = 'text_policy_name';
export const TEXT_POLICYDESCRIPTION = 'text_policydescription';
export const TEXT_POLICYDIRECTLYASSIGNED = 'text_policydirectlyassigned';
export const TEXT_POLICYINHERITANCE_INHERIT = 'text_policyinheritance_inherit';
export const TEXT_POLICYINHERITANCE_UNCHANGED = 'text_policyinheritance_unchanged';
export const TEXT_POLICYMANAGEMENT = 'text_policymanagement';
export const TEXT_POLICYMANAGEMENT_0 = 'text_policymanagement_0';
export const TEXT_POLICYMANAGEMENT_1 = 'text_policymanagement_1';
export const TEXT_POLICYMANAGEMENT_3 = 'text_policymanagement_3';
export const TEXT_POLICYNAME = 'text_policyname';
export const TEXT_POLICYOVERRIDE = 'text_policyoverride';
export const TEXT_POLICYSECTION = 'text_policysection';
export const TEXT_POLICYTOBECOPIED = 'text_policytobecopied';
export const TEXT_POLICYTOBEDELETED = 'text_policytobedeleted';
export const TEXT_PORNOGRAPHYNUDITY = 'text_pornographynudity';
export const TEXT_POST_CLEANUP = 'text_post_cleanup';
export const TEXT_PREFERRED = 'text_preferred';
export const TEXT_PREPARING_YOUR_SITE = 'text_preparing_your_site';
export const TEXT_PREPAY = 'text_prepay';
export const TEXT_PREVIEW = 'text_preview';
export const TEXT_PREVIOUS = 'text_previous';
export const TEXT_PREVPAGE = 'text_prevpage';
export const TEXT_PRIMARY = 'text_primary';
export const TEXT_PRIMARYBROWSER = 'text_primarybrowser';
export const TEXT_PRIVATE_NETWORK_NOT_ACCEPTED = 'text_private_network_not_accepted';
export const TEXT_PRIVATEIPSNOTALLOWED = 'text_privateipsnotallowed';
export const TEXT_PROCESS_LOG = 'text_process_log';
export const TEXT_PRODUCT = 'text_product';
export const TEXT_PRODUCT_CONFIRMTRIAL_DNS = 'text_product_confirmtrial_dns';
export const TEXT_PRODUCT_CONFIRMTRIAL_EDR = 'text_product_confirmtrial_edr';
export const TEXT_PRODUCT_CONFIRMTRIAL_MDR = 'text_product_confirmtrial_mdr';
export const TEXT_PRODUCT_CONFIRMTRIAL_SECA = 'text_product_confirmtrial_seca';
export const TEXT_PRODUCT_DESCRIPTION_DNS = 'text_product_description_dns';
export const TEXT_PRODUCT_DESCRIPTION_EDR = 'text_product_description_edr';
export const TEXT_PRODUCT_DESCRIPTION_MDR = 'text_product_description_mdr';
export const TEXT_PRODUCT_DESCRIPTION_SECA = 'text_product_description_seca';
export const TEXT_PRODUCT_DESCRIPTION_WSAB = 'text_product_description_wsab';
export const TEXT_PRODUCT_TITLE_DNS = 'text_product_title_dns';
export const TEXT_PRODUCT_TITLE_EDR = 'text_product_title_edr';
export const TEXT_PRODUCT_TITLE_MDR = 'text_product_title_mdr';
export const TEXT_PRODUCT_TITLE_SECA = 'text_product_title_seca';
export const TEXT_PRODUCT_TITLE_WSAB = 'text_product_title_wsab';
export const TEXT_PRODUCT_TRIALSUCCESS_2 = 'text_product_trialsuccess_2';
export const TEXT_PRODUCT_TRIALSUCCESS_DNS1 = 'text_product_trialsuccess_dns1';
export const TEXT_PRODUCT_TRIALSUCCESS_DNS2 = 'text_product_trialsuccess_dns2';
export const TEXT_PRODUCT_TRIALSUCCESS_DNS3 = 'text_product_trialsuccess_dns3';
export const TEXT_PRODUCT_TRIALSUCCESS_DNS4 = 'text_product_trialsuccess_dns4';
export const TEXT_PRODUCT_TRIALSUCCESS_EDR1 = 'text_product_trialsuccess_edr1';
export const TEXT_PRODUCT_TRIALSUCCESS_MDR1 = 'text_product_trialsuccess_mdr1';
export const TEXT_PRODUCT_TRIALSUCCESS_SECA1 = 'text_product_trialsuccess_seca1';
export const TEXT_PRODUCT_TRIALSUCCESS_SECA2 = 'text_product_trialsuccess_seca2';
export const TEXT_PRODUCT_TRIALSUCCESS_SECA3 = 'text_product_trialsuccess_seca3';
export const TEXT_PRODUCT_TRIALSUCCESS_SECA4 = 'text_product_trialsuccess_seca4';
export const TEXT_PRODUCTS = 'text_products';
export const TEXT_PRODUCTTOUR = 'text_producttour';
export const TEXT_PRODUCTTRAINING = 'text_producttraining';
export const TEXT_PRODUCTUPDATES = 'text_productupdates';
export const TEXT_PROMOTEDRAFTCHANGES = 'text_promotedraftchanges';
export const TEXT_PROPERTIES = 'text_properties';
export const TEXT_PROTECTED = 'text_protected';
export const TEXT_PROTECTION = 'text_protection';
export const TEXT_PROXY = 'text_proxy';
export const TEXT_PT = 'text_pt';
export const TEXT_PT_1_CONTENT1 = 'text_pt_1_content1';
export const TEXT_PT_1_CONTENT2 = 'text_pt_1_content2';
export const TEXT_PT_1_CONTENT3 = 'text_pt_1_content3';
export const TEXT_PT_1_CONTENT4 = 'text_pt_1_content4';
export const TEXT_PT_1_TITLE = 'text_pt_1_title';
export const TEXT_PT_2_CONTENT1 = 'text_pt_2_content1';
export const TEXT_PT_2_CONTENT2 = 'text_pt_2_content2';
export const TEXT_PT_2_TITLE = 'text_pt_2_title';
export const TEXT_PT_REP_CONTENT1 = 'text_pt_rep_content1';
export const TEXT_PT_REP_CONTENT2 = 'text_pt_rep_content2';
export const TEXT_PT_REP_CONTENT3 = 'text_pt_rep_content3';
export const TEXT_PT_REP_TITLE = 'text_pt_rep_title';
export const TEXT_PURCHASING = 'text_purchasing';
export const TEXT_QUARTERLY = 'text_quarterly';
export const TEXT_QUICK_SCAN = 'text_quick_scan';
export const TEXT_QUICK_START_GUIDE = 'text_quick_start_guide';
export const TEXT_REACTIVATE = 'text_reactivate';
export const TEXT_REALTIME_LOOKUP = 'text_realtime_lookup';
export const TEXT_REALTIMESHIELD = 'text_realtimeshield';
export const TEXT_REALTIMESHIELDSTATUS = 'text_realtimeshieldstatus';
export const TEXT_RECIPIENTS = 'text_recipients';
export const TEXT_RECLASSIFY = 'text_reclassify';
export const TEXT_RECOMMENDED = 'text_recommended';
export const TEXT_RECOMMENDEDDEFAULTS = 'text_recommendeddefaults';
export const TEXT_RECOMMENDEDDNSENABLED = 'text_recommendeddnsenabled';
export const TEXT_RECOMMENDEDSERVERDEFAULTS = 'text_recommendedserverdefaults';
export const TEXT_REENABLE = 'text_reenable';
export const TEXT_REFRESH = 'text_refresh';
export const TEXT_REFRESHBLACKLIST = 'text_refreshblacklist';
export const TEXT_REFRESHHISTORY = 'text_refreshhistory';
export const TEXT_REFRESHSITES = 'text_refreshsites';
export const TEXT_REFRESHWHITELIST = 'text_refreshwhitelist';
export const TEXT_REGISTER = 'text_register';
export const TEXT_RELOAD = 'text_reload';
export const TEXT_RELOADDATA = 'text_reloaddata';
export const TEXT_RELOADPAGE = 'text_reloadpage';
export const TEXT_REMAINING = 'text_remaining';
export const TEXT_REMEDIATED = 'text_remediated';
export const TEXT_REMEDIATION = 'text_remediation';
export const TEXT_REMEDIATIONSTATUS = 'text_remediationstatus';
export const TEXT_REMOVE = 'text_remove';
export const TEXT_REMOVESITEADMINPERMISSIONMODAL = 'text_removeSiteAdminPermissionModal';
export const TEXT_REMOVESITEADMINPERMISSIONMODAL2 = 'text_removeSiteAdminPermissionModal2';
export const TEXT_REMOVEREDUNDANTOVERRIDES = 'text_removeredundantoverrides';
export const TEXT_REMOVING = 'text_removing';
export const TEXT_RENAME = 'text_rename';
export const TEXT_RENAMECONSOLE = 'text_renameconsole';
export const TEXT_RENAMECONSOLE_DESC = 'text_renameconsole_desc';
export const TEXT_RENAMECONSOLE_DESC2 = 'text_renameconsole_desc2';
export const TEXT_RENAMECONSOLE_DESC_ALT = 'text_renameconsole_desc_alt';
export const TEXT_RENAMECONSOLE_TITLE = 'text_renameconsole_title';
export const TEXT_RENAMECONSOLE_TITLE2 = 'text_renameconsole_title2';
export const TEXT_RENAMEPOLICY = 'text_renamepolicy';
export const TEXT_RENEW = 'text_renew';
export const TEXT_RENEWCONTENT = 'text_renewcontent';
export const TEXT_RENEWING = 'text_renewing';
export const TEXT_RENEWRMM = 'text_renewrmm';
export const TEXT_RENEWRMMDESC = 'text_renewrmmdesc';
export const TEXT_RENEWSECRET = 'text_renewsecret';
export const TEXT_RENEWUPGRADE = 'text_renewupgrade';
export const TEXT_REPLACEMENTTEMPLATE = 'text_replacementtemplate';
export const TEXT_REPLACEMENTTEMPLATEDESC = 'text_replacementtemplatedesc';
export const TEXT_REPORT = 'text_report';
export const TEXT_REPORTDETAILS = 'text_reportdetails';
export const TEXT_REPORTDISABLEDNOSITES = 'text_reportdisablednosites';
export const TEXT_REPORTDISTLIST = 'text_reportdistlist';
export const TEXT_REPORTDISTLISTDESC = 'text_reportdistlistdesc';
export const TEXT_REPORTNAME = 'text_reportname';
export const TEXT_REPORTNAMEEXISTS = 'text_reportnameexists';
export const TEXT_REPORTREQUESTED = 'text_reportrequested';
export const TEXT_REPORTS = 'text_reports';
export const TEXT_REPORTSFAILED = 'text_reportsfailed';
export const TEXT_REPORTSUMMARY = 'text_reportsummary';
export const TEXT_REPORTTOBECOPIED = 'text_reporttobecopied';
export const TEXT_REPUTATION = 'text_reputation';
export const TEXT_REQUESTCOUNT = 'text_requestcount';
export const TEXT_REQUESTED = 'text_requested';
export const TEXT_RESET = 'text_reset';
export const TEXT_RESET_FILTERS = 'text_reset_filters';
export const TEXT_RESETDASHBOARD = 'text_resetdashboard';
export const TEXT_RESETDASHBOARD_WARNING = 'text_resetdashboard_warning';
export const TEXT_RESETTINGDASHBOARD = 'text_resettingdashboard';
export const TEXT_RESETTODEFAULTS = 'text_resettodefaults';
export const TEXT_RESTORE = 'text_restore';
export const TEXT_RESTOREFROMQUARANTINE = 'text_restorefromquarantine';
export const TEXT_RESTOREINVALID = 'text_restoreinvalid';
export const TEXT_RESTORETHISFILE = 'text_restorethisfile';
export const TEXT_RESULTS = 'text_results';
export const TEXT_RESUME = 'text_resume';
export const TEXT_RESUMEDNSDESC = 'text_resumednsdesc';
export const TEXT_RESUMEPROTECTION = 'text_resumeprotection';
export const TEXT_RESUMERMM = 'text_resumermm';
export const TEXT_RESUMERMMDESC = 'text_resumermmdesc';
export const TEXT_RESUMING = 'text_resuming';
export const TEXT_RETRY = 'text_retry';
export const TEXT_RETURNTOLINK = 'text_returntolink';
export const TEXT_REVIEW_AND_CONFIRM = 'text_review_and_confirm';
export const TEXT_RISKFRAUDCRIME = 'text_riskfraudcrime';
export const TEXT_RMM = 'text_rmm';
export const TEXT_RMM_PLUGIN = 'text_rmm_plugin';
export const TEXT_RMMPASSWORD = 'text_rmmpassword';
export const TEXT_RMMSECRETCONFIRM = 'text_rmmsecretconfirm';
export const TEXT_RMMSECRETDESC = 'text_rmmsecretdesc';
export const TEXT_ROOTKITSHIELD = 'text_rootkitshield';
export const TEXT_ROOTKITSHIELDSTATUS = 'text_rootkitshieldstatus';
export const TEXT_ROWS = 'text_rows';
export const TEXT_ROWSPERPAGE = 'text_rowsperpage';
export const TEXT_RTAP = 'text_rtap';
export const TEXT_RU = 'text_ru';
export const TEXT_RUN = 'text_run';
export const TEXT_RUNCLEANUP = 'text_runcleanup';
export const TEXT_RUNCOMMANDSINGLEORALLPAGES = 'text_runcommandsingleorallpages';
export const TEXT_RUNCSS = 'text_runcss';
export const TEXT_RUNONDEMAND = 'text_runondemand';
export const TEXT_RUNREPORTNOW = 'text_runreportnow';
export const TEXT_RUNTIME = 'text_runtime';
export const TEXT_RUNWITHOUTCHANGES = 'text_runwithoutchanges';
export const TEXT_SAT = 'text_sat';
export const TEXT_SAT_COUNT_CARD_DISABLED = 'text_sat_count_card_disabled';
export const TEXT_SAT_COUNT_CARD_EXPIRED = 'text_sat_count_card_expired';
export const TEXT_SAT_COUNT_CARD_EXPIRED_SITE_ONLY_CONSOLE = 'text_sat_count_card_expired_site_only_console';
export const TEXT_SAT_COUNT_CARD_NOT_TRIALED = 'text_sat_count_card_not_trialed';
export const TEXT_SAT_DESCRIPTION = 'text_sat_description';
export const TEXT_SAT_FEWER_CYBER_SECURITY_INCIDENTS_HEADER = 'text_sat_fewer_cyber_security_incidents_header';
export const TEXT_SAT_FEWER_CYBER_SECURITY_INCIDENTS_PARAGRAPH = 'text_sat_fewer_cyber_security_incidents_paragraph';
export const TEXT_SAT_MORE_INFO_LINK_ONE = 'text_sat_more_info_link_one';
export const TEXT_SAT_ONBOARDING_VIDEO = 'text_sat_onboarding_video';
export const TEXT_SAT_ONGOING_DEFENSE_HEADER = 'text_sat_ongoing_defense_header';
export const TEXT_SAT_ONGOING_DEFENSE_PARAGRAPH = 'text_sat_ongoing_defense_paragraph';
export const TEXT_SAT_PROVEN_EFFICACY_HEADER = 'text_sat_proven_efficacy_header';
export const TEXT_SAT_PROVEN_EFFICACY_PARAGRAPH = 'text_sat_proven_efficacy_paragraph';
export const TEXT_SAT_REENABLE_DIALOG_REENABLE_PARAPRAPH = 'text_sat_reenable_dialog_reenable_parapraph';
export const TEXT_SAT_REGULATORY_COMPLIANCE_HEADER = 'text_sat_regulatory_compliance_header';
export const TEXT_SAT_REGULATORY_COMPLIANCE_PARAGRAPH = 'text_sat_regulatory_compliance_paragraph';
export const TEXT_SAT_SOLUTION_OVERVIEW_PARAGRAPH_ONE = 'text_sat_solution_overview_paragraph_one';
export const TEXT_SAT_SOLUTION_OVERVIEW_PARAGRAPH_THREE = 'text_sat_solution_overview_paragraph_three';
export const TEXT_SAT_SOLUTION_OVERVIEW_PARAGRAPH_TWO = 'text_sat_solution_overview_paragraph_two';
export const TEXT_SATREPORTS = 'text_satreports';
export const TEXT_SAVE = 'text_save';
export const TEXT_SAVECHANGES = 'text_savechanges';
export const TEXT_SAVED = 'text_saved';
export const TEXT_SAVEDRAFTCHANGES = 'text_savedraftchanges';
export const TEXT_SAVETAGS = 'text_savetags';
export const TEXT_SAVING = 'text_saving';
export const TEXT_SB_ACCESSIBLE = 'text_sb_accessible';
export const TEXT_SB_ACCESSIBLESITES = 'text_sb_accessiblesites';
export const TEXT_SB_ACTIVATEDSITES = 'text_sb_activatedsites';
export const TEXT_SB_DEACTIVATED = 'text_sb_deactivated';
export const TEXT_SB_DEVICES = 'text_sb_devices';
export const TEXT_SB_EXPIRED = 'text_sb_expired';
export const TEXT_SB_EXPIRING = 'text_sb_expiring';
export const TEXT_SB_EXPIRINGSOON = 'text_sb_expiringsoon';
export const TEXT_SB_NEEDATTENTION = 'text_sb_needattention';
export const TEXT_SB_NEEDINGATTENTION = 'text_sb_needingattention';
export const TEXT_SB_PURCHASEDDEVICES = 'text_sb_purchaseddevices';
export const TEXT_SB_SITEOVERVIEW = 'text_sb_siteoverview';
export const TEXT_SB_SITES = 'text_sb_sites';
export const TEXT_SB_SITESEATS = 'text_sb_siteseats';
export const TEXT_SB_SUSPENDED = 'text_sb_suspended';
export const TEXT_SB_TOTALACTIVEDEVICES = 'text_sb_totalactivedevices';
export const TEXT_SB_TOTALSITES = 'text_sb_totalsites';
export const TEXT_SCANDATE = 'text_scandate';
export const TEXT_SCANFOLDERCMDDESC = 'text_scanfoldercmddesc';
export const TEXT_SCANHISTORY = 'text_scanhistory';
export const TEXT_SCANNING_TITLE = 'text_scanning_title';
export const TEXT_SCANRESULT = 'text_scanresult';
export const TEXT_SCANSCHEDULE = 'text_scanschedule';
export const TEXT_SCANSETTINGS = 'text_scansettings';
export const TEXT_SCANTYPE = 'text_scantype';
export const TEXT_SCANURLFORMALICIOUS = 'text_scanurlformalicious';
export const TEXT_SCHED_0 = 'text_sched_0';
export const TEXT_SCHED_1 = 'text_sched_1';
export const TEXT_SCHED_10 = 'text_sched_10';
export const TEXT_SCHED_11 = 'text_sched_11';
export const TEXT_SCHED_12 = 'text_sched_12';
export const TEXT_SCHED_13 = 'text_sched_13';
export const TEXT_SCHED_14 = 'text_sched_14';
export const TEXT_SCHED_15 = 'text_sched_15';
export const TEXT_SCHED_16 = 'text_sched_16';
export const TEXT_SCHED_17 = 'text_sched_17';
export const TEXT_SCHED_18 = 'text_sched_18';
export const TEXT_SCHED_19 = 'text_sched_19';
export const TEXT_SCHED_2 = 'text_sched_2';
export const TEXT_SCHED_20 = 'text_sched_20';
export const TEXT_SCHED_21 = 'text_sched_21';
export const TEXT_SCHED_22 = 'text_sched_22';
export const TEXT_SCHED_23 = 'text_sched_23';
export const TEXT_SCHED_24 = 'text_sched_24';
export const TEXT_SCHED_25 = 'text_sched_25';
export const TEXT_SCHED_26 = 'text_sched_26';
export const TEXT_SCHED_27 = 'text_sched_27';
export const TEXT_SCHED_3 = 'text_sched_3';
export const TEXT_SCHED_4 = 'text_sched_4';
export const TEXT_SCHED_5 = 'text_sched_5';
export const TEXT_SCHED_6 = 'text_sched_6';
export const TEXT_SCHED_7 = 'text_sched_7';
export const TEXT_SCHED_8 = 'text_sched_8';
export const TEXT_SCHED_9 = 'text_sched_9';
export const TEXT_SCHEDULED = 'text_scheduled';
export const TEXT_SCHEDULED_HISTORY = 'text_scheduled_history';
export const TEXT_SCHEDULED_REPORTS = 'text_scheduled_reports';
export const TEXT_SCHEDULED_TEMPLATES = 'text_scheduled_templates';
export const TEXT_SCHEDULEDCLEANUP = 'text_scheduledcleanup';
export const TEXT_SCHEDULEDSCANSTATUS = 'text_scheduledscanstatus';
export const TEXT_SCINSTALLS = 'text_scinstalls';
export const TEXT_SCOPE = 'text_scope';
export const TEXT_SCOPE_TOOLTIP = 'text_scope_tooltip';
export const TEXT_SCRIPTDETECTIONS = 'text_scriptdetections';
export const TEXT_SCRIPTDETECTIONSHYPHEN = 'text_scriptdetectionshyphen';
export const TEXT_SCRIPTPROTECTION = 'text_scriptprotection';
export const TEXT_SCSUMMARY = 'text_scsummary';
export const TEXT_SDNS_DESCRIPTION = 'text_sdns_description';
export const TEXT_SDNS_EASE_OF_USE_HEADER = 'text_sdns_ease_of_use_header';
export const TEXT_SDNS_EASE_OF_USE_LIST_ITEM_ONE = 'text_sdns_ease_of_use_list_item_one';
export const TEXT_SDNS_EASE_OF_USE_LIST_ITEM_THREE = 'text_sdns_ease_of_use_list_item_three';
export const TEXT_SDNS_EASE_OF_USE_LIST_ITEM_TWO = 'text_sdns_ease_of_use_list_item_two';
export const TEXT_SDNS_GROW_PROFITABLE_REVENUES_HEADER = 'text_sdns_grow_profitable_revenues_header';
export const TEXT_SDNS_GROW_PROFITABLE_REVENUES_LIST_ITEM_ONE = 'text_sdns_grow_profitable_revenues_list_item_one';
export const TEXT_SDNS_GROW_PROFITABLE_REVENUES_LIST_ITEM_TWO = 'text_sdns_grow_profitable_revenues_list_item_two';
export const TEXT_SDNS_IMPROVE_SECURITY_POSTURE_HEADER = 'text_sdns_improve_security_posture_header';
export const TEXT_SDNS_IMPROVE_SECURITY_POSTURE_LIST_ITEM_ONE = 'text_sdns_improve_security_posture_list_item_one';
export const TEXT_SDNS_IMPROVE_SECURITY_POSTURE_LIST_ITEM_THREE = 'text_sdns_improve_security_posture_list_item_three';
export const TEXT_SDNS_IMPROVE_SECURITY_POSTURE_LIST_ITEM_TWO = 'text_sdns_improve_security_posture_list_item_two';
export const TEXT_SDNS_ONBOARDING_VIDEO = 'text_sdns_onboarding_video';
export const TEXT_SDNS_ONGOING_DEFENSE_HEADER = 'text_sdns_ongoing_defense_header';
export const TEXT_SDNS_ONGOING_DEFENSE_PARAGRAPH = 'text_sdns_ongoing_defense_paragraph';
export const TEXT_SDNS_PROVEN_EFFICACY_HEADER = 'text_sdns_proven_efficacy_header';
export const TEXT_SDNS_PROVEN_EFFICACY_PARAGRAPH = 'text_sdns_proven_efficacy_paragraph';
export const TEXT_SDNS_REGULATORY_COMPLIANCE_HEADER = 'text_sdns_regulatory_compliance_header';
export const TEXT_SDNS_REGULATORY_COMPLIANCE_PARAGRAPH = 'text_sdns_regulatory_compliance_paragraph';
export const TEXT_SDNS_SOLUTION_OVERVIEW_PARAGRAPH_ONE = 'text_sdns_solution_overview_paragraph_one';
export const TEXT_SDNSNOTENABLEDFORSITE = 'text_sdnsnotenabledforsite';
export const TEXT_SEARCH = 'text_search';
export const TEXT_SEARCH_FOR_DOMAINS = 'text_search_for_domains';
export const TEXT_SEARCHBYNAME = 'text_searchbyname';
export const TEXT_SEARCHDEVICEBYNAME = 'text_searchdevicebyname';
export const TEXT_SEARCHFORURL = 'text_searchforurl';
export const TEXT_SEATLIMIT = 'text_seatlimit';
export const TEXT_SEATS = 'text_seats';
export const TEXT_SEC = 'text_sec';
export const TEXT_SECATRIALTITLE = 'text_secatrialtitle';
export const TEXT_SECONDARY = 'text_secondary';
export const TEXT_SECS = 'text_secs';
export const TEXT_SECURE = 'text_secure';
export const TEXT_SECUREDNS = 'text_secureDNS';
export const TEXT_SECUREDNSDEFAULTS = 'text_secureDNSDefaults';
export const TEXT_SECUREDNSTITLE = 'text_secureDNSTitle';
export const TEXT_SECURECAST = 'text_securecast';
export const TEXT_SECURECASTFAILURE = 'text_securecastfailure';
export const TEXT_SECUREDNS_HIGH_PROTECTION = 'text_securedns_high_protection';
export const TEXT_SECUREDNS_LOW_PROTECTION = 'text_securedns_low_protection';
export const TEXT_SECUREDNS_MEDIUM_PROTECTION = 'text_securedns_medium_protection';
export const TEXT_SECUREDNSPOLICIES = 'text_securednspolicies';
export const TEXT_SECUREDNSPOLICY = 'text_securednspolicy';
export const TEXT_SECUREDNSSETTINGS = 'text_securednssettings';
export const TEXT_SECUREFILEREMOVAL = 'text_securefileremoval';
export const TEXT_SECURITY_AWARENESS_TRAINING_CONSOLE = 'text_security_awareness_training_console';
export const TEXT_SECURITYRISKCOUNT = 'text_securityriskcount';
export const TEXT_SEEN = 'text_seen';
export const TEXT_SELECT = 'text_select';
export const TEXT_SELECT_A_POLICY = 'text_select_a_policy';
export const TEXT_SELECT_A_POLICY_PLACEHOLDER = 'text_select_a_policy_placeholder';
export const TEXT_SELECT_A_SITE = 'text_select_a_site';
export const TEXT_SELECT_A_SITE_PLACEHOLDER = 'text_select_a_site_placeholder';
export const TEXT_SELECT_ALL_IN_CURRENT_GROUP = 'text_select_all_in_current_group';
export const TEXT_SELECT_DNS_GROUP_POLICY = 'text_select_dns_group_policy';
export const TEXT_SELECT_ENDPOINTS = 'text_select_endpoints';
export const TEXT_SELECT_POLICY = 'text_select_policy';
export const TEXT_SELECT_SCOPE = 'text_select_scope';
export const TEXT_SELECT_TO_VIEW = 'text_select_to_view';
export const TEXT_SELECTADATEFROMLEFT = 'text_selectadatefromleft';
export const TEXT_SELECTADEVICETOVIEW = 'text_selectadevicetoview';
export const TEXT_SELECTADISTLIST = 'text_selectadistlist';
export const TEXT_SELECTAGROUP = 'text_selectagroup';
export const TEXT_SELECTALL = 'text_selectall';
export const TEXT_SELECTANALERT = 'text_selectanalert';
export const TEXT_SELECTANALERTGROUP = 'text_selectanalertgroup';
export const TEXT_SELECTANOVERRIDE = 'text_selectanoverride';
export const TEXT_SELECTAPOLICY = 'text_selectapolicy';
export const TEXT_SELECTAREPORT = 'text_selectareport';
export const TEXT_SELECTAREPORTFROMTHELEFT = 'text_selectareportfromtheleft';
export const TEXT_SELECTASITE = 'text_selectasite';
export const TEXT_SELECTATEMPLATE = 'text_selectatemplate';
export const TEXT_SELECTATLEASTONECATEGORY = 'text_selectatleastonecategory';
export const TEXT_SELECTCHARTSEGMENT = 'text_selectchartsegment';
export const TEXT_SELECTDEVICE = 'text_selectdevice';
export const TEXT_SELECTDEVICEMOREDETAIL = 'text_selectdevicemoredetail';
export const TEXT_SELECTED = 'text_selected';
export const TEXT_SELECTEDSITES = 'text_selectedsites';
export const TEXT_SELECTIMPORTPOLICY = 'text_selectimportpolicy';
export const TEXT_SELECTLANGUAGE = 'text_selectlanguage';
export const TEXT_SELECTLISTDATAINPUTS = 'text_selectlistdatainputs';
export const TEXT_SELECTNONE = 'text_selectnone';
export const TEXT_SELECTOVERRIDESTOVIEW = 'text_selectoverridestoview';
export const TEXT_SELECTREPLACEMENTDISTLIST = 'text_selectreplacementdistlist';
export const TEXT_SELECTREPLACEMENTGROUP = 'text_selectreplacementgroup';
export const TEXT_SELECTREPLACEMENTPOLICY = 'text_selectreplacementpolicy';
export const TEXT_SELECTREPORTTODOWNLOAD = 'text_selectreporttodownload';
export const TEXT_SELECTSITE = 'text_selectsite';
export const TEXT_SELECTSITEFROMLEFT = 'text_selectsitefromleft';
export const TEXT_SELECTSITEIMPORTOVERRIDES = 'text_selectsiteimportoverrides';
export const TEXT_SELECTSITES = 'text_selectsites';
export const TEXT_SELECTSITESFORDASHBOARD = 'text_selectsitesfordashboard';
export const TEXT_SELECTTEMPLATE = 'text_selecttemplate';
export const TEXT_SELF_SELECTOR_BUSINESS_BULLET_ONE = 'text_self_selector_business_bullet_one';
export const TEXT_SELF_SELECTOR_BUSINESS_BULLET_THREE = 'text_self_selector_business_bullet_three';
export const TEXT_SELF_SELECTOR_BUSINESS_BULLET_TWO = 'text_self_selector_business_bullet_two';
export const TEXT_SELF_SELECTOR_COMPLETE_FORM_HEADER = 'text_self_selector_complete_form_header';
export const TEXT_SELF_SELECTOR_MAIN_TITLE = 'text_self_selector_main_title';
export const TEXT_SELF_SELECTOR_MANAGED_SERVICE_PROVIDER_BULLET_ONE = 'text_self_selector_managed_service_provider_bullet_one';
export const TEXT_SELF_SELECTOR_MANAGED_SERVICE_PROVIDER_BULLET_THREE = 'text_self_selector_managed_service_provider_bullet_three';
export const TEXT_SELF_SELECTOR_MANAGED_SERVICE_PROVIDER_BULLET_TWO = 'text_self_selector_managed_service_provider_bullet_two';
export const TEXT_SELF_SELECTOR_SUBHEADER_ONE_PART_ONE = 'text_self_selector_subheader_one_part_one';
export const TEXT_SELF_SELECTOR_SUBHEADER_ONE_PART_TWO = 'text_self_selector_subheader_one_part_two';
export const TEXT_SELF_SELECTOR_SUBHEADER_TWO = 'text_self_selector_subheader_two';
export const TEXT_SELFPROTECTION = 'text_selfprotection';
export const TEXT_SENDING = 'text_sending';
export const TEXT_SENDINGCOMMAND = 'text_sendingcommand';
export const TEXT_SEPTEMBER = 'text_september';
export const TEXT_SERVICEEXPIRED = 'text_serviceexpired';
export const TEXT_SERVICEEXPIRING = 'text_serviceexpiring';
export const TEXT_SERVICESTATUS = 'text_servicestatus';
export const TEXT_SETDEFAULTPOLICY = 'text_setdefaultpolicy';
export const TEXT_SETTING = 'text_setting';
export const TEXT_SETTINGDEFAULT = 'text_settingdefault';
export const TEXT_SETTINGS = 'text_settings';
export const TEXT_SETTINGS_APIACCESS_CREATE_LINK = 'text_settings_apiaccess_create_link';
export const TEXT_SETTINGS_APIACCESS_CREATE_PARAGRAPH = 'text_settings_apiaccess_create_paragraph';
export const TEXT_SETTINGS_APIACCESS_CREATE_PARAGRAPH_2 = 'text_settings_apiaccess_create_paragraph_2';
export const TEXT_SETUPLASTPASS_BUTTON = 'text_setuplastpass_button';
export const TEXT_SETUPLASTPASS_DESCRIPTION = 'text_setuplastpass_description';
export const TEXT_SETUPLASTPASS_HEADER = 'text_setuplastpass_header';
export const TEXT_SHIELDREPORTS = 'text_shieldreports';
export const TEXT_SHOW = 'text_show';
export const TEXT_SHOWALLDATA = 'text_showalldata';
export const TEXT_SHOWME = 'text_showme';
export const TEXT_SHOWSITEONLYADMINS = 'text_showsiteonlyadmins';
export const TEXT_SILENTAUDIT = 'text_silentaudit';
export const TEXT_SILENTINSTALLATIONDEMO_INSTALLATIONCOMPLETE = 'text_silentinstallationdemo_installationcomplete';
export const TEXT_SILENTINSTALLATIONDEMO_SCANFINISHED = 'text_silentinstallationdemo_scanfinished';
export const TEXT_SILENTINSTALLATIONDEMO_STARTINGIN = 'text_silentinstallationdemo_startingin';
export const TEXT_SILENTINSTALLATIONDEMO_TITLE = 'text_silentinstallationdemo_title';
export const TEXT_SILENTMODE = 'text_silentmode';
export const TEXT_SINGLEPAGE = 'text_singlepage';
export const TEXT_SITE = 'text_site';
export const TEXT_SITE_EXPIRED = 'text_site_expired';
export const TEXT_SITE_LIMIT_REACHED_TITLE = 'text_site_limit_reached_title';
export const TEXT_SITE_OVERRIDE = 'text_site_override';
export const TEXT_SITEADMINONLY = 'text_siteadminonly';
export const TEXT_SITEADMINONLYVISIBLE = 'text_siteadminonlyvisible';
export const TEXT_SITEBILLINGDATE = 'text_sitebillingdate';
export const TEXT_SITECONSOLE = 'text_siteconsole';
export const TEXT_SITEFILTER = 'text_sitefilter';
export const TEXT_SITELIMITREACHED = 'text_sitelimitreached';
export const TEXT_SITENAME = 'text_sitename';
export const TEXT_SITEONLYADMINS = 'text_siteonlyadmins';
export const TEXT_SITEPERMISSIONS = 'text_sitepermissions';
export const TEXT_SITES = 'text_sites';
export const TEXT_SITES_GRID_DEVICES_DATAFILTER_TOOLTIP = 'text_sites_grid_devices_datafilter_tooltip';
export const TEXT_SITESANDGROUPS = 'text_sitesandgroups';
export const TEXT_SITESAVAILABLE = 'text_sitesavailable';
export const TEXT_SITESEATS = 'text_siteseats';
export const TEXT_SITESEATSASSIGNED = 'text_siteseatsassigned';
export const TEXT_SITESECTOR = 'text_sitesector';
export const TEXT_SITESINCEDEACTIVATED = 'text_sitesincedeactivated';
export const TEXT_SITESINCLUDED = 'text_sitesincluded';
export const TEXT_SITESIZE = 'text_sitesize';
export const TEXT_SITESUSINGTHISPOLICY = 'text_sitesusingthispolicy';
export const TEXT_SIZE = 'text_size';
export const TEXT_SIZE_1001TO2000 = 'text_size_1001to2000';
export const TEXT_SIZE_101TO250 = 'text_size_101to250';
export const TEXT_SIZE_11TO100 = 'text_size_11to100';
export const TEXT_SIZE_1TO10 = 'text_size_1to10';
export const TEXT_SIZE_2000PLUS = 'text_size_2000plus';
export const TEXT_SIZE_251TO500 = 'text_size_251to500';
export const TEXT_SIZE_501TO1000 = 'text_size_501to1000';
export const TEXT_SKIP = 'text_skip';
export const TEXT_SKIP_GUIDED_SETUP = 'text_skip_guided_setup';
export const TEXT_SLOWLOADINGCONTENT = 'text_slowloadingcontent';
export const TEXT_SLOWLOADINGTITLE = 'text_slowloadingtitle';
export const TEXT_SOCIALMEDIACOMMUNICATION = 'text_socialmediacommunication';
export const TEXT_SOCIETYEDUCATIONRELIGION = 'text_societyeducationreligion';
export const TEXT_SOLUTION_OVERVIEW = 'text_solution_overview';
export const TEXT_SPLINE = 'text_spline';
export const TEXT_SPOTLIGHT_TOUR = 'text_spotlight_tour';
export const TEXT_SR_AT = 'text_sr_at';
export const TEXT_SR_FS = 'text_sr_fs';
export const TEXT_SR_LIS = 'text_sr_lis';
export const TEXT_SR_LIS_N = 'text_sr_lis_n';
export const TEXT_SR_P = 'text_sr_p';
export const TEXT_SR_SUBTITLE = 'text_sr_subtitle';
export const TEXT_SR_TD = 'text_sr_td';
export const TEXT_SR_TE = 'text_sr_te';
export const TEXT_SR_TITLE = 'text_sr_title';
export const TEXT_SR_TS = 'text_sr_ts';
export const TEXT_SR_TTR = 'text_sr_ttr';
export const TEXT_START = 'text_start';
export const TEXT_START_TRIAL = 'text_start_trial';
export const TEXT_START_TRIAL_DESCRIPTION_ONE = 'text_start_trial_description_one';
export const TEXT_START_TRIAL_DESCRIPTION_TWO = 'text_start_trial_description_two';
export const TEXT_STARTDATE = 'text_startdate';
export const TEXT_STARTFREETRIAL = 'text_startfreetrial';
export const TEXT_STATIC = 'text_static';
export const TEXT_STATICIP = 'text_staticip';
export const TEXT_STATUS = 'text_status';
export const TEXT_STEP = 'text_step';
export const TEXT_STRONG = 'text_strong';
export const TEXT_SUBCONSOLEONLY = 'text_subconsoleonly';
export const TEXT_SUBDOMAINLABELTOOLONG = 'text_subdomainlabeltoolong';
export const TEXT_SUBMIT = 'text_submit';
export const TEXT_SUBSCRIPTION = 'text_subscription';
export const TEXT_SUBSCRIPTIONS = 'text_subscriptions';
export const TEXT_SUCCESS = 'text_success';
export const TEXT_SUMMARY = 'text_summary';
export const TEXT_SUMMARYBAR_TITLE = 'text_summarybar_title';
export const TEXT_SUN = 'text_sun';
export const TEXT_SUPERADMIN = 'text_superadmin';
export const TEXT_SUPPORT = 'text_support';
export const TEXT_SUPPORTSWILDCARDPREFIX = 'text_supportswildcardprefix';
export const TEXT_SURE_DELETE_SELECTED = 'text_sure_delete_selected';
export const TEXT_SUREDELETEADMIN = 'text_suredeleteadmin';
export const TEXT_SUREDELETEALERT = 'text_suredeletealert';
export const TEXT_SUREDELETEDISTLIST = 'text_suredeletedistlist';
export const TEXT_SUREDELETEGROUP = 'text_suredeletegroup';
export const TEXT_SUREDELETEOVERRIDE = 'text_suredeleteoverride';
export const TEXT_SUREDELETEPOLICY = 'text_suredeletepolicy';
export const TEXT_SUREDELETEREPORT = 'text_suredeletereport';
export const TEXT_SUREDELETETEMPLATE = 'text_suredeletetemplate';
export const TEXT_SURERESTOREFROMQUARANTINE = 'text_surerestorefromquarantine';
export const TEXT_SURERESUMEPROTECTION = 'text_sureresumeprotection';
export const TEXT_SURERESUMEPROTECTIONDESC = 'text_sureresumeprotectiondesc';
export const TEXT_SURERESUMEREPORT = 'text_sureresumereport';
export const TEXT_SURERUNCLEANUP = 'text_sureruncleanup';
export const TEXT_SURESUSPENDPROTECTION = 'text_suresuspendprotection';
export const TEXT_SURESUSPENDPROTECTIONDESC = 'text_suresuspendprotectiondesc';
export const TEXT_SURESUSPENDPROTECTIONDESC2 = 'text_suresuspendprotectiondesc2';
export const TEXT_SURESUSPENDREPORT = 'text_suresuspendreport';
export const TEXT_SUSPEND = 'text_suspend';
export const TEXT_SUSPENDDEACTIVATE = 'text_suspenddeactivate';
export const TEXT_SUSPENDDNSDESC = 'text_suspenddnsdesc';
export const TEXT_SUSPENDED = 'text_suspended';
export const TEXT_SUSPENDED_AND_EXPIRED = 'text_suspended_and_expired';
export const TEXT_SUSPENDED_AND_EXPIRING = 'text_suspended_and_expiring';
export const TEXT_SUSPENDING = 'text_suspending';
export const TEXT_SUSPENDPROTECTION = 'text_suspendprotection';
export const TEXT_SUSPENDRESUME = 'text_suspendresume';
export const TEXT_SUSPENDREVERSE = 'text_suspendreverse';
export const TEXT_SUSPENDRMM = 'text_suspendrmm';
export const TEXT_SUSPENDRMMDESC = 'text_suspendrmmdesc';
export const TEXT_SUSPICIOUS = 'text_suspicious';
export const TEXT_SYSTEMCLEANER = 'text_systemcleaner';
export const TEXT_SYSTEMGENERATEDPOLICY = 'text_systemgeneratedpolicy';
export const TEXT_SYSTEMMESSAGE = 'text_systemmessage';
export const TEXT_SYSTEMMESSAGEDESC = 'text_systemmessagedesc';
export const TEXT_SYSVARIABLE = 'text_sysvariable';
export const TEXT_TAGLIST = 'text_taglist';
export const TEXT_TAGMATCHES = 'text_tagmatches';
export const TEXT_TAGS = 'text_tags';
export const TEXT_TARGETUSERS = 'text_targetusers';
export const TEXT_TEMPLATE = 'text_template';
export const TEXT_TEMPLATEDETAILS = 'text_templatedetails';
export const TEXT_TEMPLATEEXISTS = 'text_templateexists';
export const TEXT_TEMPLATENAME = 'text_templatename';
export const TEXT_TEMPLATENAMEEXISTS = 'text_templatenameexists';
export const TEXT_TEMPLATES = 'text_templates';
export const TEXT_TEMPLATESINCEDELETED = 'text_templatesincedeleted';
export const TEXT_TEMPLATETOBECOPIED = 'text_templatetobecopied';
export const TEXT_TERMSANDCONDITIONS = 'text_termsandconditions';
export const TEXT_THE_SITE_DOES_NOT_HAVE_DNS_PROTECTION = 'text_the_site_does_not_have_DNS_protection';
export const TEXT_THIS_MONTH = 'text_this_month';
export const TEXT_THIS_PAGE_ONLY = 'text_this_page_only';
export const TEXT_THISISCASESENSITIVE = 'text_thisiscasesensitive';
export const TEXT_THREATDETECTIONHISTORY = 'text_threatdetectionhistory';
export const TEXT_THREATS_DETECTED = 'text_threats_detected';
export const TEXT_THU = 'text_thu';
export const TEXT_TI = 'text_ti';
export const TEXT_TI_INFECTION = 'text_ti_infection';
export const TEXT_TI_REMOVE = 'text_ti_remove';
export const TEXT_TI_SELECTDESELECT = 'text_ti_selectdeselect';
export const TEXT_TI_TEXT = 'text_ti_text';
export const TEXT_TI_THREAT = 'text_ti_threat';
export const TEXT_TICK1 = 'text_tick1';
export const TEXT_TICK2 = 'text_tick2';
export const TEXT_TICK3 = 'text_tick3';
export const TEXT_TICK4 = 'text_tick4';
export const TEXT_TICK5 = 'text_tick5';
export const TEXT_TICK6 = 'text_tick6';
export const TEXT_TICK7 = 'text_tick7';
export const TEXT_TIME = 'text_time';
export const TEXT_TIMEDENOMINATOR = 'text_timedenominator';
export const TEXT_TIMEPERIOD = 'text_timeperiod';
export const TEXT_TITLEPAGETEXT = 'text_titlepagetext';
export const TEXT_TO = 'text_to';
export const TEXT_TO_VIEW_OVERRIDE_IN_TABLE = 'text_to_view_override_in_table';
export const TEXT_TODAY = 'text_today';
export const TEXT_TOOLTIP_BAGLOBALORSITE = 'text_tooltip_baglobalorsite';
export const TEXT_TOOLTIP_BILLINGCYCLE = 'text_tooltip_billingcycle';
export const TEXT_TOOLTIP_BILLINGDATE = 'text_tooltip_billingdate';
export const TEXT_TOOLTIP_BLOCKMALICIOUS = 'text_tooltip_blockmalicious';
export const TEXT_TOOLTIP_COMMENTSFIELD = 'text_tooltip_commentsfield';
export const TEXT_TOOLTIP_COMPANYINDUSTRYFIELD = 'text_tooltip_companyindustryfield';
export const TEXT_TOOLTIP_COMPANYNAMEFIELD = 'text_tooltip_companynamefield';
export const TEXT_TOOLTIP_COMPANYSIZEFIELD = 'text_tooltip_companysizefield';
export const TEXT_TOOLTIP_CREATEDBY = 'text_tooltip_createdby';
export const TEXT_TOOLTIP_DATAFILTER = 'text_tooltip_datafilter';
export const TEXT_TOOLTIP_DEACTIVATEDBY = 'text_tooltip_deactivatedby';
export const TEXT_TOOLTIP_DEFAULTPOLICY = 'text_tooltip_defaultpolicy';
export const TEXT_TOOLTIP_DOMAINS = 'text_tooltip_domains';
export const TEXT_TOOLTIP_EPTRIALSTATUS = 'text_tooltip_eptrialstatus';
export const TEXT_TOOLTIP_GLOBALOVERRIDES = 'text_tooltip_globaloverrides';
export const TEXT_TOOLTIP_GLOBALPOLICIES = 'text_tooltip_globalpolicies';
export const TEXT_TOOLTIP_INTRANETWHITELIST = 'text_tooltip_intranetwhitelist';
export const TEXT_TOOLTIP_NETWORKSETTINGS = 'text_tooltip_networksettings';
export const TEXT_TOOLTIP_POLICY = 'text_tooltip_policy';
export const TEXT_TOOLTIP_SCOPE = 'text_tooltip_scope';
export const TEXT_TOOLTIP_SEATSFIELD = 'text_tooltip_seatsfield';
export const TEXT_TOOLTIP_SECA = 'text_tooltip_seca';
export const TEXT_TOOLTIP_SECATARGETCOUNT = 'text_tooltip_secatargetcount';
export const TEXT_TOOLTIP_SECUREDNS = 'text_tooltip_securedns';
export const TEXT_TOOLTIP_SITESACCESSIBLE = 'text_tooltip_sitesaccessible';
export const TEXT_TOOLTIP_SITESACCESSIBLE2 = 'text_tooltip_sitesaccessible2';
export const TEXT_TOOLTIP_TAGSFIELD = 'text_tooltip_tagsfield';
export const TEXT_TOOLTIP_URLTYPE = 'text_tooltip_urltype';
export const TEXT_TOOMANYCHARTS = 'text_toomanycharts';
export const TEXT_TOOMANYEMAILS = 'text_toomanyemails';
export const TEXT_TOOMANYEMAILS10 = 'text_toomanyemails10';
export const TEXT_TOOMANYEMAILS25 = 'text_toomanyemails25';
export const TEXT_TOP50 = 'text_top50';
export const TEXT_TOPBLOCKEDCATEGORY = 'text_topblockedcategory';
export const TEXT_TOPBLOCKEDDOMAIN = 'text_topblockeddomain';
export const TEXT_TOPBLOCKEDUSERREQUEST = 'text_topblockeduserrequest';
export const TEXT_TOPREQUESTEDCATEGORY = 'text_toprequestedcategory';
export const TEXT_TOPSITESNUMBERREQUESTS = 'text_topsitesnumberrequests';
export const TEXT_TOTAL = 'text_total';
export const TEXT_TOTAL_ENDPOINTS_SELECTED = 'text_total_endpoints_selected';
export const TEXT_TOUR_ADVOV_1 = 'text_tour_advov_1';
export const TEXT_TOUR_ADVOV_2 = 'text_tour_advov_2';
export const TEXT_TOUR_ADVOV_3 = 'text_tour_advov_3';
export const TEXT_TOUR_GAL_1 = 'text_tour_gal_1';
export const TEXT_TOUR_GAL_2 = 'text_tour_gal_2';
export const TEXT_TOUR_GOV_1 = 'text_tour_gov_1';
export const TEXT_TOUR_GOV_2 = 'text_tour_gov_2';
export const TEXT_TOUR_GPS_1 = 'text_tour_gps_1';
export const TEXT_TOUR_GPS_2 = 'text_tour_gps_2';
export const TEXT_TR = 'text_tr';
export const TEXT_TRAINING = 'text_training';
export const TEXT_TRAINING_COURSE_LIBRARY = 'text_training_course_library';
export const TEXT_TRANSFERCODE = 'text_transfercode';
export const TEXT_TRIAL = 'text_trial';
export const TEXT_TRIAL_SURE = 'text_trial_sure';
export const TEXT_TRIALEXPIRED = 'text_trialexpired';
export const TEXT_TRIALMARKER = 'text_trialmarker';
export const TEXT_TRIALSEATS = 'text_trialseats';
export const TEXT_TRIALSTATUS = 'text_trialstatus';
export const TEXT_TRIALSUBTITLE = 'text_trialsubtitle';
export const TEXT_TRIALTAG = 'text_trialtag';
export const TEXT_TRIALTAGDAYS = 'text_trialtagdays';
export const TEXT_TRUSTWORTHY = 'text_trustworthy';
export const TEXT_TRYIT = 'text_tryIt';
export const TEXT_TUE = 'text_tue';
export const TEXT_TWOFA = 'text_twofa';
export const TEXT_TYPE = 'text_type';
export const TEXT_TYPE_A_NAME = 'text_type_a_name';
export const TEXT_TYPE_PERCENT_FOR_SYSTEM_VARIABLES = 'text_type_percent_for_system_variables';
export const TEXT_TYPE_TOOLTIP = 'text_type_tooltip';
export const TEXT_UNABLE_REACH_DNS_SERVER = 'text_unable_reach_dns_server';
export const TEXT_UNCATEGORIZED = 'text_uncategorized';
export const TEXT_UNCLASSIFIED = 'text_unclassified';
export const TEXT_UNEXPECTED_ERROR_MESSAGE = 'text_unexpected_error_message';
export const TEXT_UNEXPECTED_ERROR_TRY_AGAIN = 'text_unexpected_error_try_again';
export const TEXT_UNINSTALLDESCRIPTION = 'text_uninstalldescription';
export const TEXT_UNINSTALLED = 'text_uninstalled';
export const TEXT_UNITNOTUNDERKEYCODE = 'text_unitnotunderkeycode';
export const TEXT_UNITNOTUNDERKEYCODE2 = 'text_unitnotunderkeycode2';
export const TEXT_UNITY_API_ACCESS = 'text_unity_api_access';
export const TEXT_UNITY_API_ADDITIONAL_COMMENTS = 'text_unity_api_additional_comments';
export const TEXT_UNITYAPIDOCUMENTATION = 'text_unityapidocumentation';
export const TEXT_UNITYAPIDOCUMENTATIONSHORT = 'text_unityapidocumentationshort';
export const TEXT_UNITYINTRO_01 = 'text_unityintro_01';
export const TEXT_UNITYINTRO_02 = 'text_unityintro_02';
export const TEXT_UNITYINTRO_03 = 'text_unityintro_03';
export const TEXT_UNITYINTRO_04 = 'text_unityintro_04';
export const TEXT_UNITYINTRO_05 = 'text_unityintro_05';
export const TEXT_UNITYMORETHAN20 = 'text_unitymorethan20';
export const TEXT_UNITYTOOLTIP_DESC = 'text_unitytooltip_desc';
export const TEXT_UNITYTOOLTIP_NAME = 'text_unitytooltip_name';
export const TEXT_UNIVERSALREPORTER = 'text_universalreporter';
export const TEXT_UNKNOWN = 'text_unknown';
export const TEXT_UNLIMITED = 'text_unlimited';
export const TEXT_UNMANAGED = 'text_unmanaged';
export const TEXT_UNMANAGEDDESC = 'text_unmanageddesc';
export const TEXT_UNNAMEDCONSOLE = 'text_unnamedconsole';
export const TEXT_UNSUPPORTED = 'text_unsupported';
export const TEXT_UNSUPPORTEDBROWSER = 'text_unsupportedbrowser';
export const TEXT_UNSUPPORTEDBROWSER_UPGRADE = 'text_unsupportedbrowser_upgrade';
export const TEXT_UPDATEDWEBFILTERINGCAPABILITIES = 'text_updatedwebfilteringcapabilities';
export const TEXT_UPDATING = 'text_updating';
export const TEXT_UPDATINGCHART = 'text_updatingchart';
export const TEXT_UPGRADE = 'text_upgrade';
export const TEXT_UPGRADE_DNS_TRIAL_MESSAGE = 'text_upgrade_dns_trial_message';
export const TEXT_UPGRADE_SAT_TRIAL_MESSAGE = 'text_upgrade_sat_trial_message';
export const TEXT_UPGRADE_TRIAL = 'text_upgrade_trial';
export const TEXT_UPGRADE_TRIAL_WILL_ALSO_UPGRADE_WSA = 'text_upgrade_trial_will_also_upgrade_wsa';
export const TEXT_UPGRADEEPTRIALTOFULL = 'text_upgradeeptrialtofull';
export const TEXT_UPGRADEH1 = 'text_upgradeh1';
export const TEXT_UPGRADEP = 'text_upgradep';
export const TEXT_UPGRADING = 'text_upgrading';
export const TEXT_UPLOADLOGODESC = 'text_uploadlogodesc';
export const TEXT_UR_ABOUT_BULLET1 = 'text_ur_about_bullet1';
export const TEXT_UR_ABOUT_BULLET10 = 'text_ur_about_bullet10';
export const TEXT_UR_ABOUT_BULLET2 = 'text_ur_about_bullet2';
export const TEXT_UR_ABOUT_BULLET3 = 'text_ur_about_bullet3';
export const TEXT_UR_ABOUT_BULLET4 = 'text_ur_about_bullet4';
export const TEXT_UR_ABOUT_BULLET5 = 'text_ur_about_bullet5';
export const TEXT_UR_ABOUT_BULLET6 = 'text_ur_about_bullet6';
export const TEXT_UR_ABOUT_BULLET7 = 'text_ur_about_bullet7';
export const TEXT_UR_ABOUT_BULLET8 = 'text_ur_about_bullet8';
export const TEXT_UR_ABOUT_BULLET9 = 'text_ur_about_bullet9';
export const TEXT_UR_ABOUT_DESC = 'text_ur_about_desc';
export const TEXT_UR_ABOUT_DESC2 = 'text_ur_about_desc2';
export const TEXT_UR_ABOUT_HEADER = 'text_ur_about_header';
export const TEXT_UR_ABOUT_HEADER1 = 'text_ur_about_header1';
export const TEXT_UR_ABOUT_HEADER2 = 'text_ur_about_header2';
export const TEXT_UR_COMMUNITY = 'text_ur_community';
export const TEXT_UR_DESC1 = 'text_ur_desc1';
export const TEXT_UR_DESC2 = 'text_ur_desc2';
export const TEXT_UR_DOWNLOADUR = 'text_ur_downloadur';
export const TEXT_UR_KNOWLEDGEBASE = 'text_ur_knowledgebase';
export const TEXT_UR_PRODUCTDEMO = 'text_ur_productdemo';
export const TEXT_UR_REPORTTEMPLATES = 'text_ur_reporttemplates';
export const TEXT_UR_SETUP = 'text_ur_setup';
export const TEXT_UR_TITLE = 'text_ur_title';
export const TEXT_URL = 'text_url';
export const TEXT_URL_CURRENT_COUNT = 'text_url_current_count';
export const TEXT_URL_MAXIMUM_COUNT = 'text_url_maximum_count';
export const TEXT_URLINVALID_DASHEND = 'text_urlinvalid_dashend';
export const TEXT_URLINVALID_DASHSTART = 'text_urlinvalid_dashstart';
export const TEXT_URLINVALID_UNSUPPORTEDCHARS = 'text_urlinvalid_unsupportedchars';
export const TEXT_URLLABELTOOLONG = 'text_urllabeltoolong';
export const TEXT_URLSCANNING = 'text_urlscanning';
export const TEXT_USAGE_PORTAL_DESCRIPTION = 'text_usage_portal_description';
export const TEXT_USAGE_REPORT = 'text_usage_report';
export const TEXT_USAGEPOLICYRESTRICTSACCESS = 'text_usagepolicyrestrictsaccess';
export const TEXT_USB = 'text_usb';
export const TEXT_USBSHIELD = 'text_usbshield';
export const TEXT_USBSHIELDSTATUS = 'text_usbshieldstatus';
export const TEXT_USEDOF = 'text_usedof';
export const TEXT_USEEXISTINGLIST = 'text_useexistinglist';
export const TEXT_USER = 'text_user';
export const TEXT_USERACTION = 'text_useraction';
export const TEXT_USERGENERATEDPOLICY = 'text_usergeneratedpolicy';
export const TEXT_USERINTERFACE = 'text_userinterface';
export const TEXT_USERMUSTCONFIRMREGISTRATION = 'text_usermustconfirmregistration';
export const TEXT_USERNAME = 'text_username';
export const TEXT_USESECURITYCODEDURINGLOGIN = 'text_usesecuritycodeduringlogin';
export const TEXT_UTILITY = 'text_utility';
export const TEXT_VALUE = 'text_value';
export const TEXT_VENDOR = 'text_vendor';
export const TEXT_VERSION = 'text_version';
export const TEXT_VERYSTRONG = 'text_verystrong';
export const TEXT_VIEW = 'text_view';
export const TEXT_VIEW_COMMAND_LOG_FOR_SELECTED_ENDPOINTS = 'text_view_command_log_for_selected_endpoints';
export const TEXT_VIEW_COMMAND_LOG_FOR_THIS_PAGE_OR_ALL = 'text_view_command_log_for_this_page_or_all';
export const TEXT_VIEW_INFECTED = 'text_view_infected';
export const TEXT_VIEWCOMMANDLOG = 'text_viewcommandlog';
export const TEXT_VIEWDEVICES = 'text_viewdevices';
export const TEXT_VIEWMORE = 'text_viewmore';
export const TEXT_VIEWREPORT = 'text_viewreport';
export const TEXT_VIEWUSAGE = 'text_viewusage';
export const TEXT_VIRTUAL = 'text_virtual';
export const TEXT_WATCHVIDEO = 'text_watchvideo';
export const TEXT_WEAK = 'text_weak';
export const TEXT_WEB_OVERRIDES = 'text_web_overrides';
export const TEXT_WEB_THREAT_SHIELD_BLOCKED_URL_HISTORY_CHART = 'text_web_threat_shield_blocked_url_history_chart';
export const TEXT_WEB_THREAT_SHIELD_BLOCKED_URLS = 'text_web_threat_shield_blocked_urls';
export const TEXT_WEBALLOWLIST = 'text_weballowlist';
export const TEXT_WEBBLOCKALLOWLIST = 'text_webblockallowlist';
export const TEXT_WEBBLOCKPAGESETTINGS = 'text_webblockpagesettings';
export const TEXT_WEBOVERRIDES = 'text_weboverrides';
export const TEXT_WEBROOT_CLOUD_DETERMINATION = 'text_webroot_cloud_determination';
export const TEXT_WEBROOT_DNS_PROTECTION_CONFIRM_CHANGES_MESSAGE = 'text_webroot_dns_protection_confirm_changes_message';
export const TEXT_WEBROOT_DNS_PROTECTION_CONFIRM_CLICK_PREVIOUS_TO_CHANGE_SELECTION = 'text_webroot_dns_protection_confirm_click_previous_to_change_selection';
export const TEXT_WEBROOT_DNS_PROTECTION_CONFIRM_GROUP_MESSAGE = 'text_webroot_dns_protection_confirm_group_message';
export const TEXT_WEBROOT_DNS_PROTECTION_CONFIRM_IMPORTANT_MESSAGE = 'text_webroot_dns_protection_confirm_important_message';
export const TEXT_WEBROOT_DNS_PROTECTION_CONFIRM_TOTAL_DEVICES_MESSAGE = 'text_webroot_dns_protection_confirm_total_devices_message';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP = 'text_webroot_dns_protection_guided_setup';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP_COMPLETE_CONFIGURE_MESSAGE = 'text_webroot_dns_protection_guided_setup_complete_configure_message';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP_COMPLETE_MESSAGE = 'text_webroot_dns_protection_guided_setup_complete_message';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP_COMPLETE_TITLE = 'text_webroot_dns_protection_guided_setup_complete_title';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP_INSTALL_DNS_AGENT_MESSAGE = 'text_webroot_dns_protection_guided_setup_install_dns_agent_message';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP_MOVE_GROUP_MESSAGE = 'text_webroot_dns_protection_guided_setup_move_group_message';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP_NEW_POLICY_MESSAGE = 'text_webroot_dns_protection_guided_setup_new_policy_message';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP_SELECT_ENDPOINTS_MESSAGE = 'text_webroot_dns_protection_guided_setup_select_endpoints_message';
export const TEXT_WEBROOT_DNS_PROTECTION_GUIDED_SETUP_SELECT_POLICY_MESSAGE = 'text_webroot_dns_protection_guided_setup_select_policy_message';
export const TEXT_WEBROOT_DNS_PROTECTION_NO_ENDPOINTS_CONFIGURE_SETTINGS_MESSAGE = 'text_webroot_dns_protection_no_endpoints_configure_settings_message';
export const TEXT_WEBROOT_DNS_PROTECTION_NO_ENDPOINTS_DOWNLOAD_SOFTWARE_MESSAGE = 'text_webroot_dns_protection_no_endpoints_download_software_message';
export const TEXT_WEBROOT_DNS_PROTECTION_NO_ENDPOINTS_OPTION_ONE_MESSAGE = 'text_webroot_dns_protection_no_endpoints_option_one_message';
export const TEXT_WEBROOT_DNS_PROTECTION_NO_ENDPOINTS_OPTION_TWO_MESSAGE = 'text_webroot_dns_protection_no_endpoints_option_two_message';
export const TEXT_WEBROOT_DNS_PROTECTION_SKIP_GUIDED_SETUP_MESSAGE = 'text_webroot_dns_protection_skip_guided_setup_message';
export const TEXT_WEBROOT_DNS_PROTECTION_SKIP_GUIDED_SETUP_QUICK_START_GUIDE_BELOW_MESSAGE = 'text_webroot_dns_protection_skip_guided_setup_quick_start_guide_below_message';
export const TEXT_WEBROOT_DNS_PROTECTION_TRIAL_CONFIRMED_CONTINUE_MESSAGE = 'text_webroot_dns_protection_trial_confirmed_continue_message';
export const TEXT_WEBROOT_DNS_PROTECTION_TRIAL_CONFIRMED_IMPORTANT_NOTICE = 'text_webroot_dns_protection_trial_confirmed_important_notice';
export const TEXT_WEBROOT_DNS_PROTECTION_TRIAL_CONFIRMED_MESSAGE = 'text_webroot_dns_protection_trial_confirmed_message';
export const TEXT_WEBROOT_DNS_PROTECTION_TRIAL_MESSAGE = 'text_webroot_dns_protection_trial_message';
export const TEXT_WEBROOT_DNS_PROTECTION_TRIAL_TITLE = 'text_webroot_dns_protection_trial_title';
export const TEXT_WEBROOT_END_OF_TRIAL_UPGRADE_OPTIONS_MESSAGE = 'text_webroot_end_of_trial_upgrade_options_message';
export const TEXT_WEBROOT_SAT_PROTECTION_TRIAL_CONFIRMED_CONTINUE_MESSAGE = 'text_webroot_sat_protection_trial_confirmed_continue_message';
export const TEXT_WEBROOT_SAT_PROTECTION_TRIAL_CONFIRMED_MESSAGE = 'text_webroot_sat_protection_trial_confirmed_message';
export const TEXT_WEBROOT_SAT_PROTECTION_TRIAL_MESSAGE = 'text_webroot_sat_protection_trial_message';
export const TEXT_WEBROOT_SAT_PROTECTION_TRIAL_TITLE = 'text_webroot_sat_protection_trial_title';
export const TEXT_WEBROOT_SKIP_GUIDED_SETUP_TITLE = 'text_webroot_skip_guided_setup_title';
export const TEXT_WEBROOT_START_TRIAL_CLICK_TO_CONTINUE_MESSAGE = 'text_webroot_start_trial_click_to_continue_message';
export const TEXT_WEBROOT_SYSLOG_BRIDGE = 'text_webroot_syslog_bridge';
export const TEXT_WEBROOT_TRIAL_CONFIRMED_TITLE = 'text_webroot_trial_confirmed_title';
export const TEXT_WEBROOTDNSSERVERS = 'text_webrootdnsservers';
export const TEXT_WEBROOTSECUREANYWHEREAGREEMENTS = 'text_webrootsecureanywhereagreements';
export const TEXT_WEBROOTUNIVERSALREPORTER = 'text_webrootuniversalreporter';
export const TEXT_WEBSHIELD = 'text_webshield';
export const TEXT_WEBSITENOTALLOWED = 'text_websitenotallowed';
export const TEXT_WEBTHREATSHIELD = 'text_webthreatshield';
export const TEXT_WEBTHREATSHIELDSTATUS = 'text_webthreatshieldstatus';
export const TEXT_WEBTHREATSHIELDUPDATE = 'text_webthreatshieldupdate';
export const TEXT_WED = 'text_wed';
export const TEXT_WEEKLY = 'text_weekly';
export const TEXT_WEEKS = 'text_weeks';
export const TEXT_WELCOMETOGSM = 'text_welcometogsm';
export const TEXT_WHITELIST = 'text_whitelist';
export const TEXT_WHITELISTED = 'text_whitelisted';
export const TEXT_WHITELISTTHISFILE = 'text_whitelistthisfile';
export const TEXT_WHITELISTWARNING = 'text_whitelistwarning';
export const TEXT_WILD_CARDS_ARE_SUPPORTED_WITHIN_DOMAIN = 'text_wild_cards_are_supported_within_domain';
export const TEXT_WIN10UPGRADE = 'text_win10upgrade';
export const TEXT_WINDOWSANDMAC = 'text_windowsandmac';
export const TEXT_WINDOWSDESKTOP = 'text_windowsdesktop';
export const TEXT_WINDOWSONLY = 'text_windowsonly';
export const TEXT_WINDOWSPC = 'text_windowspc';
export const TEXT_WINDOWSSERVER = 'text_windowsserver';
export const TEXT_WINDOWSSYSTEM = 'text_windowssystem';
export const TEXT_WIZARD_10_1 = 'text_wizard_10_1';
export const TEXT_WIZARD_10_2 = 'text_wizard_10_2';
export const TEXT_WIZARD_10_3 = 'text_wizard_10_3';
export const TEXT_WIZARD_10_4 = 'text_wizard_10_4';
export const TEXT_WIZARD_10_5 = 'text_wizard_10_5';
export const TEXT_WIZARD_1_1 = 'text_wizard_1_1';
export const TEXT_WIZARD_1_2 = 'text_wizard_1_2';
export const TEXT_WIZARD_1_3 = 'text_wizard_1_3';
export const TEXT_WIZARD_1_4 = 'text_wizard_1_4';
export const TEXT_WIZARD_2_1 = 'text_wizard_2_1';
export const TEXT_WIZARD_2_2 = 'text_wizard_2_2';
export const TEXT_WIZARD_2_3 = 'text_wizard_2_3';
export const TEXT_WIZARD_2_4 = 'text_wizard_2_4';
export const TEXT_WIZARD_2_5 = 'text_wizard_2_5';
export const TEXT_WIZARD_2_6 = 'text_wizard_2_6';
export const TEXT_WIZARD_3_1 = 'text_wizard_3_1';
export const TEXT_WIZARD_3_2 = 'text_wizard_3_2';
export const TEXT_WIZARD_3_3 = 'text_wizard_3_3';
export const TEXT_WIZARD_3_4 = 'text_wizard_3_4';
export const TEXT_WIZARD_3_5 = 'text_wizard_3_5';
export const TEXT_WIZARD_3_6 = 'text_wizard_3_6';
export const TEXT_WIZARD_4_1 = 'text_wizard_4_1';
export const TEXT_WIZARD_4_2 = 'text_wizard_4_2';
export const TEXT_WIZARD_4_4 = 'text_wizard_4_4';
export const TEXT_WIZARD_4_5 = 'text_wizard_4_5';
export const TEXT_WIZARD_4_6 = 'text_wizard_4_6';
export const TEXT_WIZARD_5_1 = 'text_wizard_5_1';
export const TEXT_WIZARD_5_2 = 'text_wizard_5_2';
export const TEXT_WIZARD_5_3 = 'text_wizard_5_3';
export const TEXT_WIZARD_5_4 = 'text_wizard_5_4';
export const TEXT_WIZARD_6_1 = 'text_wizard_6_1';
export const TEXT_WIZARD_6_2 = 'text_wizard_6_2';
export const TEXT_WIZARD_6_3 = 'text_wizard_6_3';
export const TEXT_WIZARD_6_4 = 'text_wizard_6_4';
export const TEXT_WIZARD_6_5 = 'text_wizard_6_5';
export const TEXT_WIZARD_7_1 = 'text_wizard_7_1';
export const TEXT_WIZARD_7_2 = 'text_wizard_7_2';
export const TEXT_WIZARD_7_3 = 'text_wizard_7_3';
export const TEXT_WIZARD_7_4 = 'text_wizard_7_4';
export const TEXT_WIZARD_7_5 = 'text_wizard_7_5';
export const TEXT_WIZARD_8_1 = 'text_wizard_8_1';
export const TEXT_WIZARD_8_2 = 'text_wizard_8_2';
export const TEXT_WIZARD_9_1 = 'text_wizard_9_1';
export const TEXT_WIZARD_9_2 = 'text_wizard_9_2';
export const TEXT_WIZARD_9_3 = 'text_wizard_9_3';
export const TEXT_WIZARD_REP_1 = 'text_wizard_rep_1';
export const TEXT_WIZARD_REP_2 = 'text_wizard_rep_2';
export const TEXT_WIZARD_REP_3 = 'text_wizard_rep_3';
export const TEXT_WIZARD_REP_4 = 'text_wizard_rep_4';
export const TEXT_WORKGROUP = 'text_workgroup';
export const TEXT_WRITE_SCAN = 'text_write_scan';
export const TEXT_WSAB_REQUIRED_DESC = 'text_wsab_required_desc';
export const TEXT_YES = 'text_yes';
export const TEXT_YESTERDAY = 'text_yesterday';
export const TEXT_YOURTRANSFERCODE = 'text_yourtransfercode';
export const TEXT_ZH = 'text_zh';
export const TEXT_ZS = 'text_zs';
export const TOUR_BUSINESS_DASHBOARD_OVERVIEW_PARAGRAPH_ONE = 'tour_business_dashboard_overview_paragraph_one';
export const TOUR_BUSINESS_DASHBOARD_OVERVIEW_PARAGRAPH_THREE = 'tour_business_dashboard_overview_paragraph_three';
export const TOUR_BUSINESS_DASHBOARD_OVERVIEW_PARAGRAPH_TWO = 'tour_business_dashboard_overview_paragraph_two';
export const TOUR_BUSINESS_DASHBOARD_OVERVIEW_TITLE = 'tour_business_dashboard_overview_title';
export const TOUR_BUSINESS_DNS_PROTECTION_PARAGRAPH_TWO = 'tour_business_dns_protection_paragraph_two';
export const TOUR_BUSINESS_GROUPS_PARAGRAPH_ONE = 'tour_business_groups_paragraph_one';
export const TOUR_BUSINESS_SAT_PARAGRAPH_TWO = 'tour_business_sat_paragraph_two';
export const TOUR_BUSINESS_WELCOME_TO_YOUR_NEW_CONSOLE_BULLETS_ONE = 'tour_business_welcome_to_your_new_console_bullets_one';
export const TOUR_HOW_TO_START_PARAGRAPH_ONE = 'tour_how_to_start_paragraph_one';
export const TOUR_MSP_ADDING_A_SITE_PARAGRAPH_ONE = 'tour_msp_adding_a_site_paragraph_one';
export const TOUR_MSP_ADDING_A_SITE_TITLE = 'tour_msp_adding_a_site_title';
export const TOUR_MSP_ADMINS_PARAGRAPH_ONE = 'tour_msp_admins_paragraph_one';
export const TOUR_MSP_ADMINS_PARAGRAPH_TWO = 'tour_msp_admins_paragraph_two';
export const TOUR_MSP_ADMINS_TITLE = 'tour_msp_admins_title';
export const TOUR_MSP_CONFIGURATION_PARAGRAPH_ONE = 'tour_msp_configuration_paragraph_one';
export const TOUR_MSP_CONFIGURATION_PARAGRAPH_TWO = 'tour_msp_configuration_paragraph_two';
export const TOUR_MSP_CONFIGURATION_TITLE = 'tour_msp_configuration_title';
export const TOUR_MSP_DNS_PROTECTION_PARAGRAPH_ONE = 'tour_msp_dns_protection_paragraph_one';
export const TOUR_MSP_DNS_PROTECTION_PARAGRAPH_TWO = 'tour_msp_dns_protection_paragraph_two';
export const TOUR_MSP_DNS_PROTECTION_TITLE = 'tour_msp_dns_protection_title';
export const TOUR_MSP_GROUPS_PARAGRAPH_ONE = 'tour_msp_groups_paragraph_one';
export const TOUR_MSP_GROUPS_PARAGRAPH_THREE = 'tour_msp_groups_paragraph_three';
export const TOUR_MSP_GROUPS_PARAGRAPH_TWO = 'tour_msp_groups_paragraph_two';
export const TOUR_MSP_GROUPS_TITLE = 'tour_msp_groups_title';
export const TOUR_MSP_MANAGING_YOUR_SITE_PARAGRAPH_ONE = 'tour_msp_managing_your_site_paragraph_one';
export const TOUR_MSP_MANAGING_YOUR_SITE_PARAGRAPH_TWO = 'tour_msp_managing_your_site_paragraph_two';
export const TOUR_MSP_MANAGING_YOUR_SITE_TITLE = 'tour_msp_managing_your_site_title';
export const TOUR_MSP_POLICIES_PARAGRAPH_ONE = 'tour_msp_policies_paragraph_one';
export const TOUR_MSP_POLICIES_PARAGRAPH_TWO = 'tour_msp_policies_paragraph_two';
export const TOUR_MSP_POLICIES_TITLE = 'tour_msp_policies_title';
export const TOUR_MSP_SAT_PARAGRAPH_ONE = 'tour_msp_sat_paragraph_one';
export const TOUR_MSP_SAT_PARAGRAPH_TWO = 'tour_msp_sat_paragraph_two';
export const TOUR_MSP_SAT_TITLE = 'tour_msp_sat_title';
export const TOUR_MSP_SITES_OVERVIEW_PARAGRAPH_ONE = 'tour_msp_sites_overview_paragraph_one';
export const TOUR_MSP_SITES_OVERVIEW_PARAGRAPH_THREE = 'tour_msp_sites_overview_paragraph_three';
export const TOUR_MSP_SITES_OVERVIEW_PARAGRAPH_TWO = 'tour_msp_sites_overview_paragraph_two';
export const TOUR_MSP_SITES_OVERVIEW_TITLE = 'tour_msp_sites_overview_title';
export const TOUR_MSP_WELCOME_TO_YOUR_NEW_CONSOLE_BULLETS_FIVE = 'tour_msp_welcome_to_your_new_console_bullets_five';
export const TOUR_MSP_WELCOME_TO_YOUR_NEW_CONSOLE_BULLETS_FOUR = 'tour_msp_welcome_to_your_new_console_bullets_four';
export const TOUR_MSP_WELCOME_TO_YOUR_NEW_CONSOLE_BULLETS_ONE = 'tour_msp_welcome_to_your_new_console_bullets_one';
export const TOUR_MSP_WELCOME_TO_YOUR_NEW_CONSOLE_BULLETS_THREE = 'tour_msp_welcome_to_your_new_console_bullets_three';
export const TOUR_MSP_WELCOME_TO_YOUR_NEW_CONSOLE_BULLETS_TITLE = 'tour_msp_welcome_to_your_new_console_bullets_title';
export const TOUR_MSP_WELCOME_TO_YOUR_NEW_CONSOLE_BULLETS_TWO = 'tour_msp_welcome_to_your_new_console_bullets_two';
export const TOUR_MSP_WELCOME_TO_YOUR_NEW_CONSOLE_MESSAGE = 'tour_msp_welcome_to_your_new_console_message';
export const TOUR_MSP_WELCOME_TO_YOUR_NEW_CONSOLE_TITLE = 'tour_msp_welcome_to_your_new_console_title';
export const TRIAL_HEADER_CONFIRMED = 'trial_header_confirmed';
export const TRY_NOW = 'try_now';
export const WSAT_PHISHING_CLICKS = 'wsat_phishing_clicks';
export const WSAT_TRAINING_PROGRESS = 'wsat_training_progress';
export const WSAT_USAGE_REPORT = 'wsat_usage_report';
export const BY_DEFAULT_THE_EDR_AGENT_WILL_BE_INSTALLED = 'BY_DEFAULT_THE_EDR_AGENT_WILL_BE_INSTALLED';
export const BY_DEFAULT_THE_MDR_AGENT_WILL_BE_INSTALLED = 'BY_DEFAULT_THE_MDR_AGENT_WILL_BE_INSTALLED';
